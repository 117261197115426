import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { ExitModal } from "../../components/exitModal";
import { Produtos } from "../../../components/produtos";
import Icone from "../../../assets/icones/icone.png"

export const VisualizarDetalhes = ({ info, modalVisualizarDetalhe, setModalVisualizarDetalhe, infoDependente, calculoDetalhe, selecteds, handleClick, handleCarregamento, loadingDetalhes, setInfo, id, handleClickAll, generatePDF, exibirTodos, loadingPdf, enviarEmail }) => {
  const [selectedsNew, setSelectedsNew] = useState([])
  useEffect(() => {
    let dados = [...selecteds]
    setSelectedsNew(dados)

  }, [selecteds])
  return (
    <Modal id="modal-center3" size="xl" isOpen={modalVisualizarDetalhe} centered={true} style={{ maxWidth: 1200 }}  >
      <div className="" style={{ width: '100%', overflowX: 'auto' }}>
        <Row>
          <ExitModal setModal={setModalVisualizarDetalhe} />
        </Row>
        {(loadingPdf) && (
          <div
            style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column', position: "absolute", width: "100%", zIndex: 99999999999999, backgroundColor: `rgb(246, 247, 249, 0.6)` }} >
            <span style={{ fontSize: 12, color: 'ccc', marginBottom: 10 }} >PDF sendo gerado...</span>
            <img src={Icone} className="rotating-image" />

          </div>
        )}
        {loadingDetalhes ?
          <div className={`col-lg-12 col-sm-12 item`} style={{ height: "20vh", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", zIndex: 99999999999999, }}  >
            <img src={Icone} className="rotating-image" />
          </div>
          :
          <Produtos info={info} infoDependente={infoDependente} calculoDetalhe={calculoDetalhe} selecteds={selectedsNew} handleClick={handleClick} handleClickAll={handleClickAll} handleCarregamento={handleCarregamento} loadingDetalhes={loadingDetalhes} setInfo={setInfo} id={id} cotacao={true} exibirTodos={exibirTodos} generatePDF={generatePDF} enviarEmail={enviarEmail} />
        }
      </div>
    </Modal >
  )
};