import { ApiRequestCotacao } from './request';


export const Api = {
  estados: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/estados', dados)
    return response.data.option;
  },
  faixa: async () => {
    let response = await ApiRequestCotacao('post', '/cotacoes/faixa')
    return response.data.input;
  },
  faixas: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/faixas', dados)
    return response.data.input;
  },
  listagem: async () => {
    let response = await ApiRequestCotacao('post', '/cotacoes/listagem')
    return response.data;
  },
  filtro: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/buscar', dados)
    return response.data;
  },
  status: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/status', dados)
    return response.data;
  },
  profissoes: async () => {
    let response = await ApiRequestCotacao('post', '/cotacoes/profissoes')
    return response.data;
  },
  areacomercializacao: async () => {
    let response = await ApiRequestCotacao('post', '/cotacoes/areacomercializacao')
    return response.data;
  },
  cadastro: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/cadastro', dados)
    return response.data;
  },
  delete: async (dados) => {
    let response = await ApiRequestCotacao('delete', '/cotacoes/delete/' + dados?.id)
    return response.data.list;
  },
  calculo: async (dados) => {
    let response = await ApiRequestCotacao('get', `/cotacao/calculo/${dados?.id || ""}?cobertura=${dados?.cobertura || ""}&coparticipacao=${dados?.coparticipacao || ""}&contratacao=${dados?.contratacao || ""}&reembolso=${dados?.reembolso || ""}&acomodacao=${dados?.acomodacao || ""}&municipio=${dados?.municipio || ""}`, dados)
    return response.data;
  },
  calculoDetalhe: async (dados) => {
    let response = await ApiRequestCotacao('post', `/cotacao/detalhes/${dados?.id || ""}?cobertura=${dados?.cobertura || ""}&coparticipacao=${dados?.coparticipacao || ""}&contratacao=${dados?.contratacao || ""}&reembolso=${dados?.reembolso || ""}&municipio=${dados?.municipio}`, dados)
    return response.data;
  },
  gerarPdf: async (dados) => {
    let response = await ApiRequestCotacao('post', `/cotacao/gerarPdf/${dados?.id || ""}?cobertura=${dados?.cobertura || ""}&coparticipacao=${dados?.coparticipacao || ""}&contratacao=${dados?.contratacao || ""}&reembolso=${dados?.reembolso || ""}&municipio=${dados?.municipio}`, dados)
    return response.data;
  },
  enviarEmail: async (dados) => {
    let response = await ApiRequestCotacao('post', `/cotacao/enviarEmail/${dados?.id || ""}?cobertura=${dados?.cobertura || ""}&coparticipacao=${dados?.coparticipacao || ""}&contratacao=${dados?.contratacao || ""}&reembolso=${dados?.reembolso || ""}&municipio=${dados?.municipio}`, dados)
    return response.data;
  },
  dados: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/dados', dados)
    return response.data.list;
  },


  editar: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/editar', dados)
    return response.data;
  },
  municipio: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/municipios', dados)
    return response.data;
  },
  agendar: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/agendar', dados)
    return response.data;
  },
  agendamentos: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/agendamentoUnit', dados)
    return response.data;
  },
  excluirAgendamentos: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/excluiragendamento', dados)
    return response.data;
  },
  agendamento: async () => {
    let response = await ApiRequestCotacao('post', '/cotacoes/agendamento')
    return response.data;
  },
  tiposOperadoras: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/tiposOperadoras', dados)
    return response.data.option;
  },
  compartilhar: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cotacoes/compartilhar', dados)
    return response.data;
  }
};
