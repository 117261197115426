import React from "react";
import Dados from "./index";
import Layouts from "../Home/Layouts";
import "../../assets/css/style.css";

export default function TabelaVisu() {
  return (
    <>
      <Layouts pageTitle="Termos de Uso" breadcrumbs={[
      { name: "Home", path: "../" },
      { name: "Termos de Uso", path: "/termodeuso" }]}>
        <Dados />
      </Layouts>
    </>
  );
}
