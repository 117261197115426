import React from "react";
import Select from "react-select";
import { Col, Input, Label, Row, UncontrolledAlert } from "reactstrap";
import Icone from "../../../assets/icones/icone.png"
export const Cadastrar = ({
    resposta, error, msg, validation, statusProfissoes, handleAreas, estados, operadoras, tiposOperadoras, handleOperadorasValue, handleTiposOperadorasValue, handleNumero, handleProfissoesValue, setStatusCoparticipacao, profissoes, statusCoparticipacao, setStatusContratacao, statusContratacao, setStatusNumeros, statusNumeros, numerovidas, setStatusArea, statusArea, areas, areasSelect, handleAreasValue, produtos, produtosSelect, handleProdutosValue, handleVisualizar, loading, numerovidasSelect, setnumerovidasSelect, setSalvarVisualizar, coparticipacoes, coberturas, contratacoes, numerodevidas, municipios
}) => {
    return (
        //Odonto  -> Ramo : 1, 5 e 6
        <Row style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

            {/* <Col lg={12}>
                <div>
                    <div className="mb-3">
                        <Label htmlFor="example-text-input" className="form-Label">Descrição </Label>
                        <Input name="descricao" value={validation.values.descricao} onChange={validation.handleChange} className="form-control" type="text" />
                    </div>
                </div>
            </Col> */}
            <Row>
                <Col lg={3}>
                    <div>
                        <div className="mb-3">
                            <Label htmlFor="example-text-input" className="form-Label">Ramo </Label>

                            <select name="tipoOperadora" value={validation.values.tipoOperadora} className="form-control" onChange={(e) => {
                                validation.handleChange(e)

                                handleTiposOperadorasValue(e.target.value)

                            }} >
                                <option>Selecione</option>
                                {tiposOperadoras && tiposOperadoras.map((item) => (
                                    <option key={item.id} value={item.id} >{item.nome}</option>
                                ))}

                            </select>
                        </div>


                    </div>
                </Col>
                <Col lg={3} >

                    <div>
                        <div className="mb-3">
                            <Label htmlFor="example-text-input" className="form-Label">Estado</Label>
                            <select name="estados" value={validation.values.estados} disabled={(!loading && validation.values.tipoOperadora) ? false : true} className="form-control" onChange={(e) => {
                                validation.handleChange(e)
                                // handleAreas({ estado: validation.values.estados, operadora: validation.values.operadora, tipoOperadora: validation.values.tipoOperadora })
                                // handleAreasValue([])
                            }} >
                                <option>Selecione</option>
                                {estados && estados.map((item) => (
                                    <option key={item.id} value={item.id} >{item.nome}</option>
                                ))}

                            </select>
                        </div>
                    </div>
                </Col>
                <Col lg={3}>

                    <div>
                        <div className="mb-3">
                            <Label htmlFor="example-text-input" className="form-Label">Município</Label>
                            <select name="municipios" value={validation.values.municipios} disabled={(!loading && validation.values.estados) ? false : true} className="form-control" onChange={(e) => {
                                validation.handleChange(e)
                                // handleAreas({ estado: validation.values.estados, operadora: validation.values.operadora, tipoOperadora: validation.values.tipoOperadora })
                                // handleAreasValue([])
                            }} >
                                <option>Selecione</option>
                                <option value={''}>Todas as Opções</option>
                                {municipios && municipios.map((item) => (
                                    <option key={item.id} value={item.id} >{item.nome}</option>
                                ))}

                            </select>
                        </div>
                    </div>
                </Col>


                <Col lg={3} >
                    <div>
                        <div className="mb-3">
                            <Label htmlFor="example-text-input" className="form-Label">Operadora </Label>

                            <select name="operadora" value={validation.values.operadora} disabled={(!loading && validation.values.estados) ? false : true} className="form-control" onChange={(e) => {
                                validation.handleChange(e)
                            }} >
                                <option>Selecione</option>
                                {operadoras && operadoras.map((item) => (
                                    // <option key={item.id} value={`${item.id}-${item.tipopessoa}`}  >{item.nome}{item.detalhe ? ' - ' + item.detalhe : ''}</option>
                                    <option key={item.id} value={item.id}  >{item.nome}{item.detalhe ? ' - ' + item.detalhe : ''}</option>
                                ))}

                            </select>
                        </div>
                    </div>
                </Col>
            </Row>




            {validation.values.operadora && (validation.values.tipoOperadora == 2 || validation.values.tipoOperadora == 3 || validation.values.tipoOperadora == 4) &&

                <Row>

                    {validation.values.tipoOperadora == 4 &&

                        <Col >
                            <div>
                                <div className="mb-3">
                                    <Label htmlFor="example-text-input" className="form-Label">Número de Vidas</Label>

                                    {/* <Select name="coparticipacao" placeholder='Selecione' isMulti onChange={(e) => {
                                    validation.setFieldValue('coparticipacao', e)
                                    setStatusContratacao(true)
                                    handleNumero({ operadora: validation.values.operadora, tipoOperadora: validation.values.tipoOperadora, coparticipacao: e, contratacao: validation.values.contratacao })

                                }}
                                    options={[{ label: 'Sim', value: 'Sim' }, { label: 'Não', value: 'Não' }, { label: 'Parcial', value: 'Parcial' }, { label: 'Total', value: 'Total' }]}
                                    value={validation.values.coparticipacao}
                                /> */}
                                    <select tabIndex={13} name='numerovidas' value={validation.values.numerovidas} className="form-control" onChange={(e) => {
                                        validation.handleChange(e)
                                    }} >

                                        <option>Selecione</option>
                                        {numerodevidas && numerodevidas?.map((item) => (
                                            <option key={item} value={item} >{item} vidas</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </Col>
                    }

                    {validation.values.tipoOperadora != 4 && validation.values.tipoOperadora != 2 &&
                        <Col >
                            <div>
                                <div className="mb-3">
                                    <Label htmlFor="example-text-input" className="form-Label">Profissões </Label>

                                    <Select placeholder='Selecione' className="basic-multi-select"
                                        isDisabled={validation.values.tipoOperadora == 4 ? (validation.values.numerovidas ? false : true) : false}
                                        options={profissoes.map((item) => { return { value: item.id, label: item.nome } })}
                                        value={validation.values.profissao}
                                        onChange={(val) => {
                                            validation.setFieldValue('profissao', val)

                                        }}
                                    />
                                    {/* <select name="profissao" disabled={validation.values.tipoOperadora == 4 ? (validation.values.numerovidas ? false : true) : false} value={validation.values.profissao} className="form-control" onChange={(e) => {
                                       
                                        // handleProfissoesValue(e.target.value)
                                        // handleNumero({ operadora: validation.values.operadora, tipoOperadora: validation.values.tipoOperadora, coparticipacao: validation.values.coparticipacao, contratacao: validation.values.contratacao })
                                        // handleAreasValue([])
                                    }} >
                                        <option value={''} >Selecione</option>
                                        {profissoes && profissoes.map((item) => (
                                            <option key={item.id} value={item.id} >{item.nome}</option>
                                        ))}

                                    </select> */}


                                </div>
                            </div>
                        </Col>
                    }

                    <Col >
                        <div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">Coparticipação</Label>

                                {/* <Select name="coparticipacao" placeholder='Selecione' isMulti onChange={(e) => {
                                    validation.setFieldValue('coparticipacao', e)
                                    setStatusContratacao(true)
                                    handleNumero({ operadora: validation.values.operadora, tipoOperadora: validation.values.tipoOperadora, coparticipacao: e, contratacao: validation.values.contratacao })

                                }}
                                    options={[{ label: 'Sim', value: 'Sim' }, { label: 'Não', value: 'Não' }, { label: 'Parcial', value: 'Parcial' }, { label: 'Total', value: 'Total' }]}
                                    value={validation.values.coparticipacao}
                                /> */}
                                <select tabIndex={13} name='coparticipacao' value={validation.values.coparticipacao} className="form-control" disabled={validation.values.tipoOperadora == 4 ? validation.values.numerovidas ? false : true : false} onChange={(e) => {
                                    validation.handleChange(e)
                                }} >
                                    <option value={''} >Selecione</option>

                                    {coparticipacoes && coparticipacoes?.map((item) =>
                                    (
                                        <option key={item} value={item} >{(item == 'Sim' || item == 'Total') && `Com Corparticipação`} {item == 'Não' && `Sem Corparticipação`} {item == 'Parcial' && `Com Corparticipação Parcial`}</option>
                                    )
                                    )}
                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col >
                        <div>
                            <div className="mb-3">
                                <Label htmlFor="example-text-input" className="form-Label">Cobertura</Label>

                                {/* <Select name="cobertura" placeholder='Selecione' isMulti onChange={(e) => {
                                    validation.setFieldValue('cobertura', e)
                                    handleNumero({ operadora: validation.values.operadora, tipoOperadora: validation.values.tipoOperadora, coparticipacao: validation.values.coparticipacao, contratacao: validation.values.contratacao, cobertura: e })
                                    handleAreasValue(areasSelect)
                                }}
                                    options={[
                                        { label: 'Ambulatorial', value: 'Ambulatorial' },
                                        { label: 'Hospitalar', value: 'Hospitalar' },
                                        { label: 'Completo (AMB + HOSP + OBST)', value: 'Completo (AMB + HOSP + OBST)' },
                                        { label: 'Completo (AMB + HOSP + OBST) + Odontológico', value: 'Completo (AMB + HOSP + OBST) + Odontológico' },
                                        { label: 'Hospitalar + Odontológico', value: 'Hospitalar + Odontológico' },
                                        { label: 'Ambulatorial + Odontológico', value: 'Ambulatorial + Odontológico' },
                                        { label: 'Hospitalar + Obstetrícia', value: 'Hospitalar + Obstetrícia' },
                                        { label: 'Ambulatorial + Hospitalar', value: 'Ambulatorial + Hospitalar' },
                                        { label: 'Urgência + Emergência', value: 'Completo (AMB + HOSP + OBST) + Odontológico' },]}
                                    value={validation.values.cobertura}
                                /> */}

                                <select tabIndex={13} name='cobertura' disabled={validation.values.tipoOperadora == 4 ? (validation.values.numerovidas ? false : true) : false} value={validation.values.cobertura} className="form-control" onChange={(e) => {
                                    validation.handleChange(e)
                                }} >
                                    <option value={''} >Selecione</option>
                                    {coberturas && coberturas?.map((item) => (

                                        <option key={item} value={item} >
                                            {
                                                [{ id: 'Hospitalar', label: 'Hospitalar' },
                                                { id: 'Urgência + Emergência', label: 'Urgência e Emergência' },
                                                { id: 'Ambulatorial', value: 'Ambulatorial' },
                                                { id: 'Completo (AMB + HOSP + OBST)', value: 'Amb + Hosp Com Obstetrícia' },
                                                { id: 'Completo (AMB + HOSP + OBST) + Odontológico', value: 'Amb + Hosp + Odonto Com Obstetrícia' },
                                                { id: 'Hospitalar + Odontológico', value: 'Hospitalar + Odontológico' },
                                                { id: 'Ambulatorial + Odontológico', value: 'Ambulatorial Com Odonto' },
                                                { id: 'Hospitalar + Obstetrícia', value: 'Hospitalar + Obstetrícia' },

                                                { id: 'Ambulatorial + Hospitalar', value: 'Amb + Hosp Sem Obstetrícia' },
                                                { id: 'Amb + Hosp + Odonto sem Obstetrícia', value: 'Amb + Hosp + Odonto sem Obstetrícia' },
                                                ].find((item2) => item2.id == item)?.value
                                            }</option>
                                    ))}
                                    {/* <option value='Ambulatorial' >Ambulatorial</option>
                                    <option value='Hospitalar' >Hospitalar</option>
                                    <option value='Completo (AMB + HOSP + OBST)' >Completo (AMB + HOSP + OBST)</option>
                                    <option value='Completo (AMB + HOSP + OBST) + Odontológico' >Completo (AMB + HOSP + OBST) + Odontológico</option>
                                    <option value='Hospitalar + Odontológico' >Hospitalar + Odontológico</option>
                                    <option value='Ambulatorial + Odontológico' >Ambulatorial + Odontológico</option>
                                    <option value='Hospitalar + Obstetrícia' >Hospitalar + Obstetrícia</option>
                                    <option value='Ambulatorial + Hospitalar' >Ambulatorial + Hospitalar</option>
                                    <option value='Urgência + Emergência' >Urgência + Emergência</option> */}
                                </select>

                            </div>
                        </div>
                    </Col>

                    {validation.values.tipoOperadora == 4 &&
                        <>
                            <Col >
                                <div>
                                    <div className="mb-3">
                                        <Label htmlFor="example-text-input" className="form-Label">Tipo de Contratação</Label>
                                        <select name="contratacao" disabled={validation.values.tipoOperadora == 4 ? (validation.values.numerovidas ? false : true) : (validation.values.coparticipacao ? false : true)} value={validation.values.contratacao} className="form-control" onChange={(e) => {
                                            validation.handleChange(e)
                                            // handleNumero({ operadora: validation.values.operadora, tipoOperadora: validation.values.tipoOperadora, coparticipacao: validation.values.coparticipacao, contratacao: e.target.value })
                                            // handleAreasValue([])

                                        }} >
                                            <option value={''} >Selecione</option>
                                            {contratacoes && contratacoes.map((item) => (

                                                <>

                                                    {item == "AMBOS" ? "" : <option key={item} value={item} >{item}</option>}

                                                </>

                                            ))}


                                        </select>

                                    </div>
                                </div>
                            </Col>

                        </>
                    }

                </Row>


            }


            {/* <Row>

                <Col lg={12} style={{ marginBottom: 20 }}>
                    <div>
                        <Label htmlFor="example-text-input" className="form-Label">Produtos </Label>
                        <Select placeholder='Selecione' isMulti className="basic-multi-select"
                            isDisabled={validation.values.operadora ? false : true}
                            options={produtos}
                            value={produtosSelect}
                            onChange={(val) => {
                                handleProdutosValue(val)
                            }}
                        />
                    </div>
                </Col>
            </Row> */}

         


            <div style={{ display: "flex", width: '100%', justifyContent: 'center', alignItems: 'center' }} >
                {!loading &&  validation.values.tipoOperadora  &&  estados?.length==0 ?(
                       <p style={{marginTop:20}} >Nenhum resultado encontrado.</p>
                ): !loading && produtos.length < 0 && (validation.values.estados && validation.values.operadora && validation.values.tipoOperadora) ? (
                    <p style={{marginTop:20}}>Produtos não encontrados nessa localidade.</p>
                ) : (
                    <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={(
                            !loading && (
                                validation.values.estados &&
                                    validation.values.operadora &&
                                    validation.values.tipoOperadora &&
                                    [1, 5, 6].includes(validation.values.tipoOperadora) ? true : true &&
                                        [2, 3, 4].includes(validation.values.tipoOperadora) ? (validation.values.coparticipacao && validation.values.cobertura ? true : false) : true
                            )

                        ) ? false : true

                        }
                        onClick={() => {
                            setSalvarVisualizar(true);
                        }}
                        style={{ margin: 20 }}
                    >
                        Visualizar Tabelas
                    </button>
                )} 
                





            </div>


        </Row>
    );
};
