import { ApiRequest } from "./request";

export const Api = {
  editar: async (dados) => ApiRequest("put", `/usuario/${dados?.id}`, dados,true),
  editarFoto: async (dados, id) => ApiRequest("patch", `/usuario/foto/${id}`, dados),
  editarSenha: async (dados) => ApiRequest("patch", `/usuario/senha/${dados?.id}`, dados),
  login: async (dados) => ApiRequest("post", "/usuario/login", dados),
  etapa1: async (dados) => ApiRequest("post", "/usuario/etapa1", dados),
  etapa2: async (dados) => ApiRequest("put", `/usuario/etapa2/${dados?.id || ""}`, dados),
  etapa3: async (dados) => ApiRequest("put", `/usuario/etapa3/${dados?.id || ""}`, dados),
  etapa4: async (dados) => ApiRequest("put", `/usuario/etapa4/${dados?.id || ""}`, dados),
  validarCelular: async (dados) => ApiRequest("post", "/usuario/validaCelular", dados),
  validarEndereco: async (dados) => ApiRequest("post", "/usuario/validaEndereco", dados),
  validarEmail: async (dados) => ApiRequest("post", "/usuario/validaEmail", dados),
  disparoEmail: async (dados) => ApiRequest("post", "/usuario/dispararEmail", dados),
  disparoCelular: async (dados) => ApiRequest("post", "/usuario/dispararCelular", dados),
  dispararCodigo: async (dados) => ApiRequest("post", "/usuario/dispararCodigo", dados),
  validaRecuperacao: async (dados) => ApiRequest("post", "/usuario/validaRecuperacao", dados),
  dispararRecuperacao: async (dados) => ApiRequest("post", "/usuario/dispararRecuperacao", dados),
  novaSenha: async (dados) => ApiRequest("patch", `/usuario/novaSenha/${dados?.id}`, dados),
};