import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Api } from "../../api/apiSite";
import { Login } from "../../pages/Auth/Login";
import React from "react";
function Navigation({ className }) {
  const obj ={}
  const [estadosSaude, setEstadosSaude] = useState();
  const [estadosOdonto, setEstadosOdonto] = useState();
  const [loadingSaude, setLoadingSaude] = useState(false);
  const [loadingOdonto, setLoadingOdonto] = useState(false);
  const [escolha, setEscolha] = useState(0);
  const [escolhasub, setEscolhaSub] = useState(0);
  const [modalLogin, setModalLogin] = useState(false);
  const handleEstadosSaude = async () => {
  
    // let list = await Api.estadosSaude();
    // setEstadosSaude(list?.list);
    // setLoadingSaude(false);
  };
  const handleEstadosOdonto = async () => {
   
    // let list = await Api.estadosOdontologico();
    // setEstadosOdonto(list?.list);
    // setLoadingOdonto(false);
  };


  return (
    <>
      <div className={`restly-header-main-menu ${className || ""}`}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>

          <li>
            <Link to="#">
              Planos de Saúde <i className="fa fa-angle-down" />
            </Link>
            <ul className="sub-menu">
              {!loadingSaude &&
                estadosSaude &&
                estadosSaude.map((item) => (
                  <>
                    <li>
                    <Link to={`/${item.nome.replace(/ /g, "-")}/saude`}>
                      {item.nome}
                    </Link>
                  </li>
                  </>
                
                ))}
              {loadingSaude && (
                <li style={{ color: "#092140", textAlign: "center" }}>
                  Carregando...
                </li>
              )}
            </ul>
          </li>
          <li>
            <Link to="#">
              Planos de Odontológico <i className="fa fa-angle-down" />
            </Link>
            <ul className="sub-menu">
              {!loadingOdonto &&
                estadosOdonto &&
                estadosOdonto.map((item) => (
                 <>
                  <li>
                    <Link to={`/${item.nome.replace(/ /g, "-")}/odontologico`}>
                      {item.nome}
                    </Link>
                  </li>
                 </>
                ))}
              {loadingOdonto && (
                <li style={{ color: "#092140", textAlign: "center" }}>
                  Carregando...
                </li>
              )}
            </ul>
          </li>

          <li>
            <Link to="#">
              Ferramentas de Vendas
              <i className="fa fa-angle-down" />
            </Link>
            <div
              className="sub-menu"
              style={{ padding: 10, overflowY: "auto", height: 250 }}
            >
              <li>
                <Link
                  onClick={() => {
                    if (obj?.id) {
                    } else {
                      setModalLogin(true);
                    }
                  }}
                  to={obj?.id && obj?.id != undefined ? "/venda" : "#"}
                >
                  Todas as Ferramentas
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    setEscolha(escolha == 2 ? 0 : 2);
                  }}
                >
                  Cotacões
                  <i
                    className="fa fa-angle-down"
                    style={{ color: "#505056", marginLeft: 10 }}
                  />
                </Link>
                {escolha == 2 && (
                  <ul>
                    <li style={{ marginLeft: 50 }}>
                      <a
                        style={{ padding: 0 }}
                        href={obj?.id ? "/cotacoes" : "#"}
                        onClick={() => {
                          if (obj?.id) {
                          } else {
                            setModalLogin(true);
                          }
                        }}
                      >
                        Cotações
                      </a>
                    </li>
                    <li style={{ color: "#092140", marginLeft: 50 }}>
                      <a
                        style={{ padding: 0 }}
                        href={obj?.id ? "/cotacoes/minhascotacoes" : "#"}
                        onClick={() => {
                          if (obj?.id) {
                          } else {
                            setModalLogin(true);
                          }
                        }}
                      >
                        Minhas Cotações
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    setEscolha(escolha == 1 ? 0 : 1);
                  }}
                >
                  Vendas Online
                  <i
                    className="fa fa-angle-down"
                    style={{ color: "#505056", marginLeft: 10 }}
                  />
                </Link>
                {escolha == 1 && (
                  <ul>
                    <li
                      style={{
                        color: "#505056",
                        marginLeft: 50,
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setEscolhaSub(escolhasub == 1 ? 0 : 1);
                      }}
                    >
                      Planos de Saúde{" "}
                      <i
                        className="fa fa-angle-down"
                        style={{ color: "#505056", marginLeft: 10 }}
                      />
                    </li>
                    {escolhasub == 1 && (
                      <ul style={{ marginLeft: 20, overflow: "auto" }}>
                        {!loadingSaude &&
                          estadosSaude &&
                          estadosSaude.map((item) => (
                            <>
                              <li>
                                <Link
                                  to={`/venda/${item.nome.replace(
                                    / /g,
                                    "-"
                                  )}/saude`}
                                >
                                  {item.nome}
                                </Link>
                              </li>
                              <br />
                            </>
                          ))}
                        {loadingSaude && (
                          <li
                            style={{
                              color: "#505056",
                              textAlign: "center",
                              marginLeft: 50
                            }}
                          >
                            Carregando...
                          </li>
                        )}
                      </ul>
                    )}

                    <li
                      style={{
                        color: "#505056",
                        marginLeft: 50,
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setEscolhaSub(escolhasub == 2 ? 0 : 2);
                      }}
                    >
                      Planos Odontológicos{" "}
                      <i
                        className="fa fa-angle-down"
                        style={{ color: "#505056", marginLeft: 10 }}
                      />
                    </li>
                    {escolhasub == 2 && (
                      <ul style={{ marginLeft: 20 }}>
                        {!loadingOdonto &&
                          estadosOdonto &&
                          estadosOdonto.map((item) => (
                            <>
                              <li>
                                <Link
                                  to={`/venda/${item.nome.replace(
                                    / /g,
                                    "-"
                                  )}/odontologico`}
                                >
                                  {item.nome}
                                </Link>
                              </li>
                              <br />
                            </>
                          ))}
                        {loadingOdonto && (
                          <li
                            style={{
                              color: "#505056",
                              textAlign: "center",
                              marginLeft: 50
                            }}
                          >
                            Carregando...
                          </li>
                        )}
                      </ul>
                    )}
                  </ul>
                )}
              </li>
              <li>
                <Link to="#">Divulgação</Link>
              </li>
              <li>
                <Link to="#">Leads</Link>
              </li>
            </div>
          </li>
        </ul>
        <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />
      </div>
    </>
  );
}

export default Navigation;