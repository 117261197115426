import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logos/logo.png";
import logoAqui from "../../../assets/images/sualogoaqui.png";
import StickyMenu from "../../../lib/StickyMenu";
import Navigation from "../../../components/helpers/Navigation";
import MobileHeaderCom from "./Mobile";
import { Login } from "../../Auth/Login";
import { MeusDados } from "../../Auth/MeusDados";
import { v4 as uuidV4 } from "uuid"
import { baseUrlApi } from "../../../api/baseUrl";
import { getCookie, saveCookie } from "../../../components/cookies";
export default function HeaderHomeThree() {
  useEffect(() => {
    StickyMenu(".main-header");
  });
  const [modalLogin, setModalLogin] = useState();
  const [modalDados, setModalDados] = useState();
  const cookie = getCookie('authUser')
  const obj = cookie
  useEffect(() => {
    const id = obj?.id
    if (!id) {
      saveCookie('id', JSON.stringify(uuidV4()));
    }
  }, [])
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [fixed, setFixed] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        setFixed(false)
      } else {
        setFixed(true)
      }
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <>
      <header className="main-header header-three text-white" >
        <div className="header-upper" style={{ background: 'none', boxShadow: "none", border: 'none', position: fixed ? 'fixed' : '' }}>
          <div className="container clearfix" style={{ boxShadow: '0px 4px 4px 0px #0921400D', marginTop: 5, backgroundColor: '#fff' }}>
            <MobileHeaderCom logo={logo} />
          </div>
        </div>
      </header>

      <header className="main-header header-three text-white" >
        <div className="header-upper" style={{ display: 'none' }}>
          <div className="container clearfix">
            <MobileHeaderCom logo={logo} />
            <div className="header-inner d-flex align-items-center"  >
              <div className="logo-outer">
                <div className="logo">
                  <a href="/">
                    <img src={logo} alt="Logo" title="Logo" style={{ width: "35%", marginRight: 10 }} />
                    <img src={obj?.foto ? `${baseUrlApi}/imagens/${obj?.foto}` : logoAqui} alt="Logo" title="Logo" style={{ width: "35%" }} />
                  </a>
                </div>
              </div>
              <div className="nav-outer clearfix d-flex align-items-center" >
                <div className="main-menu navbar-expand-lg">
                  <Navigation />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />
        <MeusDados modalDados={modalDados} setModalDados={setModalDados} />
      </header>
    </>
  );
}
