import { useEffect, useState } from "react";
import { Row, Form } from "reactstrap";
import { Api } from "../../../api/apiUsuario";
import React from "react";
import { Col, Input, Label } from "reactstrap";
import InputMask from 'react-input-mask';
import { useCookies } from "react-cookie";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { AlertResposta } from "../../../components/loading/alert";
import { getCookie } from "../../../components/cookies";

export const Endereco = () => {
    const [resposta, setResposta] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const cookie = getCookie('authUser');
    const [obj, setObj] = useState({})



    const validation = useFormik({
        enableReinitialize: true,
        validationSchema: Yup.object({
            id: Yup.string(),
            bairro: Yup.string().nullable().optional(),
            complemento1: Yup.string().nullable().optional(),
            ddd: Yup.string().nullable().optional(),
            ibge: Yup.string().nullable().optional(),
            rua: Yup.string().nullable().optional(),
            cidade: Yup.string(),
            siafi: Yup.string().nullable().optional(),
            estado: Yup.string(),
            cep: Yup.string()
        }),
        initialValues: {
            id: obj?.id,
            bairro: obj?.endereco?.bairro,
            complemento1: obj?.endereco?.complemento1,
            ddd: obj?.endereco?.ddd,
            ibge: obj?.endereco?.ibge,
            rua: obj?.endereco?.rua,
            cidade: obj?.endereco?.cidade,
            siafi: obj?.endereco?.siafi,
            estado: obj?.endereco?.estado,
            cep: obj?.endereco?.cep,
        },
        onSubmit: async (values) => {
            setLoading(true)
            let response = await Api.editar(values);
            if (response?.error) {
                setError(true)
                setResposta(true)
                setMsg(response?.message)
            } else {
                setError(false)
                setResposta(true)
                setMsg(response?.message)
                let dados = { ...obj }
                dados.endereco = { ...dados?.endereco, ...values }
                setCookie('authUser', JSON.stringify(dados), {});
            }
            setLoading(false)
            document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
    });
    const handleCEP = async (string) => {
        let cep = string?.replace(/\D/g, "");
        validation.setFieldValue('cep', cep)
        if (cep?.length == 8) {
            await fetch(`https://viacep.com.br/ws/${cep}/json`)
                .then((res) => res.json())
                .then((data) => {
                    if (data?.cep) {
                        setError(false)
                        setResposta(false)
                        setMsg('')
                        validation.setFieldValue('estado', data?.uf)
                        validation.setFieldValue('bairro', data?.bairro)
                        validation.setFieldValue('rua', data?.logradouro)
                        validation.setFieldValue('complemento', data?.complemento)
                        validation.setFieldValue('cidade', data?.localidade)
                        validation.setFieldValue('siafi', data?.siafi)
                        validation.setFieldValue('ddd', data?.ddd)
                    } else {
                        setError(true)
                        setResposta(true)
                        setMsg('CEP inválido.')
                    }
                })
                .catch(() => {
                    setError(true)
                    setResposta(true)
                    setMsg('CEP inválido.')
                })
        }
    };
    useEffect(() => {
        let objs = cookie
        setObj(objs)
    }, [])
    return (
        <Form
            className="custom-form"
            enctype='multipart/form-data'
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}>
            <div>
                <Row style={{ flex: 1, marginTop: 20, flexDirection: 'row' }}>


                    <Col lg={6}>
                        <div>
                            <div className="mb-1">
                                <Label
                                    htmlFor="example-text-input"
                                    className="form-Label"
                                    title='O CEP é necessário para identificar a principal região de atuação e otimizar o uso das ferramentas de vendas.'
                                >
                                    *CEP (?)
                                </Label>
                                <InputMask mask="99999-999" className="form-control" maskPlaceholder={null} value={validation.values.cep} onChange={(e) => {
                                    handleCEP(e.target?.value)
                                }} onBlur={validation.handleBlur} id="cep" />
                                {validation.touched.cep && validation.errors.cep && (
                                    <div style={{ color: 'red' }}>{validation.errors.cep}</div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div>
                            <div className="mb-1">
                                <Label htmlFor="example-text-input" className="form-Label" >
                                    Estado
                                </Label>
                                <Input name="estado" autocomplete="new-password" disabled value={validation.values.estado} className="form-control" type="text" />
                                {validation.touched.estado && validation.errors.estado && (
                                    <div style={{ color: 'red' }}>{validation.errors.estado}</div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div>
                            <div className="mb-1" >
                                <Label htmlFor="example-text-input" className="form-Label" >
                                    Cidade
                                </Label>
                                <Input name="cidade" autocomplete="new-password" disabled value={validation.values.cidade} className="form-control" type="text" />
                                {validation.touched.cidade && validation.errors.cidade && (
                                    <div style={{ color: 'red' }}>{validation.errors.cidade}</div>
                                )}
                            </div>
                        </div>
                    </Col>


                    <button className="btn btn-primary" type="submit" disabled={loading} style={{ position: 'absolute', bottom: 20, right: 25 }}>Salvar</button>
                </Row >


            </div>

        </Form>
    )
}