import { useEffect, useRef, useState } from "react";
import { Modal, Row } from "reactstrap";
import { Api } from "../../../../api/apiParceiros";
import React from "react";
import { Col, Input, Label, UncontrolledAlert } from "reactstrap";
import InputMask from 'react-input-mask';
import { ExitModal } from "../../../components/exitModal";
export const Parceiro = ({ setModalDados, modalDados }) => {

    const [nome, setNome] = useState();
    const [celular, setCelular] = useState('');
    const [possuiCnpj, setPossuiCnpj] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [msg, setMsg] = useState('')
    const [resposta, setResposta] = useState(false)
    useEffect(() => {
        setNome("")
        setCelular("")
        setPossuiCnpj("")
    }, [])

    const enviar = async () => {
        let response = await Api.cadastrar({ nome, celular, possuicnpj: possuiCnpj, cnpj })
        if (!response?.error) {
            setNome("")
            setCelular("")
            setPossuiCnpj("")
            setResposta(response?.error)
            setMsg(response?.message)
        } else {
            setResposta(response?.error)
            setMsg(response?.message)
        }
    }

    return (
        <Modal id="modal-center3" size="lg" centered={true} isOpen={modalDados}>
            <div className="modal-header" style={{ border: 'none' }} >
                <ExitModal setModal={setModalDados} />

            </div>
            <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }} >

                <p style={{ color: '#092140', fontSize: 20, textAlign: 'center' }}>
                    Seja nosso Parceiro<br />
                    <span style={{ color: resposta ? 'red' : 'green', fontSize: 15 }} >{msg}</span>
                </p>
                <Row style={{ marginTop: 40 }}>
                    <Col lg={6} className="pt-5">
                        <div>
                            <div className="mb-1">
                                <Label htmlFor="example-text-input" className="form-Label" >
                                    Nome
                                </Label >
                                <Input name="nome" value={nome} onChange={(e) => { setNome(e.target.value); }} className="form-control" type="text" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} className="pt-5">
                        <div>
                            <div className="mb-1">
                                <Label htmlFor="example-text-input" className="form-Label" >
                                    Celular
                                </Label >
                                <InputMask mask="(99) 99999-9999" className="form-control" value={celular} onChange={(e) => { setCelular(e.target.value); }} />

                            </div>
                        </div>
                    </Col>
                    <Col lg={possuiCnpj == 'Sim' ? '6' : 12} className="pt-5">
                        <div>
                            <div className="mb-1">
                                <Label htmlFor="example-text-input" className="form-Label" >
                                    Possui CNPJ?
                                </Label>
                                <select name="possuiCnpj" value={possuiCnpj} onChange={(e) => { setPossuiCnpj(e.target.value); }} className="form-control" type="text" >
                                    <option value={''} >Selecione</option>
                                    <option value={'Sim'} >Sim</option>
                                    <option value={'Não'} >Não</option>
                                </select>

                            </div>
                        </div>
                    </Col>
                    {possuiCnpj == "Sim" &&
                        <Col lg={6} className="pt-5">
                            <div>
                                <div className="mb-1">
                                    <Label htmlFor="example-text-input" className="form-Label" >
                                        CNPJ
                                    </Label >
                                    <InputMask mask="99.999.999/9999-99" className="form-control" value={cnpj} onChange={(e) => { setCnpj(e.target.value); }} />

                                </div>
                            </div>
                        </Col>

                    }

                </Row>
            </div>
            <div className="modal-footer" style={{ display: 'block', border: 'none' }} >


                <button className="btn btn-primary" style={{ float: 'left' }} onClick={enviar} >Enviar</button>
            </div>

        </Modal>

    )
}