import React, { useState } from "react";
import { Col, Modal, Row } from "reactstrap";

export const Footer = ({ visualizar, tabelas, municipio, operadora }) => {
    const [municipios, setMunicipios] = useState([])
    const [modalMunicipios, setModalMunicipios] = useState(false)
    const handleMunicipios = (municipio) => {
        setMunicipios(municipio)
        setModalMunicipios(true)
    }

    const ObjectDiversos = ({ index, title, dados }) => {
        return (
            <>
                {visualizar && dados?.length > 0 &&
                    <div className="page-break" style={{ breakInside: 'avoid', paddingTop: 20 }}>
                        <div className="page-break" style={{ breakInside: 'avoid', border: "1px solid #ccc", height: '100%', padding: '10px 10px 0px 0px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                            <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 16, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px',marginBottom:0 }}>
                                {title}
                            </p>
                            {index == 0 &&
                                <p style={{ fontSize: 14, color: 'red', textAlign: 'center', lineHeight: 1.2 }}>"Esta simulação poderá variar de acordo com o perfil do cliente e a critério da operadora.<br /> Preços, condições e critérios de aceitação da proposta estão sujeitos a analise e confirmação no momento da implantação do contrato."</p>
                            }

                            <Col lg={12}>
                                <div className='' style={{ border: 'none', borderWidth: 1, lineHeight: 1.4, fontSize: 16, height: '100%' ,marginBottom:0}} >
                                    <span style={{ color: '#092140' }} >{operadora}</span><br />
                                    {dados?.length > 0 && dados?.map((item, index) => (
                                        <div>
                                            <p>
                                                <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span>
                                                <p style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.4 }} >{item?.produto.join(' | ')}</p>
                                            </p>
                                            <p style={{ fontSize: 16 }}>
                                                <p style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.item.obs
                                                            ?.replace(/font-family:[^;]*;/g, '')
                                                            ?.replace(/background-color:[^;]*;/g, '')
                                                            ?.replace(/font-size:[^;]*;/g, '')
                                                    }}
                                                ></p>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        <div>
            <table className="table" style={{ marginTop: 0 }}  >
                <tbody id='tabela' style={{ display: "flex", paddingRight: 30, paddingLeft: 30, flexDirection: "column", marginTop: 0 }}>
                    <ObjectDiversos index={0} title={'Observações Importantes'} dados={tabelas?.observacoes} />

                    <ObjectDiversos index={1} title={'Rede de Atendimento (RESUMO)'} dados={tabelas?.rede} />

                    <ObjectDiversos index={2} title={'Diferenciais e Reembolsos'} dados={tabelas?.reembolso} />

                    <ObjectDiversos index={3} title={'Carências'} dados={tabelas?.carencia} />

                    <ObjectDiversos index={4} title={'Dependentes'} dados={tabelas?.dependente} />

                    <ObjectDiversos index={5} title={'Documentos Necessários'} dados={tabelas?.documento} />

                    <ObjectDiversos index={6} title={'Formas de Pagamentos'} dados={tabelas?.pagamento} />

                    <ObjectDiversos index={7} title={'Coparticipação'} dados={tabelas?.coparticipacoes} />

                    {visualizar && tabelas?.entidades?.length > 0 &&
                        <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                            <div style={{ breakInside: 'avoid', border: "1px solid #ccc", margin: 15, height: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px' }}>Entidades e Profissões  </p>
                                <Col lg={12}>
                                    <div className='' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%'}}  >
                                        <span style={{ color: '#092140' }} >{operadora}</span><br />
                                        <p style={{ fontWeight: 'bold' }} >
                                            <p>
                                                <span style={{ fontWeight: 'bold', color: '#092140', marginRight: 5 }}>Produtos:</span>
                                                <span style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.5 }}>
                                                    {[...new Set(tabelas?.entidades?.flatMap(entidade => entidade.produto) || [])].join(' | ')}
                                                </span>
                                            </p>
                                        </p>

                                        {tabelas?.entidades && tabelas?.entidades?.map((ent) => (
                                            <div key={`entidades-${ent.id}`} style={{ marginBottom: 0 }}>
                                                <p style={{ lineHeight: 1.6 }}>
                                                    {(ent?.item?.gruposentidades && ent?.item?.gruposentidades?.length > 0) ?

                                                        ent?.item?.gruposentidades?.map((grup) => (

                                                            <p key={ent?.id} style={{ whiteSpace: 'pre-wrap', textAlign: 'justify', color: '#092140', fontWeight: 'bold', fontSize: 12 ,lineHeight:2,marginBottom:0}}>

                                                            <span style={{ whiteSpace: 'pre-wrap', textAlign: 'justify', color: '#092140', fontWeight: 'bold', fontSize: 16, marginRight: 5 }} >
                                                            {grup?.entidade?.nome}:
                                                            </span>
                                                            {grup?.entidadesprofisso?.profisso?.nome} </p>


                                                        ))

                                                        :
                                                        <span key={ent?.id} style={{ whiteSpace: 'pre-wrap', textAlign: 'justify', color: '#092140', fontWeight: 'bold', fontSize: 12 }}>
                                                            <span style={{ whiteSpace: 'pre-wrap', textAlign: 'justify', color: '#092140', fontWeight: 'bold', fontSize: 16, marginRight: 5 }} >
                                                                {ent?.item ? ent?.item?.entidade?.nome : ent?.entidade?.nome}:</span>
                                                            {ent?.item ? ent?.item?.profisso?.nome : ent?.profisso?.nome} - </span>
                                                    }
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </div>
                        </div>
                    }
                    {visualizar && tabelas?.areacomercializacao?.length > 0 &&
                        <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                            <div style={{ breakInside: 'avoid', border: "1px solid #ccc",  height: '100%', padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                                <p style={{ textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-26px', paddingLeft: '20px', paddingRight: '20px' }}>Áreas de Comercialização  </p>
                                <Col lg={12}>
                                    <div className='' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                                        <span style={{ color: '#092140' }} >{operadora}</span><br />
                                        {tabelas?.areacomercializacao && tabelas?.areacomercializacao?.map((item) => (
                                            <div key={item} style={{ marginBottom: 0 }} >
                                                <p>
                                                    <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span>
                                                    <p style={{ fontSize: 12, textAlign: 'justify', lineHeight: 1.8 }} >{item?.produto.join(' | ')}</p>
                                                </p>


                                                {item?.municipios.length > 0 && item?.municipios?.filter((item, index, self) => index === self?.findIndex((m) => m.idestado === item.idestado)).map((muni) => (
                                                    <div style={{ whiteSpace: 'pre-wrap', textAlign: 'justify', color: '#092140', fontWeight: 'bold', fontSize: 16 }}>

                                                        {!tabelas?.areacomercializacao[0]?.descricoes?.some((element) => (Number(element?.idestado) == muni.idestado && element.descricao)) ?
                                                            <>
                                                                {item?.municipios.map((item3, index) => (
                                                                    <>
                                                                        <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3?.nome || item3.munici)} -` }}  ></span>
                                                                    </>
                                                                ))}
                                                            </>
                                                            :
                                                            <>
                                                                <span key={`Comercializacao-`}  >{tabelas?.areacomercializacao && tabelas?.areacomercializacao[0]?.descricoes.find((element) => Number(element.idestado) == muni.idestado)?.descricao}</span><br />
                                                                <a style={{ textDecoration: 'underline', textAlign: 'justify' }} onClick={() => { handleMunicipios(item?.municipios) }} > Ver municípios</a>
                                                            </>
                                                        }
                                                    </div>
                                                ))

                                                }
                                                <br />
                                                <p  >{item.descricao}</p>

                                            </div>

                                        ))}

                                    </div>
                                </Col>
                            </div>
                        </div>
                    }


                </tbody>
            </table>
            <Modal isOpen={modalMunicipios} centered={true}>
                <div className="modal-header" style={{ border: 'none' }}>
                    <button
                        type="button"
                        onClick={() => {
                            setModalMunicipios(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
                    <p style={{ color: '#092140', textAlign: 'center' }}>Municípios</p>
                    <Row style={{ justifyContent: 'center', alignItems: 'center' }} >
                        {municipios.map((item3, index) => (
                            <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3.municipio.nome)} -` }}  ></span>
                        ))}
                    </Row>
                </div>
            </Modal>
        </div >
    )
};
