import React from "react";
import { useParams } from "react-router-dom";
import Dados from "./index";
import Layouts from "../Home/Layouts";

export default function ServiceOne() {
  const { estado, plano } = useParams();
 
  return (
    <Layouts
      style={{ fontFamily: "Open Sans, sans-serif" ,textTransform:'none'}}
      pageTitle={`Pesquisar Produtos`}
      
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: `Pesquisar Produtos`, path: "/ramos" },
      ]}
    >
      <Dados plano={plano} estado={estado} />
    </Layouts>
  );
}
