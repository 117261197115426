import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import Info from "./info";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { baseUrlApi } from "../../../api/baseUrl";
export default function MainSection({ datas, plano }) {
  const [isotope, setIsotope] = useState(null);
  const [filterKey, setFilterKey] = useState("fornecedor");
  const [escolha, setEscolha] = useState("");
  const [dados, setDados] = useState([]);
  // const { estado, plano } = useParams();
  const [itemSize, setSize] = useState("0px");
  const [item2, setItem] = useState("");
  const handleFiltro = (val) => {
    if (val == "fornecedor") {
      let fisica = datas.valores?.filter((item) => item.filter == "fornecedor");
      setDados(fisica);
      setEscolha("fornecedor");
    }
    if (val == "ramo") {
      let juridico = datas.valores?.filter((item) => item.filter == "ramo");
      setDados(juridico);
      setEscolha("ramo");
    }
  };
  const handleDados = () => {
    handleFiltro();
  };
  useEffect(() => {

    handleDados();
  }, [datas]);
  const handler = (e, value) => {
    e.preventDefault();
    const getItems = document.querySelectorAll(`#${value}`).length;
    if (getItems > 0) {
      if (item2 !== value) {
        setItem(value);
      } else {
        setItem("");
      }
    }
  };
  return (
    <section className="portfolio-section   ">
      <div className="container">
        {plano != 'pet' &&
          <ul className="portfolio-filter mb-45" style={{ listStyleType: 'none' }}>
            <li
              onClick={() => handleFiltro("ramo")}
              className={escolha === "ramo" ? "current" : ""}
              style={{ fontFamily: "Open Sans, sans-serif", border: '1px solid #4180D6', fontWeight: 100 }}
            >
              Produtos
            </li>
            <li
              onClick={() => handleFiltro("fornecedor")}
              className={escolha === "fornecedor" ? "current" : ""}
              style={{
                fontFamily: "Open Sans, sans-serif",
                border: '1px solid #4180D6', fontWeight: 100
              }}
            >
              Fornecedores
            </li>

          </ul>

        }

        <div className="row align-items-center  portfolio-wrap">
          {dados && (plano != 'pet' ? true : escolha) &&
            dados.length > 0 &&
            dados.map((item) => (
              <a
                key={item.id}
                className={`col-lg-12 col-sm-12 item `}
                style={{ cursor: "pointer" }}
                onClick={(e) => handler(e, "operadora" + item.id)}
              >
                <div className="feature-item   style-three " style={{ marginBottom: 10, padding: 10 }}>
                  <div className="icon"></div>

                  <div className="feature-content style-three" style={{ marginBottom: 5 }}>
                    <Link
                      className="menu-item-has-children active"
                      style={{
                        fontFamily: "Open Sans, sans-serif",
                        marginLeft: 10,
                        fontSize: 15
                      }}
                    
                      id={"operadora" + item.id}
                    >
                      <div style={{ float: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>
                        <img style={{ width: "35px" }}
                          src={`${baseUrlApi}/imagens/${item.image}`} />
                        <a href="#" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.4, width: 220 }}>
                          {item.title}
                        </a>
                      </div>
                      <div style={{ float: 'right' }}>
                        <span
                          className="menu-expand"  >
                          {item2 == (`operadora${item?.id}`) ?
                              <FaAngleUp />
                              :
                              <FaAngleDown />
                            }
                        </span>
                      </div>
                    </Link>
                    <div
                      className="sub-menu"
                      style={{
                        display:
                          item2 == "operadora" + item.id ? "block" : "none"
                      }}
                    >
                      <Info tipos={item.tipos} />
                    </div>
                  </div>
                </div>
              </a>
            ))}
        </div>
      </div>
    </section>
  );
}
