import React from "react";
import Dados from "./index";
import Layouts from "../Home/Layouts";
import "../../assets/css/style.css";

export default function TabelaVisu() {
  return (
    <>
      <Layouts pageTitle="Política de Privacidade" breadcrumbs={[
        { name: "Home", path: "../" },
        { name: "Política de Privacidade", path: "/politicadeprivacidade" }]}>
        <Dados />
      </Layouts>
    </>
  );
}
