import React, { useState } from "react";
import { Col, Input, Label, Form, Row, UncontrolledAlert } from "reactstrap";
import { Api } from "../../../api/apiUsuario";
import { useFormik } from "formik";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

export const Etapa3 = ({ setProximo, setid, id, setModalValidaEmail, setModalValidaCelular }) => {
  document.title = "Corretor Parceiro";
  const [resposta, setResposta] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const validation = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string(),
      bairro: Yup.string().nullable().optional(),
      complemento1: Yup.string().nullable().optional(),
      ddd: Yup.string().nullable().optional(),
      ibge: Yup.string().nullable().optional(),
      rua: Yup.string().nullable().optional(),
      cidade: Yup.string(),
      siafi: Yup.string().nullable().optional(),
      estado: Yup.string(),
      cep: Yup.string(),

    }),
    initialValues: {
      id: id,
      bairro: "",
      complemento1: "",
      ddd: "",
      ibge: "",
      rua: "",
      cidade: "",
      siafi: "",
      estado: "",
      cep: "",
    },
    onSubmit: async (values) => {
      setLoading(true)
      if (id) {
        let response = await Api.etapa3(values);
        if (response?.error) {
          setError(true)
          setResposta(true)
          setMsg(response?.message)
        } else {
          setid(response?.id)
          if (!response.validaemail) {
            setModalValidaEmail(true)
          }
          if (!response.validacelular) {
            setModalValidaCelular(true)
          }
        }
      } else {
        setProximo(1);
      }
      setLoading(false)
      document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  });

  const handleCEP = async (string) => {
    let cep = string?.replace(/\D/g, "");
    validation.setFieldValue('cep', cep)
    if (cep?.length == 8) {
      await fetch(`https://viacep.com.br/ws/${cep}/json`)
        .then((res) => res.json())
        .then((data) => {

          if (data?.cep) {
            setError(false)
            setResposta(false)
            setMsg('')
            validation.setFieldValue('estado', data.uf)
            validation.setFieldValue('bairro', data.bairro)
            validation.setFieldValue('rua', data.logradouro)
            validation.setFieldValue('complemento', data.complemento)
            validation.setFieldValue('cidade', data.localidade)
            validation.setFieldValue('siafi', data.siafi)
            validation.setFieldValue('ddd', data.ddd)
          } else {
            setError(true)
            setResposta(true)
            setMsg('CEP inválido.')
          }
        })
        .catch(() => {
          setError(true)
          setResposta(true)
          setMsg('CEP inválido.')
        })
    }
  };

  return (
    <Form
      className="custom-form"
      enctype='multipart/form-data'
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}>
      <Row className="g-0">
        <Col lg={12} md={12} className="col-xxl-12">
          <div className="auth-full-page-content d-flex p-sm-2 p-4">
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                <div className="auth-content my-auto">
                  <div className="text-center">
                    <p style={{ color: '#092140', fontSize: 20 }}>Endereço</p>
                  </div>
                 
                  <Row>
                    <Col lg={6}>
                      <div>
                        <div className="mb-1">
                          <Label
                            htmlFor="example-text-input"
                            className="form-Label"
                            title='O CEP é necessário para identificar a principal região de atuação e otimizar o uso das ferramentas de vendas.'
                          >
                            *CEP (?)
                          </Label>
                          <InputMask mask="99999-999" className="form-control" maskPlaceholder={null} value={validation.values.cep} onChange={(e) => {
                            handleCEP(e.target?.value)
                          }} onBlur={validation.handleBlur} id="cep" />
                          {validation.touched.cep && validation.errors.cep && (
                            <div style={{ color: 'red' }}>{validation.errors.cep}</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <div className="mb-1">
                          <Label htmlFor="example-text-input" className="form-Label" >
                            Estado
                          </Label>
                          <Input name="estado" autocomplete="new-password" disabled value={validation.values.estado} className="form-control" type="text" />
                          {validation.touched.estado && validation.errors.estado && (
                            <div style={{ color: 'red' }}>{validation.errors.estado}</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div>
                        <div className="mb-1" >
                          <Label htmlFor="example-text-input" className="form-Label" >
                            Cidade
                          </Label>
                          <Input name="cidade" autocomplete="new-password" disabled value={validation.values.cidade} className="form-control" type="text" />
                          {validation.touched.cidade && validation.errors.cidade && (
                            <div style={{ color: 'red' }}>{validation.errors.cidade}</div>
                          )}
                        </div>
                      </div>
                    </Col>

                  </Row>


                  <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: 15 }} >
                    <div>
                      <button className="btn btn-primary" style={{ float: "left" }}
                        onClick={() => {
                          setProximo(2);
                        }} >
                        Voltar
                      </button>
                    </div>
                    <div>
                      <button className="btn btn-primary" disabled={loading} style={{ float: "right" }}
                        type="submit" >
                        Avançar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
