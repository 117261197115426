import React from "react";
import { useNavigate } from "react-router-dom";
import errorImg from "../../assets/images/404.png";

export default function FourZeroFour() {
  const navigate = useNavigate();
  return (
    <section className="error-page text-center py-120 rpy-100">
      <div className="container">
        <div className="error-content">
          <img src={errorImg} alt="404 Error" />
          <h2>Oops! Sessão expirada ou não iniciada.</h2>
          <p>
            Parece que sua sessão expirou ou você não está logado. Para acessar esta página, é necessário estar autenticado com uma sessão ativa. Por favor, faça login novamente para continuar. Se você acredita que isso foi um erro, tente atualizar a página ou verificar sua conexão. Caso o problema persista, entre em contato com o suporte. Agradecemos sua compreensão!
          </p>
          <a
            onClick={(e) => {
              e.preventDefault();
              navigate('/');
            }}
            className="theme-btn mt-30"
          >
            Voltar para inicio
          </a>
        </div>
      </div>
    </section>
  );
}