export const baseUrlApi = `${process.env.REACT_APP_ENVIROMENT == "DEV" ? getBaseUrl(`${process.env.REACT_APP_URL_DEV}`) : `${process.env.REACT_APP_URL}`}`
export const baseUrlApp = getBaseUrl(`${process.env.REACT_APP_ENVIROMENT == "DEV" ? `${process.env.REACT_APP_SITE_DEV}` : (`${process.env.REACT_APP_SITE}`)}`)
export const baseUrlSite = getBaseUrl(`${process.env.REACT_APP_ENVIROMENT == "DEV" ? `${process.env.REACT_APP_SITEWEB_DEV}` : (`${process.env.REACT_APP_SITEWEB}`)}`)

function getBaseUrl(porta) {
    const urlAtual = window.location.href;
    const urlObj = new URL(urlAtual);
    const hostnameAtual = urlObj.hostname;
    const partesDominio = hostnameAtual.split('.');
    const regexIpV4 = /^(?:\d{1,3}\.){3}\d{1,3}$/;
    if (partesDominio?.length > 2 && partesDominio[0] === 'www') {
        partesDominio.shift();
    }
    if (partesDominio?.length > 2) {
        partesDominio.slice(-2).join('.');
    }
    if (partesDominio?.includes("localhost")) {
        return `${window.location.protocol}//localhost:${porta || "3000"}`;
    }
    if (regexIpV4.test(hostnameAtual)) {
        return `${window.location.protocol}//${hostnameAtual}:${porta}`;
    }
    return `${window.location.protocol}//${porta}.${partesDominio?.join(".")}`;
}