export const saveCookie = (name, value, days = 7) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);

    // Armazena como JSON para evitar erros
    localStorage.setItem(name, JSON.stringify({ value, expires: expires.getTime() }));
};

export  const getCookie =  (name) => {
    const storedData = localStorage.getItem(name);
    if (!storedData) return null;

    try {
        const parsedData = JSON.parse(storedData);
        return parsedData.value; // Retorna o valor armazenado
    } catch (error) {
        console.error("Erro ao parsear cookie:", error);
        return null;
    }
};

export const removeCookie = (name) => {
    localStorage.removeItem(name);
};