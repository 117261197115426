import React, {  useState } from "react";
import { Col, Input, Label, Form, Row } from "reactstrap";
import { Api } from "../../../api/apiUsuario";
import { useFormik } from "formik";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

export const Etapa1 = ({ setProximo, setid, id, setTipoPessoa, setEmail, setCelular }) => {
    document.title = "Corretor Parceiro";

    const [resposta, setResposta] = useState(false);
    const [error, setError] = useState(false);
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [mask, setMask] = useState('999.999.999-99');

    const validation = useFormik({
        enableReinitialize: true,
        validationSchema: Yup.object({
            id: Yup.string().optional(''),
            documento: Yup.string().required('CPF ou CNPJ é obrigatório'),
            celular: Yup.string().required('O campo Celular é obrigatório'),
            email: Yup.string()
                .transform((value) => (value ? value.toLowerCase() : ''))
                .email('E-mail inválido')
                .required('O campo E-mail é obrigatório'),
            termosUsoPoliticas: Yup.bool()
                .oneOf([true], 'Você deve aceitar os termos de uso e Política de Privacidade do portal')
                .required('Confirme que leu e aceitou os termos'),
        }),
        initialValues: {
            id: id || '',
            documento: '',
            celular: '',
            email: '',
            termosUsoPoliticas: ''
        },
        onSubmit: async (values) => {
            setLoading(true)
            let response = await Api.etapa1(values);
            if (response?.error) {
                setError(true)
                setResposta(true)
                setMsg(response?.message)
            } else {
                setEmail(values?.email)
                setCelular(values?.celular)
                validation.resetForm()
                setid(response?.id)
                setTipoPessoa(values?.documento?.replace(/\D/g, '')?.length > 11 ? 'PJ' : 'PF')
                setProximo(2);
            }
            setLoading(false)
            document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
    });
    const handleChange = (event) => {
        let onlyNumbers = event.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos

        if (onlyNumbers.length > 14) return onlyNumbers.slice(0, 14);
        
        if (onlyNumbers.length <= 11) {
            onlyNumbers = onlyNumbers.replace(/^(\d{3})(\d)/, "$1.$2")
                .replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3")
                .replace(/\.(\d{3})(\d)/, ".$1-$2");
        } else {
            onlyNumbers = onlyNumbers.replace(/^(\d{2})(\d)/, "$1.$2")
                .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
                .replace(/\.(\d{3})(\d)/, ".$1/$2")
                .replace(/(\d{4})(\d)/, "$1-$2");
        }
        validation.setFieldValue('documento', onlyNumbers)
    };
  
    return (
        <Form
            className="custom-form"
            enctype='multipart/form-data'
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}>
            <Row className="g-0">
             
                <Col lg={12} md={12} className="col-xxl-12">
                    <div className="auth-full-page-content d-flex p-sm-2 p-4">
                        <div className="w-100">
                            <div className="d-flex flex-column h-100">
                                <div className="auth-content my-auto">
                                    <div className="text-center">
                                        <p style={{ color: '#092140', fontSize: 20 }}>Crie seu Usuário</p>
                                    </div>
                                    {validation.touched.termosUsoPoliticas && validation.errors.termosUsoPoliticas && (
                                        <div style={{ color: 'red', textAlign: 'center' }}>{validation.errors.termosUsoPoliticas}</div>
                                    )}
                                    
                                    <Row>
                                        <Col lg={6}>
                                            <div>
                                                <div className="mb-1">
                                                    <Label htmlFor="example-text-input" className="form-Label"  > *CPF ou CNPJ </Label>
                                                    <Input className="form-control" type="text" name="documento" value={validation.values.documento} onChange={handleChange} />
                                                    {validation.touched.documento && validation.errors.documento && (
                                                        <div style={{ color: 'red' }}>{validation.errors.documento}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div>
                                                <div className="mb-2">
                                                    <Label htmlFor="example-text-input" className="form-Label"  >
                                                        *Celular Principal
                                                    </Label>
                                                    <InputMask mask={'(99) 99999-9999'} name="celular" value={validation.values.celular} onChange={validation.handleChange} onBlur={validation.handleBlur} className="form-control" type="tel" />
                                                    {validation.touched.celular && validation.errors.celular && (
                                                        <div style={{ color: 'red' }}>{validation.errors.celular}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div>
                                                <div className="mb-2">
                                                    <Label htmlFor="example-text-input" className="form-Label" >
                                                        *E-mail
                                                    </Label>
                                                    <Input name="email" autocomplete="new-password" value={validation.values.email} onChange={validation.handleChange} className="form-control" type="email" style={{ textTransform: 'none' }} />
                                                    {validation.touched.email && validation.errors.email && (
                                                        <div style={{ color: 'red' }}>{validation.errors.email}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Col lg={12} style={{ marginLeft: 15, marginTop: 15 }} >
                                        <button className="btn btn-primary" type="submit" disabled={loading} style={{ float: "right" }} tabIndex={20}  >
                                            Avançar
                                        </button>
                                    </Col>


                                    <Row>
                                        <div className="" style={{ width: '100%', textAlign: 'center' }}>
                                            <div className="mb-0" style={{ textAlign: 'center' }}>
                                                <input type="checkbox" name='termosUsoPoliticas' value={validation.values.termosUsoPoliticas} onChange={validation.handleChange} style={{ marginRight: 10 }} />
                                                Confirmo que li e aceito os  <a className="text" style={{ marginLeft: 2, marginRight: 2, color: '#2278f3', textDecoration: 'underline' }}
                                                    onMouseOver={(e) => { e.target.style.color = 'black'; e.target.style.textDecoration = 'none' }}
                                                    onMouseOut={(e) => { e.target.style.color = '#2278f3'; e.target.style.textDecoration = 'underline' }} href="/termodeuso"
                                                    target="_blank"  >
                                                    Termos de Uso
                                                </a> e a  <a className="text" onMouseOver={(e) => { e.target.style.color = 'black'; e.target.style.textDecoration = 'none' }}
                                                    onMouseOut={(e) => { e.target.style.color = '#2278f3'; e.target.style.textDecoration = 'underline' }} style={{ marginLeft: 2, marginRight: 2, color: '#2278f3', textDecoration: 'underline' }} href="/politicadeprivacidade" target="_blank" >
                                                    Política de Privacidade
                                                </a> do Portal.
                                            </div>
                                        </div>


                                        <div className=" text-center" style={{ width: '100%', textAlign: 'center' }}>
                                            <p className=" mb-0">
                                                Já possui cadastro?{" "}
                                                <a className="text fw-semibold" style={{ color: 'red' }} onClick={() => {
                                                    setModalCadastro(false)
                                                    setModalLogin(true)
                                                }}>
                                                    {" "}
                                                    Clique Aqui.{" "}
                                                </a>{" "}
                                            </p>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};
