import React, { useEffect, useState } from 'react'
import { Toaster } from "react-hot-toast";
import { UncontrolledAlert } from "reactstrap";
export const AlertResposta = () => {

    return (
        <Toaster
            position="top-right"
            toastOptions={{
                style: {
                    backgroundColor: '#fff',
                    color: "#28A745", fontWeight: "bold", borderRadius: "8px", padding: "25px"
                },
                duration: 3000
            }}
        />
    )
}