import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api/apiTabelaPrecos";
import Visualizar from "./utils/visualizar";

export default function ServiceOne() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [nomeProduto, setNomeProduto] = useState('')
    const [estado, setEstado] = useState([])
    const [info, setInfo] = useState([])
    const [faixas, setFaixas] = useState([])
    const [response, seResponse] = useState()
    const gerarTabela = (dados) => {

        dados.dados.sort((a, b) => Number(a?.tabelafaixas?.find((item) => item.idfaixa == 18)?.valor?.replace(',', '.')) - Number(b?.tabelafaixas?.find((item) => item.idfaixa == 18)?.valor?.replace(',', '.')))
        let array = { id: dados.info && dados.info[0]?.idtabelaprecos ? dados.info[0]?.idtabelaprecos : 0, estado: dados.info && dados?.info[0]?.tabelaprecos[0]?.estado.nome ? dados?.info[0]?.tabelaprecos[0]?.estado.nome : estado.nome, nome: [], cobertura: [], acomodacao: [], coparticipacao: [], observacoes: [], coparticipacoes: [], areacomercializacao: [], imagens: [], dependente: [], documento: [], pagamento: [], rede: [], reembolso: [], carencia: [], faixa: [], precovida: [], entidades: [] }

        dados.dados.filter((item) => (item.tabelafaixas?.length > 0 || item.precovida)).map((item) => {


            if (item.imagem) {
                if (item.imagem && !array.imagens.some((arr) => arr.imagem == item.imagem)) {
                    array.imagens.push({ imagem: item.imagem, produto: [item.nome] })
                } else {
                    array.imagens.some((arr) => arr.imagem == item.imagem ? arr.produto.push(item.imagem) : '')
                }
            }

            if (item.nome) {
                array.nome.push(item.nome)
            }
            if (item.cobertura) {
                array.cobertura.push(item.cobertura)
            }
            if (item.acomodacao) {
                array.acomodacao.push(item.acomodacao)
            }
            if (item.coparticipacao) {
                array.coparticipacao.push({ nome: item.coparticipacao, status: item.coparticipacao == "Não" ? false : true, percentual: item.percentual })
            }


            if (item.observaco?.id) {
                if (item.observaco?.id && !array.observacoes.some((arr) => arr.id == item.observaco?.id)) {
                    array.observacoes.push({ id: item.observaco.id, item: item.observaco, produto: [item.nome] })
                } else {
                    array.observacoes.some((arr) => arr.id == item.observaco?.id ? arr.produto.push(item.nome) : '')
                }
            }

            if (item.areacomercializacao?.id) {
                if (item.areacomercializacao?.id && !array.areacomercializacao.some((arr) => arr.id == item.areacomercializacao?.id)) {
                    array.areacomercializacao.push({ id: item.areacomercializacao.id, descricoes: item.areacomercializacao?.areacomercializacaodescricaos, descricao: item.areacomercializacao.descricao, produto: [item.nome], municipios: item.areacomercializacao.areacomercializacaoestadosmunicipios })
                } else {
                    array.areacomercializacao.some((arr) => arr.id == item.areacomercializacao?.id ? arr.produto.push(item.nome) : '')
                }
            }
            if (item.dependente?.id) {
                if (item.dependente?.id && !array.dependente.some((arr) => arr.id == item.dependente?.id)) {
                    array.dependente.push({ id: item.dependente.id, item: item.dependente, produto: [item.nome] })
                } else {
                    array.dependente.some((arr) => arr.id == item.dependente?.id ? arr.produto.push(item.nome) : '')
                }
            }
            if (item.coparticipaco?.id) {
                if (item.coparticipaco?.id && !array.coparticipacoes.some((arr) => arr.id == item.coparticipaco?.id)) {
                    array.coparticipacoes.push({ id: item.coparticipaco.id, item: item.coparticipaco, produto: [item.nome] })
                } else {
                    array.coparticipacoes.some((arr) => arr.id == item.coparticipaco?.id ? arr.produto.push(item.nome) : '')
                }
            }
            if (item.documento?.id) {
                if (item.documento?.id && !array.documento.some((arr) => arr.id == item.documento?.id)) {
                    array.documento.push({ id: item.documento.id, item: item.documento, produto: [item.nome] })
                } else {
                    array.documento.some((arr) => arr.id == item.documento?.id ? arr.produto.push(item.nome) : '')
                }
            }
            if (item.pagamento?.id) {
                if (item.pagamento?.id && !array.pagamento.some((arr) => arr.id == item.pagamento?.id)) {
                    array.pagamento.push({ id: item.pagamento.id, item: item.pagamento, produto: [item.nome] })
                } else {
                    array.pagamento.some((arr) => arr.id == item.pagamento?.id ? arr.produto.push(item.nome) : '')
                }
            }
            if (item.carencia?.id) {
                if (item.carencia?.id && !array.carencia.some((arr) => arr.id == item.carencia?.id)) {
                    array.carencia.push({ id: item.carencia.id, item: item.carencia, produto: [item.nome] })
                } else {
                    array.carencia.some((arr) => arr.id == item.carencia?.id ? arr.produto.push(item.nome) : '')
                }
            }
            if (item.rede?.id) {
                if (!array.rede.some((arr) => arr.id == item.rede?.id)) {
                    array.rede.push({ id: item?.rede.id, item: item?.rede, produto: [item.nome] })
                } else {
                    array.rede.some((arr) => arr.id == item?.rede?.id ? arr.produto.push(item.nome) : '')
                }
            }
            if (item.reembolso?.id) {
                if (item.reembolso?.id && !array.reembolso.some((arr) => arr.id == item.reembolso?.id)) {
                    array.reembolso.push({ id: item.reembolso.id, item: item.reembolso, produto: [item.nome] })
                } else {
                    array.reembolso.some((arr) => arr.id == item.reembolso?.id ? arr.produto.push(item.nome) : '')

                }
            }
            if (
                item.grupoentidades?.id &&
                !array.entidades.some((arr) => arr.id == item.grupoentidades?.id)
            ) {
                array.entidades.push({
                    id: item.grupoentidades.id,
                    item: item.grupoentidades
                    ,
                    produto: [item.nome]
                });
            } else {
                array.entidades.some((arr) =>
                    arr.id == item.grupoentidades?.id ? arr.produto.push(item.nome) : ""
                );
            }


            // return

            let faixas = []


            if (item.tabelafaixas?.length > 0) {

                item?.tabelafaixas.map((item3) => {



                    if (item3.idfaixa == 1) {
                        faixas.push({
                            id: item3.id, status: item3.status, nome: 'Valor', valor: [{
                                valor: item3.valor.replace(',', '.'),
                                status: item3.status
                            }]
                        })

                    } else {

                        if (!faixas.some((item2) => item2.nome == item3.faixa.nome)) {

                            let id = item3.faixa.nome?.split('-')
                            faixas.push({
                                id: parseInt(id[0]), status: item.status, nome: item3.faixa.nome, valor: [{
                                    valor:
                                        (parseFloat((item3?.valor ? item3?.valor?.replace(',', '.') : 0)) * ((item3?.coeficiente && item3.coeficiente != '0' && item3?.statuscoeficiente) ? (parseFloat(item3?.coeficiente) / 100) : 1))
                                        + parseFloat(item3.odonto ? item3.odonto.replace(',', '.') : 0)
                                    ,
                                    status: item3.status
                                }]
                            })

                        } else {
                            let index = faixas.findIndex((item2) => item2.nome.includes(item3.faixa.nome))
                            faixas[index].valor.push({ valor: (parseFloat((item?.valor?.replace(',', '.'))) * ((item3?.coeficiente && item3.coeficiente != '0' && item3?.statuscoeficiente) ? (parseFloat(item3?.coeficiente) / 100) : 1)) + parseFloat(item3.odonto ? item3.odonto.replace(',', '.') : 0), status: item3.status })
                            // faixas[index].status = item.status
                        }

                    }



                })

                array.faixa.push(faixas)

            } else {

                if (item.precovida) {
                    if (!faixas.some((item2) => item2.nome == 'Valor')) {

                        faixas.push({
                            id: 'Valor', status: item.status, nome: 'Valor', valor: [{
                                valor:
                                    (parseFloat((item?.precovida ? item?.precovida?.replace(',', '.') : 0))), status: true
                            }]
                        })

                    } else {
                        let index = faixas.findIndex((item2) => item2.nome.includes('Valor'))
                        faixas[index].valor.push({ valor: (parseFloat((item?.precovida?.replace(',', '.')))), status: true })
                        // faixas[index].status = item.status
                    }
                }

                array.faixa.push(faixas)
            }
        })


        // let faixas = []

        // for (let index = 0; index < dados.dados.length; index++) {

        //     if (dados.dados[index]?.tabelafaixas) {
        //         dados.dados[index]?.tabelafaixas.map((item) => {


        //             if (!faixas.some((item2) => item2.nome == item.faixa.nome)) {

        //                 let id = item.faixa.nome.split('-')
        //                 faixas.push({id: parseInt(id[0]), status: item.status, nome: item.faixa.nome, valor:[(parseFloat((item?.valor ? item?.valor.replace(',', '.') : 0)) * (item?.coeficiente && item?.statuscoeficiente ? (parseFloat(item?.coeficiente) / 100) : 1)) + parseFloat(item.odonto ? item.odonto.replace(',', '.') : 0)]
        //                 })
        //             } else {
        //                 let index = faixas.findIndex((item2) => item2.nome.includes(item.faixa.nome))
        //                 faixas[index].valor.push((parseFloat((item?.valor ? item?.valor?.replace(',', '.') : 0)) * (item?.coeficiente && item?.statuscoeficiente ? (parseFloat(item?.coeficiente) / 100) : 1)) + parseFloat(item.odonto ? item.odonto.replace(',', '.') : 0))
        //             }

        //         })
        //     }

        // }
        setInfo(array)

        // faixas.sort((item, item2) => item.id - item2.id)
        setFaixas(faixas)
    }

    const handleDadosBuscar = async () => {
        setLoading(true)
        let dados = await Api.visualizarBuscar({ id: id })
        let nome = `${dados?.info[0]?.tabelaprecos[0]?.operadora?.nome} ${dados?.info[0]?.tabelaprecos[0]?.numerovidas ? '- ' + dados?.info[0]?.tabelaprecos[0]?.numerovidas?.replace('-', ' A ') + ' Vidas' : ''}`
        setNomeProduto(nome)
        seResponse(dados)
        gerarTabela(dados)
        setLoading(false)
    }
    useEffect(() => {
        handleDadosBuscar()
    }, [id])
    return (
        <Visualizar faixas={faixas} info={info} nomeProduto={nomeProduto} response={response} id={id} loading={loading} setLoading={setLoading} />
    );
}
