import React, { useEffect, useState } from "react";
import { Modal, Row } from "reactstrap";
import { getCookie, useCookieContext } from "../../components/cookies";
import odonto from "../../assets/images/icones-venda/iconeOdonto.png";
import lista from "../../assets/images/icones-venda/lista.png";
import redes from "../../assets/images/icones-venda/redes.png";
import saude from "../../assets/images/icones-venda/saude.png";
import carrinho from "../../assets/images/icones/carrinho.png";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import pet from "../../assets/images/icones/pet.png";
import uti_movel from "../../assets/images/icones/uti_movel.png";
import { Login } from "../Auth/Login";
import { Api } from "../../api/apiSite";
import { GestorModal } from "../components/modal";
import sino from "../../assets/images/icones/sino.png";
import treinamentos from "../../assets/images/icones/treinamentos.png";
import material_divulgacao from "../../assets/images/icones/material_divulgacoes.png";
import faca_cotacoes from "../../assets/images/icones/faca_cotacoes.png";
import { ExitModal } from "../components/exitModal";
import buscar from "../../assets/images/icones/busca.png";
import produtos_ramos_atuacao from "../../assets/images/icones/produtos_ramos_atuacao.png";
import empresasParceias from "../../assets/images/icones/empresas_parceiras.png";
import auto_moto from "../../assets/images/icones/auto_moto.png";
import residencial from "../../assets/images/icones/residencial.png";
import condominio from "../../assets/images/icones/condominio.png";
import seguro from "../../assets/images/icones/seguro.png";
import card from "../../assets/images/icones/card.png";
import BacktoTopCom from "../../components/helpers/BackToTopCom";
import { baseUrlApi } from "../../api/baseUrl";
import { useNavigate } from "react-router-dom";
export const AreaItem = ({ title, subTitle, titleModal, icon, href, showPopover, togglePopover, togglePopoverOut, index, setModalLogin, id, iframe, setModalIframe, handleIframe, widthIMG, height, breve = false, html, marginBottomIMG }) => {
  const navigate = useNavigate();

  const handleValida = () => {

    if (href) {
      if (id) {
        if (iframe) {
          setModalIframe(true)
        } else {
          navigate(href)
        }
      } else {
        setModalLogin(true)
      }
    } else {
      setModalLogin(true)
    }
  }

  return (
    <div onClick={() => { handleIframe(index) }} className=" " style={{ position: 'relative', width: 190, marginBottom: 20, borderRadius: '20px', border: showPopover[index] ? '1px solid #ccc' : 'none', marginRight: 20 }}>
      {/* onMouseOver={() => { togglePopover(index) }} onMouseOut={() => { togglePopoverOut(index) }} */}
      {html}
      <div className=" wow fadeInUp delay-0-4s" style={{ textAlign: 'center', display: 'flex', justifyContent: 'flex-start', alignItems: "center", flexDirection: "column", height: height ? height : '100%' }}>
        <div className="icon">
          <a onClick={handleValida}>
            <img src={icon} style={{ width: widthIMG ? widthIMG : "100px", textAlign: 'center', ...(marginBottomIMG && { marginBottom: marginBottomIMG }) }} />
          </a>
        </div>
        <p style={{ fontWeight: 'none' }} >
          <a onClick={() => { href ? id ? iframe ? setModalIframe(true) : setModalLogin(true) : setModalLogin(true) : '' }} href={href ? id ? iframe ? null : href : null : '#'} style={{ fontSize: 14, fborderBottom: showPopover[index] ? '1px solid #ccc' : 'none', color: '#092140' }}  >
            {title.split('\n').map((linha, index) => (
              <p key={index} style={{ lineHeight: title.split('\n').length > 1 ? 0.9 : 1.5 }}>
                {linha}
                <br />
              </p>
            ))}
          </a>
        </p>
        <p style={{ marginTop: '-16px', fontWeight: 100, fontSize: 14 }} >{subTitle}</p>
        <p style={{ color: '#2278F3', marginTop: '-16px', fontWeight: 100, fontSize: 14 }} > {breve ? "Em breve" : <br />}</p>

        {showPopover[index] && <AreaModal title={titleModal} />}
      </div>
    </div>
  )

}
export const AreaModal = ({ title }) => {
  return (
    <>
      {title &&
        <>
          <div style={{ position: 'absolute', borderRadius: 6, top: '-100px', width: '100%', left: 0, backgroundColor: 'white', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)', color: '#092140', height: 100, verticalAlign: 'middle', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}  >
            <p style={{ color: '#092140', opacity: '1', fintSize: 12 }} dangerouslySetInnerHTML={{ __html: title }}></p>
          </div>
        </>
      }
    </>
  )
}
function ServiceSection() {

  const [modalIframe, setModalIframe] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);
  const [iframeArray, setIframeArray] = useState();
  const [iframeSelect, setIframeSelect] = useState();
  const [titleIframe, setTitleIframe] = useState('')
  const [showPopover, setShowPopover] = useState([false, false, false, false, false]);
  useEffect(() => {
    handleRamos()
  }, []);
  const togglePopover = (id) => {
    let Popover = [...showPopover]
    Popover[id] = true
    setShowPopover(Popover);
  };
  const togglePopoverOut = (id) => {
    let Popover = [...showPopover]
    Popover[id] = false
    setShowPopover(Popover);
  };
  useEffect(() => {
    handleDados()
    let dados = [
      { id: 6, nome: 'Seguros de Vida', url: 'https://natuseg.corretagemfacil-app.com.br/outros_ramos/vida/new?filial=48778' },
      { id: 7, nome: 'Seguro Viagem', url: 'https://natuseg.corretagemfacil-app.com.br/viagem/new?filial=48778' },
      { id: 8, nome: 'Seguro de Automóveis', url: 'https://natuseg.corretagemfacil-app.com.br/outros_ramos/transporte/new?filial=48778' },
      { id: 9, nome: 'Seguro e Assistência', url: 'https://natuseg.corretagemfacil-app.com.br/outros_ramos/garantia/new?filial=48778' },
      { id: 10, nome: 'Seguro Garantia', url: 'https://natuseg.corretagemfacil-app.com.br/viagem/new?filial=48778' },
      { id: 11, nome: 'Seguro RC Profissional', url: 'https://natuseg.corretagemfacil-app.com.br/outros_ramos/rc-geral/new?filial=48778' },
    ]
    setIframeArray(dados)
    handleModal()

  }, [])
  const handleIframe = (id) => {
    let buscar = iframeArray?.find((item) => item.id == id)
    setTitleIframe(buscar.nome)
    setIframeSelect(buscar.url)
  }
  const [dados, setDados] = useState([]);
  const handleModal = async () => {
    let list = await Api.gestorModal('Ferramentas');
    setDados(list.dados)
  };
  const [visu, setVisu] = useState([]);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (isDesktop) {
      setVisu([true, true, true, true, true])
    } else {
      setVisu([false, false, false, false, false])
    }

  }, [isDesktop])
  const handleVisu = (index) => {
    let visus = [...visu]
    visus[index] = !visus[index]
    setVisu(visus)
  }
  const [comunicados, setComunicados] = useState('')
  const [treinamento, setTreinamento] = useState('')
  const [obj, setObj] = useState()
  useEffect(() => {
    const cookie = getCookie('authUser')
    setObj(cookie)
  }, [])


  const handleDados = async () => {

    let response = await Api.blogs();
    let list = response?.list?.list
    let id = obj?.id
    if (id) {
      id = JSON.parse(id)
    }

    let listagem = list.filter((item) => (!item?.blogviews?.some((item2) => (item2?.iduser == id))))
    setComunicados(listagem?.length)
    let json = await Api.treinamentos();
    let lists = json?.list
    setTreinamento(lists?.length)
  };
  const [ramos, setRamos] = useState([])
  const handleRamos = async () => {
    let value = await Api.ramosVendaOline()
    let result = value.list?.filter((item) => item.tiposoperadoras?.some((item) => item.operadora.linkvenda) ? true : item.operadoraslinks?.some((item) => item.link))
    result = result?.map((item) => { return { id: item.id, nome: item.nome, icone: item.icone, tipo: item.tiposoperadoras?.some((item) => item.operadora.linkvenda) ? 'Odonto-Saude' : item.operadoraslinks.some((item) => item.link) ? 'Outros' : '' } })
    let uniqueArray = result?.reduce((acc, current) => {
      const exists = acc.find(item => item.id === current.id);
      if (!exists) {
        acc.push(current);
      }
      return acc;
    }, []);
    uniqueArray?.sort((a, b) => a.ordem - b.ordem)
    setRamos(uniqueArray)
  }
  return (
    <div className="pt-30  pb-70 ">

      <div className="container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div className="section-title-with-btn " style={{ borderBottom: '1px solid #ccc', borderBottom: '1px solid transparent', borderImage: 'linear-gradient(to right, white, lightgray, gray, black, gray, lightgray, white)', borderImageSlice: 1, marginBottom: 20 }}>
          <div className="section-title" style={{ width: '100%', textAlign: 'center' }} >
            <h2 style={{ fontSize: 20, fontWeight: 100, cursor: 'pointer' }} onClick={() => { handleVisu(0) }}>Faça suas Cotações
              {visu[0] ?
                <FaAngleUp size={15} style={{ marginLeft: 10 }} />
                :
                <FaAngleDown size={15} style={{ marginLeft: 10 }} />
              }
            </h2>
          </div>
        </div>
        {visu[0] &&
          <div className="row" style={{ fontWeight: 100, marginTop: '-80px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>

            <AreaItem title={`Planos de Saúde`} subTitle={`Individual, Adesão e PME`} titleModal={``} icon={saude} href={"/cotacoes"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={0} id={obj?.id} handleIframe={() => { }} />

            <AreaItem title={`Planos Odontológicos`} subTitle={`Individual, Adesão e PME`} titleModal={``} icon={odonto} href={"/cotacoes"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={1} id={obj?.id} handleIframe={() => { }} />

            <AreaItem title={`Tabelas de Preços`} subTitle={`Personalizadas`} titleModal={``} icon={lista} href={"/tabela"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={2} id={obj?.id} handleIframe={() => { }} />

            <AreaItem title={`Comparador de Rede`} subTitle={`Planos de Saúde`} titleModal={``} icon={redes} href={""} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} setModalIframe={() => { }} index={3} id={obj?.id} handleIframe={() => { }} breve={true} />

            <AreaItem title={`Solicitar Cotação`} subTitle={`Plano Empresarial`} titleModal={``} icon={faca_cotacoes} href={""} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} setModalIframe={() => { }} index={3} id={obj?.id} handleIframe={() => { }} breve={true} widthIMG={50} />


            <AreaItem title={`Seguro Auto `} subTitle={`e Moto`} widthIMG={50} breve={true} titleModal={``} icon={auto_moto} href={""} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={0} id={obj?.id} handleIframe={() => { }} />


            <AreaItem title={`Seguro`} subTitle={`Residencial`} widthIMG={50} breve={true} titleModal={``} icon={residencial} href={""} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={0} id={obj?.id} handleIframe={() => { }} />


            <AreaItem title={`Seguro`} subTitle={`Condomínio`} widthIMG={50} breve={true} titleModal={``} icon={condominio} href={""} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={0} id={obj?.id} handleIframe={() => { }} />

            <AreaItem title={`Consórcios`} subTitle={`(Veículos e imóveis)`} widthIMG={50} breve={true} titleModal={``} icon={seguro} href={""} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={0} id={obj?.id} handleIframe={() => { }} />
          </div>
        }
        <div className="section-title-with-btn" style={{ borderBottom: '1px solid #ccc', borderBottom: '1px solid transparent', borderImage: 'linear-gradient(to right, white, lightgray, gray, black, gray, lightgray, white)', borderImageSlice: 1, marginBottom: 20, marginTop: 20 }}>
          <div className="section-title" style={{ width: '100%', textAlign: 'center' }} >
            <h2 style={{ fontSize: 20, fontWeight: 100, cursor: 'pointer' }} onClick={() => { handleVisu(2) }}>Tabelas e Materias de Vendas
              {visu[2] ?
                <FaAngleUp size={15} style={{ marginLeft: 10 }} />
                :
                <FaAngleDown size={15} style={{ marginLeft: 10 }} />
              }
            </h2>
          </div>
        </div>
        {visu[2] &&
          <div className="row" style={{ fontWeight: 100, marginTop: '-80px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: 10 }}>

            <AreaItem title={`Planos de Saúde`} subTitle={`Individual, Adesão e PME`} titleModal={``} icon={saude} href={"/plano/saude"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={12} id={obj?.id} handleIframe={() => { }} />

            <AreaItem title={`Planos Odontológicos`} subTitle={`Individual, Adesão e PME`} titleModal={``} icon={odonto} href={"/plano/odontologico"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={13} id={obj?.id} handleIframe={() => { }} />

            <AreaItem title={`Planos Pet`} subTitle={`(cães e gatos)`} titleModal={``} icon={pet} widthIMG={54} href={"/plano/pet"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={14} id={obj?.id} handleIframe={() => { }} />

            <AreaItem title={`UTI Móvel`} subTitle={`Emergências 24h`} titleModal={``} icon={uti_movel} widthIMG={54} href={"/plano/uti"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={15} id={obj?.id} handleIframe={() => { }} />

            <AreaItem title={`Cartões`} subTitle={`de Benefícios`} titleModal={``} icon={card} widthIMG={54} href={"/plano/card"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={15} id={obj?.id} handleIframe={() => { }} />

          </div>
        }

        <div className="section-title-with-btn" style={{ borderBottom: '1px solid #ccc', borderBottom: '1px solid transparent', borderImage: 'linear-gradient(to right, white, lightgray, gray, black, gray, lightgray, white)', borderImageSlice: 1, marginBottom: 20, marginTop: 20 }}>
          <div className="section-title" style={{ width: '100%', textAlign: 'center' }} >
            <h2 style={{ fontSize: 20, fontWeight: 100, cursor: 'pointer' }} onClick={() => { handleVisu(1) }}>Feche suas Vendas Online
              {visu[1] ?
                <FaAngleUp size={15} style={{ marginLeft: 10 }} />
                :
                <FaAngleDown size={15} style={{ marginLeft: 10 }} />
              }
            </h2>
          </div>
        </div>
        {visu[1] &&
          <div className="row" style={{ fontWeight: 100, marginTop: '-80px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: 10 }}>

            {ramos?.map((item) => (
              <AreaItem key={'AreaItem-' + item.id} title={item.nome} subTitle={``} titleModal={``} icon={`${baseUrlApi}/imagens/` + item.icone} href={"/vendaOnline/" + item.id} showPopover={showPopover} togglePopoverOut={togglePopoverOut} widthIMG={54} marginBottomIMG={7} togglePopover={togglePopover} index={item.id} id={obj?.id} handleIframe={() => { }} />
            ))}






          </div>
        }


        <div className="section-title-with-btn" style={{ borderBottom: '1px solid #ccc', borderBottom: '1px solid transparent', borderImage: 'linear-gradient(to right, white, lightgray, gray, black, gray, lightgray, white)', borderImageSlice: 1, marginBottom: 20, marginTop: 20 }}>
          <div className="section-title" style={{ width: '100%', textAlign: 'center' }} >
            <h2 style={{ fontSize: 20, fontWeight: 100, cursor: 'pointer' }} onClick={() => { handleVisu(3) }}>Marketing e Comunicação
              {visu[3] ?
                <FaAngleUp size={15} style={{ marginLeft: 10 }} />
                :
                <FaAngleDown size={15} style={{ marginLeft: 10 }} />
              }
            </h2>
          </div>
        </div>
        {visu[3] &&
          <div className="row" style={{ fontWeight: 100, marginTop: '-80px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: 10 }}>
            <AreaItem title={`Materiais`} subTitle={`de Divulgação`} titleModal={``} icon={material_divulgacao} widthIMG={54} href={"/materiais"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={18} id={obj?.id} handleIframe={() => { }} />
            <AreaItem title={`Avisos e`} subTitle={`Comunicados`} titleModal={``} icon={sino} widthIMG={54} href={"/comunicados"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={16} id={obj?.id} handleIframe={() => { }} html={comunicados ?
              <div style={{ position: 'absolute', left: 50, top: '-2px', borderRadius: '50%', color: 'red', width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 900, backgroundColor: 'red', color: '#fff' }} >{comunicados}</div>
              : ""} />

            <AreaItem title={`Agenda`} subTitle={`de Treinamentos`} titleModal={``} icon={treinamentos} widthIMG={54} href={"/treinamentos"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={17} id={obj?.id} handleIframe={() => { }} html={treinamento ?
              <div style={{ position: 'absolute', left: 50, top: '-2px', borderRadius: '50%', color: 'red', width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 900, backgroundColor: 'red', color: '#fff' }} >{treinamento}</div>
              : ""} />
            <AreaItem title={`Últimas`} subTitle={`Atualizações`} titleModal={``} icon={treinamentos} widthIMG={54} href={""} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={17} id={obj?.id} handleIframe={() => { }} breve={true} />
            <AreaItem title={`Catálago`} subTitle={`de Produtos`} titleModal={``} icon={treinamentos} widthIMG={54} href={""} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={17} id={obj?.id} handleIframe={() => { }} breve={true} />


            <AreaItem title={`Minha`} subTitle={`Loja Online`} titleModal={``} icon={carrinho} widthIMG={54} href={""} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={17} id={obj?.id} handleIframe={() => { }} breve={true} />
          </div>
        }


        <div className="section-title-with-btn" style={{ borderBottom: '1px solid #ccc', borderBottom: '1px solid transparent', borderImage: 'linear-gradient(to right, white, lightgray, gray, black, gray, lightgray, white)', borderImageSlice: 1, marginBottom: 20, marginTop: 20 }}>
          <div className="section-title" style={{ width: '100%', textAlign: 'center' }} >
            <h2 style={{ fontSize: 20, fontWeight: 100, cursor: 'pointer' }} onClick={() => { handleVisu(4) }}>Produtos e Empresas
              {visu[4] ?
                <FaAngleUp size={15} style={{ marginLeft: 10 }} />
                :
                <FaAngleDown size={15} style={{ marginLeft: 10 }} />
              }
            </h2>
          </div>
        </div>
        {visu[4] &&
          <div className="row" style={{ fontWeight: 100, marginTop: '-80px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: 10 }}>
            <AreaItem title={`Pesquisar`} subTitle={`Produtos`} titleModal={``} icon={buscar} widthIMG={54} href={"/produtos"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={18} id={obj?.id} handleIframe={() => { }} />

            <AreaItem title={`Ramos`} subTitle={`de Atuação`} titleModal={``} icon={produtos_ramos_atuacao} widthIMG={54} href={"/ramos"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={16} id={obj?.id} handleIframe={() => { }} />

            <AreaItem title={`Empresas`} subTitle={`Parceiras`} titleModal={``} icon={empresasParceias} widthIMG={54} href={"/empresas"} showPopover={showPopover} togglePopoverOut={togglePopoverOut} togglePopover={togglePopover} index={17} id={obj?.id} handleIframe={() => { }} />

          </div>
        }

      </div>
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />

      <Modal isOpen={modalIframe} size='lg' centered={false}>
        <div className="modal-header">
          <p>{titleIframe}</p>

          <ExitModal setModal={setModalIframe} />
        </div>
        <div className="modal-body">
          <Row>
            <iframe id="test" width="100%" height={'450px'} src={iframeSelect}></iframe>
          </Row>
        </div>
      </Modal>
      <GestorModal dados={dados} />

      <BacktoTopCom />
    </div>
  );
}
export default ServiceSection;