import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Header } from "./header";
import { Footer } from "./footer";
import { Filter } from "./filter";
import { baseUrlApi } from "../../api/baseUrl";
import html2canvas from "html2canvas";

import { jsPDF } from "jspdf";
export const Produtos = ({ info, modalVisualizarDetalhe, enviarEmail, calculoDetalhe, selecteds, handleClick, loadingDetalhes, setInfo, id, cotacao, Compartilha, setVisualizar, handleClickOperadoraDeselectAll, handleClickOperadoraSelectAll, exibirTodos, calculoFiltro, handleClickAll, generatePDF }) => {
  const divRef = useRef(null);
  const [display, setDisplay] = useState('block')
  const [viewCorretor, setViewCorretor] = useState(true)


  useEffect(() => {
    setDisplay('block')
  }, [modalVisualizarDetalhe])


  const [municipios, setMunicipios] = useState([])
  const [modalMunicipios, setModalMunicipios] = useState(false)
  const ref = useRef();
  useEffect(() => {
    setDisplay('block')
  }, [modalVisualizarDetalhe])

  const handleVisualizar = (index, status) => {
    let dados = [...info]
    dados[index].visualizar = status
    setInfo(dados)
  }
  const [viewTotal, setViewTotal] = useState(true)
  const handleViewTotal = () => {
    info.map((operadora) => {
      operadora.visualizar = !viewTotal
    })
    setViewTotal(!viewTotal)
  }
  const contentRef = useRef(null);
  const [isGenerating, setIsGenerating] = useState(false);


  return (
    <div  >
      <div className="" style={{ width: '100%', overflowX: 'auto' }} >
        `
        {
          <>
            <Filter Compartilha={Compartilha} cotacao={true} divRef={contentRef} handleViewTotal={handleViewTotal} setDisplay={setDisplay} viewTotal={viewTotal} gerarPDF={generatePDF} setViewCorretor={setViewCorretor} viewCorretor={viewCorretor} enviarEmail={enviarEmail} />
            <div className="modal-body" id='minha-div' ref={contentRef} >
              <table className="table" style={{ marginTop: 0 }}  >

                <tbody id='tabela' style={{ display: "flex", paddingRight: 30, paddingLeft: 30, flexDirection: "column", marginTop: 0 }}>
                  <Header calculoDetalhe={calculoDetalhe} index={0} cotacao={cotacao} viewTotal={viewTotal} handleViewTotal={handleViewTotal} setDisplay={setDisplay} divRef={divRef} viewCorretor={viewCorretor} />
                  {exibirTodos &&
                    <div style={{ display: 'flex', justifyContent: "flex-start", alignItems: "center", marginLeft: 0 }}>
                      <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'row', paddingLeft: 60 }}>
                        <input type="checkbox" defaultChecked={true} className="form-control" onChange={(e) => {
                          if (e.target.checked) {
                            handleClickAll(true)
                          } else {

                            handleClickAll(false)
                          }
                        }} />
                        <label style={{ marginBottom: 10, textAlign: 'center', marginLeft: 5 }} >Exibir Todas</label>
                      </div>
                    </div>
                  }

                  {info?.length > 0 && info.filter((fill) => selecteds.length > 0 ? selecteds.some((select) => fill.tabelas.produto.some((som) => som.id == select.idtabela)) : true)?.map((operadora, index) => (
                    <>
                      <div style={index == 0 ? { breakInside: '' } : { pageBreakAfter: 'always' }}>
                        <div>
                          {selecteds.some((select) => operadora?.tabelas.produto.some((som) => som.id == select.idtabela)) &&
                            (
                              <div onClick={() => { handleVisualizar(index, !operadora.visualizar) }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginBottom: operadora.visualizar ? 0 : 10, boxShadow: '0 0 0 0.5px #ccc', borderRadius: 6, ...(index != 0 && { pageBreakBefore: 'always' }) }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                  <img src={`${baseUrlApi}/${operadora?.imagem?.includes('image') ? operadora?.imagem : `midias/${operadora?.imagem}`}`} width='200px' style={{ margin: 'auto', marginRight: 10 }} />
                                </div>
                                <div style={{ position: "absolute", right: '40px', display }} >
                                  {operadora.visualizar ?
                                    <IoIosArrowDown color="#092140" onClick={() => { handleVisualizar(index, true) }} />
                                    :
                                    <IoIosArrowForward color="#092140" onClick={() => { handleVisualizar(index, false) }} />
                                  }
                                </div>
                              </div>
                            )
                          }
                          {(display == 'none' ? true : operadora.visualizar) && operadora.tabelas.produto.map((item, indexProduto) => (
                            <>
                              {(selecteds.some((select) => select.idtabela == item?.id)) &&
                                (<tr key={'tr-' + item.id} className="tr" id={item?.faixas.map((item3) => { return item3.id }).join('&')} style={{ flexDirection: 'column', marginBottom: 10, display: display == 'none' ? selecteds.find((select) => item.id == select.idtabela)?.visualizar ? 'flex' : 'none' : 'flex' }} >

                                  <tr style={{ textAlign: 'center' }}  >
                                    <button style={{ position: 'absolute', left: 30, color: '#D75350', display: display }} className='btn btn-default'  >
                                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flex: 1, paddingTop: '20px', paddingLeft: 50 }} >
                                        <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'row' }}>
                                          <input type="checkbox" checked={selecteds.find((select) => item.id == select.idtabela)?.visualizar} className="form-control" onChange={(e) => {
                                            let find = [...selecteds]
                                            let indexPrd = selecteds.findIndex((select) => item.id == select.idtabela)
                                            if (e.target.checked) {
                                              find[indexPrd].visualizar = false
                                            } else {
                                              find[indexPrd].visualizar = true
                                            }
                                            handleClick(item.id, indexPrd)
                                          }}
                                          />
                                          <label style={{ marginBottom: 10, marginLeft: 5 }}>Exibir</label>
                                        </div>
                                      </div>
                                    </button>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 80, backgroundColor: 'rgb(204, 204, 204,0.5)', borderRadius: 6 }}>

                                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'center', width: '100%', marginBottom: 0, lineHeight: 1.2 }} >
                                          {operadora.operadora?.toUpperCase()} <br /> {item.nome}<br /> {item.cobertura ? `${item.cobertura}` : ''}  {item.vidas} {item.acomodacao ? `- ${item.acomodacao} - ` : ''} {(item.coparticipacao == 'Sim' || item.coparticipacao == "Total" || item.coparticipacao == "Parcial") ? `Com coparticipacão ${item.coparticipacao == "Total" || item.coparticipacao == "Parcial" ? `(${item.coparticipacao})` : ""} ${item.percentual ? 'de  ' + item.percentual + '%' : ''} ` : 'Sem coparticipacão'} {item?.contratacao?.trim() ? `- ${item.contratacao}` : ''} </p>
                                      </div>
                                    </div>
                                    <table className="" style={{ width: '100%' }}  >
                                      <tbody  >
                                        <tr style={{ padding: 1, height: 40, display: 'flex', justifyContent: 'space-around', border: 'none', height: 90 }}>
                                          {item?.faixas.sort((a, b) => a.idfaixa - b.idfaixa)?.map((item, index) => (
                                            <div key={item.id} style={{ textAlign: 'center', borderWidth: 1, padding: 5, border: 0, width: 80 }} >
                                              <p style={{ fontWeight: 'bold', fontSize: 15, height: 6, lineHeight: 1, height: 60, display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }} >
                                                <span>{item?.nomeFaixa ? item.nomeFaixa?.replace('-', ' à ') + "" : ''} <br />  {item?.qtdCotacao} vida(s)</span>
                                                <br />
                                                <span style={{ lineHeight: 0.8 }} >
                                                  <span > {item?.valorTotal?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </span>
                                                  <span style={{ fontSize: 11 }} > {item?.valor?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </span>
                                                  <span style={{ fontSize: 11 }} >p/vida </span>
                                                </span>
                                              </p>
                                            </div>
                                          ))}
                                          <div style={{ textAlign: 'center', borderWidth: 1, padding: 5, lineHeight: 1, width: 80 }} >
                                            <p style={{ fontWeight: 'bold', fontSize: 15, height: 8, height: 90, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }} >
                                              Total  <br />  {calculoDetalhe?.vidas > 0 ? calculoDetalhe.vidas : 1} vida(s)
                                              <span style={{ lineHeight: 0.8, marginBottom: 20 }} >
                                                <span > {item.faixas.reduce((sum, element) => sum + element?.valorTotal, 0)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </span>
                                                <span style={{ fontSize: 11 }} >Valor Total </span>
                                              </span>
                                            </p>
                                          </div>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </tr>
                                </tr>
                                )
                              }

                            </>
                          ))}
                        </div>
                        <Footer visualizar={operadora.visualizar} tabelas={operadora.tabelas} municipio={municipios} operadora={operadora.operadora} />
                      </div>
                    </>
                  ))}
                </tbody>
              </table>
            </div >
          </>}
      </div>
      <Modal isOpen={modalMunicipios} centered={true}>
        <div className="modal-header" style={{ border: 'none' }}>

          <button
            type="button"
            onClick={() => {
              setModalMunicipios(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
          <p style={{ color: '#092140', textAlign: 'center' }}>Municípios</p>

          <Row style={{ justifyContent: 'center', alignItems: 'center' }} >
            {municipios.map((item3, index) => (
              <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3.municipio.nome)} -` }}  ></span>
            ))}
          </Row>
        </div>

      </Modal>
    </div >
  )
}