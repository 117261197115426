import React from "react";
import Layouts from "../Home/Layouts";
import Dados from "./index";

export default function Treinamentos() {
  return (
    <Layouts
      pageTitle="Agenda de Treinamentos"
      breadcrumbs={[
        { name: "Inicio", path: "/" },
        { name: "Agenda de Treinamentos", path: "/treinamentos" }
      ]}
    >
      <Dados />
    </Layouts>
  );
}
