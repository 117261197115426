import React, { useEffect, useRef, useState } from "react";
import { HiOutlineX } from "react-icons/hi";

export const Exit = ({ modal ,setModal,top}) => {  
    return (

        <div style={{ top: 6, right: 6, zIndex: 99999999999999, position: "absolute", backgroundColor: `rgba(255,255,255,1)`, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%',width:25,height:25 }} >
            <button type="button" onClick={() => { setModal(false); }} className="close" data-dismiss="modal" aria-label="Close" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <HiOutlineX size={20} />
            </button>
        </div>

    )
}