import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
// import email from "../../assets/icones/email.png"
import html2canvas from 'html2canvas';

import { MdOutlineEmail as Email } from "react-icons/md";
import { IoLogoWhatsapp as Wpp } from "react-icons/io5";
import { FiPhone as Tel } from "react-icons/fi";
import ReactToPrint from "react-to-print";
import * as htmlToImage from 'html-to-image';
import { Modal } from "reactstrap";
import { LuPrinter } from "react-icons/lu";
import { RiDownloadCloudLine } from "react-icons/ri";
import { BsEye } from "react-icons/bs";
import { baseUrlApi } from "../../api/baseUrl";
import { Exit } from "../components/exit";
import { useCookies } from 'react-cookie';

export default function Divulgacao({ datas, id }) {
    const [cookie,setCookie] = useCookies(['authUser'])
    const obj =cookie.authUser
  

    const openPrintDialog = (index, id) => {
        const divToHide = document.querySelector(`div[id="${index}"]`);
        if (divToHide) {
            divToHide.style.display = "none";
        }

        const element = document.querySelector(`a[id="${id}"] #imgVisuContaner`);
        if (element) {
            htmlToImage.toPng(element)
                .then(function (dataUrl) {
                    const printWindow = window;
                    if (printWindow) {
                        printWindow.document.write(`
                        <html>
                        <head>
                            <title>Imagem para Impressão</title>
                            <style>
                                body {
                                    margin: 0;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 100vh;
                                }
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            </style>
                        </head>
                        <body>
                            <img src="${dataUrl}" alt="Imagem para Impressão" />
                            <script>
                                window.onload = function() {
                                    window.print();
                                    window.close();
                                
                                }
                            </script>
                        </body>
                        </html>
                    `);
                        printWindow.document.close(); // Fechamos o documento para garantir que o script seja executado corretamente
                        printWindow.onload = function () {
                            printWindow.print();
                            printWindow.close();
                            window.location.href = `${process.env.REACT_APP_SITEWEB}/materiais`
                        };
                    } else {
                        alert('Falha ao abrir a caixa de diálogo de impressão. Verifique se as janelas pop-up estão desbloqueadas.');
                    }
                    document.querySelector(`div[id="${index}"]`).style.display = "flex"
                })
                .catch(function (error) {
                    console.error('Erro ao baixar a imagem:', error);
                    document.querySelector(`div[id="${index}"]`).style.display = "flex"
                });


        }
    };




    const canvasRef = useRef(null);
    const imgRef = useRef(null);
    const divRef = useRef(null);
    const refs = useRef([]);


    const [items, setItems] = useState()
    const [modal, setModal] = useState()
    const [indexItem, setIndexItem] = useState()
    const handleClick = (item,index) => {
        setIndexItem(index)
        setItems(item)
        setModal(true)
    }
    const modalRef = useRef(null);

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const handleClickOutside = (event) => {
        const modalElement = document.getElementById('modalElementId');
        if (modalElement && !modalElement?.contains(event.target)) {
            setModal(false)
        }
    };
    useEffect(() => {
        if (modal) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            // Remove o evento de clique quando o modal é fechado
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modal]);
    const [format, setFormat] = useState('png');


    const downloadImage = async (index) => {
        const element = refs.current[index];
    
        try {
            // Aguarde o carregamento de todas as imagens
            await ensureImagesLoaded(element);
    
            // Defina a escala (aumenta a qualidade)
            const scale = 2; // Pode ajustar para 2, 3 ou mais conforme necessário
    
            // Capture a div como imagem
            const canvas = await html2canvas(element, {
                scale: scale, // Aumenta a resolução
                useCORS: true, // Para carregar imagens externas
            });
    
            // Crie o link de download
            const link = document.createElement('a');
            link.download = 'material.png';
            link.href = canvas.toDataURL('image/png', 1.0); // Qualidade máxima
            link.click();
        } catch (error) {
            console.error('Erro ao capturar a imagem:', error);
        }
    };
    const ensureImagesLoaded = (element) => {
        const images = element.querySelectorAll('img');
        return Promise.all(
            Array.from(images).map((img) => {
                return new Promise((resolve, reject) => {
                    if (img.complete) {
                        resolve();
                    } else {
                        img.onload = resolve;
                        img.onerror = reject;
                    }
                });
            })
        );
    };
    const formatarCelular = (celular) => {
        if (celular?.startsWith("55")) {
            return (
                "55" +
                celular
                    .substring(2)
                    .replace(/\D/g, "")
                    .replace(/^(\d{2})(\d)/g, "($1) $2")
                    .replace(/(\d)(\d{4})$/, "$1-$2")
            );
        } else {
            if (celular) {

                return celular
                    .replace(/\D/g, "")
                    .replace(/^(\d{2})(\d)/g, "($1) $2")
                    .replace(/(\d)(\d{4})$/, "$1-$2");
            } else {
                return "";
            }
        }
    };
    return (
        <section className="portfolio-section   pb-30 rpb-50">
            <div className="container" >
                <div className="row align-items-center  portfolio-wrap">
                    {datas &&
                        datas.length > 0 &&
                        datas.map((item, index) => (
                            <a key={item.id} id={item.id} className={`col-lg-3 col-sm-6 item `} style={{ textDecoration: 'none', marginTop: 50 }} >

                                <div className="style-three " style={{ cursor: "pointer" }}  >
                                    <div className="feature-content style-three" style={{ textAlign: "center", marginTop: "auto" }}>



                                        <Row id="imgVisuContaner">
                                            <Col lg={12}>
                                                <div id={index} style={{ cursor: "pointer", display: 'flex', position: "absolute", top: -30, backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center', width: '100%'}}>

                                                    <div>
                                                        <RiDownloadCloudLine size={18} onClick={() => { downloadImage(index, item.id) }} style={{ marginRight: '8px', color: '#5c5c5c' }} />
                                                    </div>
                                                    <div>
                                                        <LuPrinter size={18} onClick={() => { openPrintDialog(index, item.id) }} style={{ marginRight: '8px', color: '#5c5c5c' }} />
                                                    </div>
                                                    <div>
                                                        <BsEye size={18} onClick={() => { handleClick(item,index) }} style={{ marginRight: '8px', color: '#5c5c5c' }} />
                                                    </div>
                                                </div>
                                                {/* <CompartilharRedesSociais id={item.id}  titulo={'Teste'} /> */}
                                                <div ref={(el) => (refs.current[index] = el)} onClick={() => { handleClick(item,index) }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', cursor: 'pointer' }}>


                                                    <img id='imgVisu' ref={imgRef} src={`${baseUrlApi}/imagens/${item?.imagem}`} style={{ width: '100%', height: 280 }} />

                                                    <div ref={canvasRef} id='canvas'
                                                        style={{ width: isDesktop ? '90%' : '93%', backgroundColor: '#fff', border: '1px solid #ccc', textAlign: 'center', bottom: 0, height: '55px', fontSize: item.tipo == '2' ? '8px' : '10px', position: 'absolute', top: '228px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >

                                                        <div style={{ display: obj?.foto && 'flex', justifyItems: 'center', alignItems: 'center' }} >
                                                            <div style={{ display: obj?.foto && 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 10 }}>
                                                                {obj?.foto &&
                                                                    <div style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
                                                                        <img src={`${baseUrlApi}/imagens/${obj?.foto}`} width={100} style={{ margin: 'auto', justifyItems: 'center', alignItems: 'center' }} />

                                                                    </div>
                                                                }
                                                                <div style={{ flex: 2 }}>
                                                                    <p style={{ fontSize: 13, textAlign: 'left', marginBottom: 0, marginTop: '-1px', textAlign: !obj?.foto ? 'center' : 'left' }}> {`${obj?.nomefantasia ? ((obj?.nomefantasia.split(' ')[0] || "") + ' ' + (obj?.nomefantasia.split(' ')[1] || "")) : (obj?.nome ? (obj?.nome.split(' ')[0] || "") + ' ' + (obj?.nome.split(' ')[1] || "") : '')}`}</p>



                                                                    <p style={{ fontSize: 13, textAlign: 'left', marginBottom: 0, marginTop: '-10px', textAlign: !obj?.foto ? 'center' : 'left' }}>{obj?.celular && (obj?.whatsapp ? <Wpp size={12} /> : <Tel size={12} />)} {obj?.celular && formatarCelular(obj?.celular)}
                                                                    </p>

                                                                    <p style={{ fontSize: 9, textAlign: 'left', marginBottom: 0, marginTop: '-10px', textAlign: !obj?.foto ? 'center' : 'left' }}><Email size={12} /> {obj?.email}</p>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        {/* <Row style={{ textAlign: 'left', marginTop: 5 }} >
                                                            <Col lg={4} style={{ justifyContent: 'center', alignItems: 'center', marginTop: 3, marginLeft: 8 }} >
                                                                {obj?.foto ?
                                                                    <img src={`${baseUrlApi}/imagens/${obj?.foto}`} width={100} style={{ margin: 'auto' }} />
                                                                    :
                                                                    <img src={logo} width={100} style={{ margin: 'auto' }} />}
                                                            </Col>
                                                            <Col lg={8} style={{ marginLeft: -30 }} >
                                                                <Col sm lg={12} style={{ lineHeight: 2 }}>
                                                                    <span style={{ fontSize: 13, lineHeight: 0 }}> {`${obj?.nome.split(' ')[0]} ${obj?.nome.split(' ')[1] ? obj?.nome.split(' ')[1] : ''}`}</span>
                                                                </Col>
                                                                <Col lg={12} style={{ lineHeight: '1.5' }}>
                                                                    <span style={{ fontSize: 13, lineHeight: 0 }}>{obj?.whatsapp ? <Wpp size={12} /> : <Tel size={12} />} {obj?.celular}</span>
                                                                </Col>
                                                                <Col lg={12} style={{ lineHeight: '1.5' }}>
                                                                    <span style={{ fontSize: 10, lineHeight: 0 }}><Email size={12} /> {obj?.email}</span>
                                                                </Col>
                                                            </Col>

                                                        </Row> */}


                                                    </div>

                                                </div>

                                            </Col>
                                        </Row>




                                    </div>

                                </div>
                            </a>
                        ))}


                </div>
            </div>
            <Modal isOpen={modal} centered={false} id="modalElementId" >

                <div className="modal-body" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <Exit modal={modal} setModal={setModal} />

                    <div style={{ height: 350 }} >
                        <div id={0} style={{ cursor: "pointer", display: 'flex', top: 0, backgroundColor: '#fff', justifyContent: 'center', alignItems: 'center', width: '100%' ,height:40,paddingLeft:4}}>

                            <div>
                                <RiDownloadCloudLine size={18} onClick={() => { downloadImage(indexItem, items.id) }} style={{ marginRight: '8px', color: '#5c5c5c' }} />
                            </div>
                            <div>
                                <LuPrinter size={18} onClick={() => { openPrintDialog(indexItem, items.id) }} style={{ marginRight: '8px', color: '#5c5c5c' }} />
                            </div>
                        </div>

                        <img id='imgVisu' ref={imgRef} src={`${baseUrlApi}/imagens/${items?.imagem}`} style={{ width: '100%' }} />

                        <div ref={canvasRef} id='canvas'
                            style={{ width: '100%', height: 100, backgroundColor: '#fff', border: '1px solid #ccc', textAlign: 'center', position: 'relative', top: '-90px', bottom: 0, height: '90px', fontSize: items?.tipo == '2' ? '8px' : '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >

                            <div style={{ display: obj?.foto && 'flex', justifyItems: 'center', alignItems: 'center' }} >
                                <div style={{ display: obj?.foto && 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {obj?.foto &&
                                        <div style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
                                            <img src={`${baseUrlApi}/imagens/${obj?.foto}`} width={100} style={{ margin: 'auto', justifyItems: 'center', alignItems: 'center' }} />
                                        </div>
                                    }
                                    <div style={{ flex: 3 }}>
                                        <p style={{ fontSize: 20, textAlign: 'left', marginBottom: 5, marginTop: '-1px', textAlign: !obj?.foto ? 'center' : 'left' }}> {`${obj?.nomefantasia ? ((obj?.nomefantasia.split(' ')[0] || "") + ' ' + (obj?.nomefantasia.split(' ')[1] || "")) : (obj?.nome ? (obj?.nome.split(' ')[0] || "") + ' ' + (obj?.nome.split(' ')[1] || "") : '')}`}</p>
                                        <p style={{ fontSize: 20, textAlign: 'left', marginBottom: 5, marginTop: '-10px', textAlign: !obj?.foto ? 'center' : 'left' }}>{obj?.celular && (obj?.whatsapp ? <Wpp size={12} /> : <Tel size={12} />)} {obj?.celular && formatarCelular(obj?.celular)}</p>
                                        <p style={{ fontSize: 20, textAlign: 'left', marginBottom: 5, marginTop: '-10px', textAlign: !obj?.foto ? 'center' : 'left' }}><Email size={12} /> {obj?.email}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>

        </section>
    );
}
