import { createContext, useContext, useEffect, useState, useRef } from "react";
import { axiosInstance } from "../../instancias/instancia";
import { toast } from "react-hot-toast";
import Icone from "../../assets/icones/icone.png";
import { useLocation } from "react-router-dom";
import { AlertResposta } from "./alert";

const LoadingContext = createContext({ loading: false });

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const requestCountRef = useRef(0);
    const interceptorsAdded = useRef(false);
    const location = useLocation();
    const PathInit = ["plano"];
    const loadingNot = ["usuario","cotacoes"];
    const notificationResposta = ["usuario","tabela","cotacoes"];
    const url = location.pathname;

    // Atualiza o estado do loading
    const updateLoadingState = (isStarting) => {
        if (isStarting) {
            requestCountRef.current += 1;
        } else {
            requestCountRef.current = Math.max(requestCountRef.current - 1, 0);
        }
        setLoading(requestCountRef.current > 0);
    };

    useEffect(() => {
        if (interceptorsAdded.current) return;

        interceptorsAdded.current = true;

        const requestInterceptor = axiosInstance.interceptors.request.use((config) => {
            if (!loadingNot.some((item) => config.url.includes(item))) {
                updateLoadingState(true);
            }
            return config;
        });

        const responseInterceptor = axiosInstance.interceptors.response.use(
            (response) => {
                try {
                    if (notificationResposta.some((item) => response.config?.url?.includes(item))) {
                        if (response?.data?.message) {
                            const toastConfig = {
                                position: "top-right",
                                duration: 3000,
                                style: { backgroundColor: "#fff" },
                            };
                            response?.data?.error
                                ? toast.error(response.data.message, toastConfig)
                                : toast.success(response.data.message, toastConfig);
                        }
                    }
                } catch (err) {}
                updateLoadingState(false);
                return response;
            },
            (error) => {
                updateLoadingState(false);
                return Promise.reject(error);
            }
        );

        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
            interceptorsAdded.current = false;
        };
    }, []);

    useEffect(() => {
        setLoading(false);
        requestCountRef.current = 0;
    }, [url]);

    return (
        <LoadingContext.Provider value={{ loading }}>
            <AlertResposta />

            {((url === "/" || PathInit.some((item) => url.includes(item))) && loading) && (
                <div
                    className={`col-lg-12 col-sm-12 item`}
                    style={{
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        width: "100%",
                        zIndex: 99999999999999,
                        backgroundColor: `rgb(246, 247, 249,${url === "/" ? 1 : 0.4})`,
                    }}
                >
                    <img src={Icone} className="rotating-image" />
                </div>
            )}

            {children}
        </LoadingContext.Provider>
    );
};
