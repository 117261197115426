import React, { useEffect, useState } from "react";
import FooterHomeTwo from "../footer";
import HeaderHomeOne from "../Header";
import Hero from "./Hero";
import { useCookies } from "react-cookie";
import { Login } from "../../Auth/Login";
import { useNavigate } from 'react-router-dom';
import { Api as ApiAuth } from "../../../api/apiAuthentica";
export default function Layouts({ children, pageTitle, breadcrumbs = [] }) {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['authUser', 'authPagehist', 'authToken']);
  const [modalLogin, setModalLogin] = useState(false);

  const [msgSessao, setMsgSessao] = useState(false)
  const auth = async () => {
    let response = await ApiAuth.Auth()
    if (response?.status == 401) {
      setMsgSessao(true)
      setModalLogin(true)
    }
  }
  useEffect(() => {
    const currentPath = window.location.pathname;
    const isPrivateRoute = routesPrivate.includes(currentPath);
    if (isPrivateRoute) {
      auth();
    }
  }, [])

  useEffect(() => {
    if (!modalLogin && msgSessao) {
      navigate('/')
    }
  }, [modalLogin])

  const routesPrivate = ['/produtos', '/tabelacotacoes', '/ferramentas', '/cotacoes', '/tabela', '/vendaOnline', '/materiais']
  return (
    <>
      <div style={{ minHeight: '88vh' }}>
        <HeaderHomeOne />
        <Hero pageTitle={pageTitle} breadcrumbs={breadcrumbs} />
        {modalLogin ?
          <Login modalLogin={modalLogin} setModalLogin={setModalLogin} msgSessao={msgSessao} />
          :
          children && children
        }
      </div>
      <FooterHomeTwo />
    </>
  );
}
