import React from "react";
import { useParams } from "react-router-dom";
import Dados from "./index";
import Layouts from "../Home/Layouts";

export default function ServiceOne() {
  const { estado, plano,id } = useParams();
  return (
    <Layouts
      pageTitle={`Feche suas Vendas Online`}
      breadcrumbs={[
        { name: "Home", path: "../" },
        { name: "Feche sua Vendas Online", path: "/vendaOline" },
      ]}
    >
      <Dados plano={plano} estado={estado} id={id} />
    </Layouts>
  );
}
