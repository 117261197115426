import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api/apiSite";
import MainSection from "./utils/listagem";
import Icone from "../../assets/icones/icone.png"
function FeatureSection({id,setDetalhes}) {
  const [datas, setDatas] = useState([]);
 
  const handleOperadora = async () => {
    setDatas([]);
    let list = await Api.operadoraSeguro({estado:5});
    
    setDatas(list.list);

  };
  useEffect(() => {
    handleOperadora();
   
  }, []);

  return (
    <section className="featured-section bgs-cover " style={{minHeight:700}}>
      <div className="container" style={{maxWidth:'1500px'}}>
        {datas.length>0 && <MainSection setDetalhes={setDetalhes} id={id} />}
       
        <div className="feature-btn text-center mt-20"></div>
      </div>
    </section>
  );
}

export default FeatureSection;
