import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { ValidacaoEmail } from "../Validacao/email";
import { ValidacaoCelular } from "../Validacao/celular";
import { ExitModal } from "../../components/exitModal";
import { Etapa1 } from "./etapa1";
import { Etapa2 } from "./etapa2";
import { Etapa3 } from "./etapa3";
import { Etapa4 } from "./etapa4";

export const Cadastro = ({ setModalCadastro, modalCadastro, setModalLogin }) => {
  const [tipoPessoa, setTipoPessoa] = useState("");
  const [modalValidaEmail, setModalValidaEmail] = useState(false)
  const [modalValidaCelular, setModalValidaCelular] = useState(false)
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [proximo, setProximo] = useState(1);
  const [id, setid] = useState('')

  useEffect(() => {
    if (!modalCadastro) {
      setProximo(1)
    }
  }, [modalCadastro])
  return (
    <Modal id="modal-center3" size="lg" centered={true} isOpen={modalCadastro}>
      <div className="modal-header" style={{ border: 'none' }} >
        <ExitModal setModal={setModalCadastro} />
      </div>
      <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
        <Row className="g-0">
          <Col lg={12} md={12} className="col-xxl-12">
            <div className="auth-full-page-content d-flex p-sm-2 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="auth-content my-auto">

                    {proximo == 1 && (
                      <Etapa1 id={id} setProximo={setProximo} setid={setid} setTipoPessoa={setTipoPessoa} setEmail={setEmail} setCelular={setCelular} />
                    )}
                    {proximo == 2 &&
                      <Etapa2 id={id} setProximo={setProximo} setid={setid} tipoPessoa={tipoPessoa} />
                    }
                    {proximo == 3 && (
                      <Etapa3 id={id} setProximo={setProximo} setid={setid}
                        setModalValidaCelular={setModalValidaCelular} setModalValidaEmail={setModalValidaEmail} />
                    )}
                    {(proximo == 4) && (
                      <Etapa4 id={id} />
                    )}
                  </div>

                </div>
              </div>
            </div>
          </Col>
        </Row>

        <ValidacaoEmail email={email} id={id} modal={modalValidaEmail} setModal={setModalValidaEmail}
          setProximo={setProximo} />
        <ValidacaoCelular celular={celular} id={id} modal={modalValidaCelular} setModal={setModalValidaCelular} setProximo={setProximo} />
      </div>
    </Modal>
  );
};
