import React, { useEffect, useState } from "react";
import { Api } from "../../../api/apiSite";
import { baseUrlApi } from "../../../api/baseUrl";
export default function MainSection({ datas ,ramo}) {
  const [dados, setDados] = useState([]);

  useEffect(() => {
    handleOperadora()
  }, [datas]);
  const handleOperadora = async () =>{
    let response = await Api.operadorasAll(ramo)
  
    if (response?.list?.length > 0) {
      let listagem = []
      let operadoras = []
      response.list.filter((item)=>item.ocultargeral==1).map((item) => {
        if (!listagem.includes(item.arquivo)) {
          listagem.push(item.arquivo)
          operadoras.push(item)
        }
      })
    
     operadoras.filter((item)=>item.ocultargeral==1).sort((a,b)=>a.ordemgeral - b.ordemgeral)
      setDados(operadoras)
    }
  }
  return (
    <section className="portfolio-section ">
      <div className="container" style={{maxWidth:'1200px'}}>
        
        <div className="row align-items-center portfolio-wrap"  >
          {dados?.length>0 ? dados.sort((a,b)=>a.ordemgeral - b.ordemgeral)?.filter((item)=>item?.imagem).map((item) => (
              <a key={item.id} href={`/${item.subdominio}`} className={`col-lg-3 col-sm-6 item `} style={{ cursor: "pointer" }} >
                <div style={{ cursor: "pointer"}} >
                  <div className="feature-content style-three" style={{ textAlign: "center", marginTop: "auto", height: 130 }}  >
                   
                    <img src={`${baseUrlApi}/imagens/${item?.imagem?item.imagem:item?.icone}`} alt="" height={100} />
                  </div>
                </div>
              </a>
            )): 
            <>
              <a className={`col-lg-12 col-sm-12 item `} style={{ cursor: "pointer" }} >
                <div style={{ cursor: "pointer"}} >
                  <div className="feature-content style-three" style={{ textAlign: "center", marginTop: "auto", height: 130 }}  >
                    Aguarde, breve novidades
                  </div>
                </div>
              </a>
            </>}
        </div>
      </div>
    
    </section>
  );
}
