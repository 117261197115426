import React, { useEffect, useState } from "react";
import { Col, Input, Label, Row, UncontrolledAlert } from "reactstrap";
import { Api } from "../../../api/apiCotacoes";
import { Calculadora } from "./calculadora";
import { Api as ApiSite } from "../../../api/apiSite";
import Select from "react-select";
import Icone from "../../../assets/icones/icone.png"
import InputMask from 'react-input-mask';
export const Cadastro = ({ resposta, error, msg, validation, estados, loading, faixas, handleFaixasValue, handleAdd, handleExit, itens,
  handleTipoValue, setResposta, setSalvarVisualizar, profissoes, setLoading }) => {
  const [tipo, setTipo] = useState("2");
  const [tiposCnpj, setTiposCnpj] = useState([]);
  const [faixasList, setFaixasList] = useState([]);
  const [modalCalculadora, setModalCalculadora] = useState(false)
  const [tiposOperadoras, setTiposOperadoras] = useState([])
  const estadosTraduz = {
    "AC": "Acre",
    "AL": "Alagoas",
    "AP": "Amapá",
    "AM": "Amazonas",
    "BA": "Bahia",
    "CE": "Ceará",
    "DF": "Distrito Federal",
    "ES": "Espírito Santo",
    "GO": "Goiás",
    "MA": "Maranhão",
    "MT": "Mato Grosso",
    "MS": "Mato Grosso do Sul",
    "MG": "Minas Gerais",
    "PA": "Pará",
    "PB": "Paraíba",
    "PR": "Paraná",
    "PE": "Pernambuco",
    "PI": "Piauí",
    "RJ": "Rio de Janeiro",
    "RN": "Rio Grande do Norte",
    "RS": "Rio Grande do Sul",
    "RO": "Rondônia",
    "RR": "Roraima",
    "SC": "Santa Catarina",
    "SP": "São Paulo",
    "SE": "Sergipe",
    "TO": "Tocantins"
  };
  const handleTipoOperadoras = async () => {
    let option = await Api?.tiposOperadoras()
    let list = option?.sort((a, b) => b.nome.localeCompare(a.nome));

    setTiposOperadoras(list)
  }
  useEffect(() => {
    handleTipoOperadoras()
    setFaixasList(faixas)
  }, [faixas])
  useEffect(() => {
    let tipoCnpj = ['Padrão', "MEI", "CAEPF", "CEI"]
    let profissoes = []
    // console.log({ estados })
    estados.map((item) => {
      if (item.id == validation.values.estados) {
        if (item.areacomercializacaoestadosmunicipios) {
          item.areacomercializacaoestadosmunicipios.map((item2) => {
            let tipos = item2.tabela.tipocnpj?.split(',')
            if ((tipos?.length > 0 && tipos[0]) ? !tipoCnpj.includes(tipos[0]) : false) {
              tipoCnpj.push(tipos[0])
            }
            if ((tipos?.length > 0 && tipos[1]) ? !tipoCnpj.includes(tipos[1]) : false) {
              tipoCnpj.push(tipos[1])
            }
            if ((tipos?.length > 0 && tipos[2]) ? !tipoCnpj.includes(tipos[2]) : false) {
              tipoCnpj.push(tipos[2])
            }
          })
        }
      }
    })

    setTiposCnpj(tipoCnpj)

  }, [validation.values.estados])
  const [documentValue, setDocumentValue] = useState('');
  const [mask, setMask] = useState('999.999.999/9999-99'); // Máscara vazia inicialmente
  const [value, setValue] = useState("");
  const formatValue = (value) => {
    // if (!value) return '';
    if (validation.values.tipocnpj == 'CAEPF') {
      setMask('999.999.999/999-99')
    } else {
      setMask('99.999.999/9999-99')
    }
  };
  useEffect(() => {
    const inputValue = documentValue?.replace(/\D/g, '');

    const formattedValue = formatValue(inputValue);
    setValue(inputValue)
    setDocumentValue(formattedValue);

  }, [validation.values.tipocnpj])
  function formatarTelefone(telefone) {

    telefone = telefone.replace(/\D/g, '');

    if (telefone.length === 11) {

      return telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    } else if (telefone.length === 10) {

      return telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
    } else {
      return 'Número inválido';
    }
  }
  const [situacao_cadastral, setSituacao_cadastral] = useState('')
  const handleDocumentChange = async (event) => {
    setLoading(true)
    setSituacao_cadastral('')
    const inputValue = event.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    const formattedValue = formatValue(inputValue);
    setValue(inputValue)
    setDocumentValue(formattedValue);

    const isCnpj = inputValue.length == 14;

    if (isCnpj) {

      let response = await ApiSite.DadosBuscarCNPJ(inputValue)

      if (response?.dados?.razao) {
        let estadoTraduzido = estados.find((item) => item.nome == estadosTraduz[response.dados.uf])
        validation.setFieldValue('estados', estadoTraduzido?.id)
        validation.setFieldValue('tipocnpj', response?.dados.opcao_mei == 'Sim' ? 'MEI' : 'Padrão')
        validation.setFieldValue('descricao', response?.dados.razao)
        validation.setFieldValue('email', response?.dados?.email?.toLowerCase())
        validation.setFieldValue('telefone', formatarTelefone(response?.dados?.telefone))
        validation.setFieldValue('municipio', response?.dados.municipio)
        setSituacao_cadastral(response.dados.situacao_cadastral)

      } else {
        validation.setFieldValue('estados', '')
        validation.setFieldValue('descricao', '')
        validation.setFieldValue('email', '')
        validation.setFieldValue('telefone', '')
        validation.setFieldValue('municipio', '')
        setSituacao_cadastral("")
      }

    }
    setLoading(false)
  };
  useEffect(() => {
    setDocumentValue("");
  }, [validation.values.tipoOperadora])
  const customStyles = {
    control: (provided) => ({
      ...provided,
      // Altere para o tamanho desejado
      height: '39px', // Altere para o tamanho desejado
      minHeight: '30px', // Define uma altura mínima
    }),
    menu: (provided) => ({
      ...provided,
      width: '200px', // Altere para corresponder à largura do controle
    }),
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    setSituacao_cadastral('')
  }, [documentValue])
  return (
    <>

      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col sm={12} md={3} lg={3} xl={3} className="mt-2">
          <div>
            <div className="">
              <Label htmlFor="example-text-input">Ramo </Label>
              <select name="tipoOperadora" value={validation.values.tipoOperadora} className="form-control" onChange={(e) => {
                validation.handleChange(e)
              }} >
                <option>Selecione</option>
                {tiposOperadoras && tiposOperadoras.map((item) => (
                  <option key={item.id} value={item.id} >{item.nome}</option>
                ))}
              </select>
            </div>


          </div>
        </Col>
        {((validation.values.tipoOperadora == 1 || validation.values.tipoOperadora == 4)) && (
          <Col className="mt-2" sm={12} md={3} lg={3} xl={3} >
            <div>
              <div className="">
                <Label htmlFor="example-text-input">
                  Tipo de Empresa
                </Label>
                <select name="tipocnpj" value={validation.values.tipocnpj} className="form-control"
                  onChange={(e) => {
                    validation.handleChange(e);
                  }}
                >
                  <option value="">Selecione</option>
                  {tiposCnpj.map((item) => (
                    <option key={item} value={item}>{item == 'Padrão' ? `CNPJ Padrão` : item}</option>
                  ))}
                  {tiposCnpj.length == 0 &&
                    <option value={'Padrão'}>CNPJ Padrão</option>
                  }


                </select>
              </div>
            </div>
          </Col>
        )}
        {((validation.values.tipoOperadora == 1 || validation.values.tipoOperadora == 4)) && (
          <Col className="mt-2" sm={12} md={3} lg={3} xl={3}>
            <div>
              <div className="">
                <Label htmlFor="example-text-input">
                  {validation.values.tipocnpj == 'CAEPF' ? "CAEPF" : "CNPJ"} {" "}
                  {situacao_cadastral &&
                    <>
                      -<span style={{ marginLeft: 5, color: situacao_cadastral == 'Ativa' ? 'green' : 'red' }} >{situacao_cadastral == 'ativa' ? 'Ativo' : situacao_cadastral}</span>
                    </>
                  }

                </Label>
                <InputMask mask={mask} name="document" value={documentValue} onChange={(e) => {
                  handleDocumentChange(e)
                }} onBlur={validation.handleBlur} className="form-control" type="tel" />

                {/* <input type="text" placeholder="Opcional" id="document" disabled={(validation.values.tipoOperadora && estados?.length > 0) ? false : true} className="form-control" value={documentValue} onChange={handleDocumentChange} maxLength="18" /> */}
              </div>
            </div>
          </Col>
        )}

        <Col className="mt-2" sm={12} md={3} lg={3} xl={3}>
          <div>
            <div className="">
              <Label htmlFor="example-text-input">
                Estado
              </Label>
              <select name="estados" disabled={(validation.values.tipoOperadora && estados?.length > 0) ? false : true} value={validation.values.estados} className="form-control"
                onChange={(e) => {
                  validation.handleChange(e);
                }}
              >
                <option>Selecione</option>
                {estados && estados.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Col>


        {((validation.values.tipoOperadora && estados?.length > 0) && validation.values.tipoOperadora == 3) &&

          <Col className="mt-2" sm={12} md={12} lg={3} xl={3}>
            <Label htmlFor="example-text-input" >
              Profissão
            </Label>
            <Select placeholder='Selecione' className="basic-multi-select"
              isDisabled={validation.values.tipoOperadora == 4 ? (validation.values.numerovidas ? false : true) : false}
              options={profissoes?.map((item) => { return { value: item.id, label: item.nome } })}
              styles={customStyles}
              value={validation.values.profissao}
              onChange={(val) => {
                validation.setFieldValue('profissao', val)
              }} />

            {/* <select name="profissao" value={validation.values.profissao} className="form-control" onChange={(e) => {
                  validation.handleChange(e);
                }}
                >
                  <option value="">Todas as Opções</option>
                  {profissoes && profissoes.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </select> */}
          </Col>
        }
      </Row >


      {loading &&

        <div
          className={`col-lg-12 col-sm-12 item`}
          style={{
            height: "20vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "aboslute",
            width: "100%",
            zIndex: 99999999999999,
            backgroundColor: `rgb(246, 247, 249,0.4)`,
          }}
        >
          <img src={Icone} className="rotating-image" />
        </div>}
      {!loading &&
        <>
          <Row style={(validation.values.tipoOperadora) ? { marginTop: 30, marginBottom: 25 } : { marginTop: 30, marginBottom: 15 }}>


            {(validation.values.tipoOperadora == 4) && (
              <>
                <Col lg={12}>
                  <div>
                    <div className="">
                      <Label
                        htmlFor="example-text-input"

                        style={{ textAlign: 'center', width: '100%', marginBottom: 25 }}
                      >
                        Digite a quantidade de vidas por faixa etária{" "}
                      </Label>
                      {(['2', '3', '4'].includes(validation.values.tipoOperadora)) &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-30px' }}>
                          <button
                            className="btn btn-link"
                            type="button"
                            disabled={loading}
                            onClick={() => {
                              setModalCalculadora(true);
                            }} > Calculadora de Idade
                          </button>
                        </div>
                      }
                      <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {faixasList &&
                          faixasList?.map((item, index) => (
                            <Col lg={2} md={2} xl={2} key={index} className="mt-15" >


                              <Input
                                name="faixas"
                                value={item.value}

                                style={{ textAlign: 'center', padding: 0, paddingLeft: 14 }}
                                placeholder={item.nome.replace('-', ' a ').replace('+', 'ou +') + (item.nome.includes("+") ? "" : " anos")}
                                onChange={(e) => {
                                  handleFaixasValue(e.target.value, index);
                                }}
                                className="form-control"
                                type="number"
                              />

                            </Col>
                          ))}
                      </Row>
                    </div>
                  </div>
                </Col>
              </>
            )}


            {(validation.values.tipoOperadora == 2 || validation.values.tipoOperadora == 3 || validation.values.tipoOperadora == 6) && (
              <>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-10px' }}>
                  <button
                    className="btn btn-link"
                    type="button"
                    disabled={loading}
                    onClick={() => {
                      setModalCalculadora(true);
                    }} > Calculadora de Idade
                  </button>
                </div>
                <Col lg={2}>
                  <div>
                    <div className="mb-3">
                      <Label htmlFor="example-text-input" >
                        Idade do Titular
                      </Label>
                      <Input name="titular" value={validation.values.titular} onChange={validation.handleChange} className="form-control" type="number" placeholder="Idade" />
                    </div>
                  </div>
                </Col>
                {itens.map((item, index) => (
                  <>
                    <Col lg={2} >
                      <div>
                        <div className="mb-3">
                          <Label htmlFor="example-text-input" >
                            Dependente {index + 1}
                          </Label>

                          <Input name="dependentearray" value={item} className="form-control" type="number" placeholder="Idade"
                            onBlur={(e) => {
                              if (e.target.value) {
                                index == itens.length - 1
                                  && handleAdd(e.target.value)

                              }
                            }}
                            onChange={(e) => {
                              handleTipoValue({
                                item: index,
                                val: e.target.value
                              });
                            }}

                          />
                        </div>
                      </div>
                    </Col>
                  </>
                ))}
              </>

            )}



            {(validation.values.tipoOperadora == 5 || validation.values.tipoOperadora == 1) && (
              <>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '-10px' }}>
                  <button
                    className="btn btn-link"
                    type="button"
                    disabled={loading}
                    onClick={() => {
                      setModalCalculadora(true);
                    }} > Calculadora de Idade
                  </button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <Col lg={3}>
                    <div>
                      <div className="mb-3">
                        <Label htmlFor="example-text-input">
                          Número de Titulares
                        </Label>
                        <Input
                          name="titular"
                          disabled={validation.values.tipoOperadora == 1 ? false : true}
                          value={validation.values.titular}
                          onChange={validation.handleChange}
                          className="form-control"
                          type="number"
                          placeholder={validation.values.tipoOperadora == 1 ? "Quantidade" : "1"}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div>
                      <div className="mb-3">
                        <Label htmlFor="example-text-input">
                          Número de Dependentes
                        </Label>
                        <Input
                          name="dependentes"
                          value={validation.values.dependentes}
                          onChange={validation.handleChange}
                          placeholder="Quantidade"
                          className="form-control"
                          type="number"
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </>
            )}


          </Row>

          <Row style={{ marginTop: '-10px' }} >

            <Col lg={12} style={{ marginBottom: '-10px' }}>
              <div>
                <div className="mb-3">
                  <Label
                    htmlFor="example-text-input"
                    style={{ textAlign: 'center', width: '100%' }}>
                    DADOS DO CLIENTE

                  </Label>

                </div>
              </div>
            </Col>
            <Col lg={3} md={3} xl={3} >
              <div>
                <div className="mb-3">
                  <Label htmlFor="example-text-input">
                    Nome do Cliente
                  </Label>
                  <Input name="descricao" placeholder="Opcional" value={validation.values.descricao} onChange={validation.handleChange} className="form-control" type="text" />
                </div>
              </div>
            </Col>


            <Col lg={3} md={3} xl={3}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="example-text-input">
                    Email
                  </Label>
                  <Input name="email" placeholder="Opcional" value={validation.values.email} onChange={validation.handleChange} className="form-control" type="text"
                  />
                </div>
              </div>
            </Col>
            <Col lg={3} md={3} xl={3}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="example-text-input">
                    Telefone
                  </Label>
                  <Input name="telefone" placeholder="Opcional" value={validation.values.telefone} onChange={validation.handleChange} className="form-control" type="text" />
                </div>
              </div>
            </Col>
            <Col lg={3} md={3} xl={3}>
              <div>
                <div className="mb-3">
                  <Label htmlFor="example-text-input">
                    Município
                  </Label>
                  <Input name="municipio" placeholder="Opcional" value={validation.values.municipio} onChange={validation.handleChange} className="form-control" type="text" />
                </div>
              </div>
            </Col>
          </Row>

        </>

      }



      <div style={{ display: "flex", width: '100%', justifyContent: 'center', alignItems: 'center' }} >



        <button
          className="btn btn-primary"
          type="submit"
          disabled={loading}
          onClick={() => {
            setSalvarVisualizar(true);
          }}
          style={{ margin: 20 }}
        >
          Visualizar Cotações
        </button>




      </div>


      <Calculadora modalCalculadora={modalCalculadora} setModalCalculadora={setModalCalculadora} />
    </>
  );
};
