import React, { useState } from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Cotacoes from "./pages/PageCotacao/header";
import TabelaPersonalizada from "./pages/PageTabelasPersonalizadas/header";
import Home from "./pages/Home"
import Material from "./pages/PageMaterial/header";
import Plano from "./pages/PagePlano/header";
import TabelaPrecos from "./pages/PageTabelaPrecos/header";
import FerramentaVendas from "./pages/PageFerramentaVendas/header";
import Ramos from "./pages/PagePesquisarProdutos/header";
import RamosList from "./pages/Ramos/header";
import VendaOnline from "./pages/PageVendaOnline/header";
import Blog from "./pages/PageComunicados/header";
import Treinamentos from "./pages/PageTreinamentos/header";
import Operadoras from "./pages/Operadoras/header";
import PageTemplate from "./pages/PageEmpresas/header";
import PageTemplateRamo from "./pages/PageRamos/header";
import FourZeroFour from "./components/FourZeroFour";
import PageCotacao from "./pages/PageCotacaoVisualizacao/header";
import TermosDeUso from "./pages/PageTermos/header";
import PoliticaPrivacidade from "./pages/PageTermosPoliticas/header";
import { getCookie } from "./components/cookies";
export default function Routers() {
  const [obj, setObj] = useState(true)
  const [valida, setValida] = useState(true)

  useEffect(() => {
    const authUser = getCookie('authUser')
    setObj(authUser)
    if (!authUser?.id) {
      setValida(false)
    }
  }, []);

  return (
    <Routes>
      <Route exact path="/termosdeuso" element={<TermosDeUso />} />
      <Route exact path="/politicadeprivacidade" element={<PoliticaPrivacidade />} />
      <Route exact path="/" element={<Home />} />
      <Route exact path="/login/:tokenUrl" element={<Home />} />
      <Route exact path="/tabelaprecos/:id" element={<TabelaPrecos />} />
      <Route exact path="/cotacoesprecos/:id" element={<PageCotacao />} />
      <Route exact path="/plano/:plano" element={<Plano />} />
      <Route exact path="/ramos" element={<RamosList />} />
      <Route exact path="/ramos/:id" element={<PageTemplateRamo />} />

      {/* <Route exact path="/ramos/:id" element={valida ? <Ramos /> } /> */}
      {/* <Route exact path="/:estado/:plano/:pessoa/:operadora"  element={<Fornecedor /> } /> */}
      {/* <Route exact path="/operadora/:ramo" element={<Operadoras />} /> */}

      <Route exact path="/empresas" element={<Operadoras />} />
      <Route exact path="/empresas/:id" element={<PageTemplate />} />
      <Route exact path="/comunicados" element={<Blog />} />
      <Route exact path="/treinamentos" element={<Treinamentos />} />
      <Route exact path="/404" element={<FourZeroFour />} />

      {/* Rotas Privadas */}
      <Route exact path="/produtos" element={<Ramos /> } />
      <Route exact path="/tabelacotacoes/:id/:idOperadora" element={<PageCotacao /> } />
      <Route exact path="/tabelacotacoes/:id/" element={<PageCotacao /> } />
      <Route exact path="/ferramentas" element={<FerramentaVendas />} />
      <Route exact path="/cotacoes" element={<Cotacoes /> } />
      <Route exact path="/cotacoes/minhascotacoes" element={<Cotacoes /> } />
      <Route exact path="/tabela" element={<TabelaPersonalizada /> } />
      <Route exact path="/vendaOnline/:id" element={<VendaOnline /> } />
      <Route exact path="/vendaOnline" element={<VendaOnline /> } />
      <Route exact path="/materiais" element={<Material /> } />
      <Route exact path="/materiais/:id" element={<Material /> } />
      <Route exact path="/unauthorized" element={<FourZeroFour />} />
      <Route exact path="/:id" element={<PageTemplate />} />


      <Route exact path="*" element={<FourZeroFour />} />
    </Routes>
  );
}
