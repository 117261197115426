import React, { useState, useEffect } from "react";
import { Col, Input, Label, Form, Row, UncontrolledAlert } from "reactstrap";
import { Api } from "../../../api/apiUsuario";
import { useFormik } from "formik";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { AlertResposta } from "../../../components/loading/alert";
import { ValidacaoEmail } from "../Validacao/email";
import { ValidacaoCelular } from "../Validacao/celular";
import { Toaster, toast } from "react-hot-toast";
import { getCookie, saveCookie } from "../../../components/cookies";
export const Dados = () => {
    document.title = "Corretor Parceiro";
    const [resposta, setResposta] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const cookie = getCookie('authUser');
    const [modalCelular, setModalCelular] = useState(false);
    const [modalEmail, setModalEmail] = useState(false);
    const [obj, setObj] = useState({})

    const validation = useFormik({
        enableReinitialize: true,
        validationSchema: Yup.object({
            id: Yup.string().required(),
            nome: Yup.string().required('O item é obrigatório'),
            nomeFantasia: Yup.string().nullable().optional(),
            data: Yup.string().required('O data é obrigatório'),
            documento: Yup.string().required('CPF ou CNPJ é obrigatório'),
            celular: Yup.string().required('O campo Celular é obrigatório'),
            email: Yup.string()
                .transform((value) => (value ? value.toLowerCase() : ''))
                .email('E-mail inválido')
                .required('O campo E-mail é obrigatório'),
        }),
        initialValues: {
            id: obj?.id,
            nome: obj?.nome,
            nomeFantasia: obj?.nomefantasia,
            data: obj?.endereco?.datanascimento,
            documento: '',
            celular: obj?.endereco?.celular,
            email: obj?.email,
        },
        onSubmit: async (values) => {
            setLoading(true)
            let response = await Api.editar(values);
            if (response?.error) {
                setError(true)
                setResposta(true)
                setMsg(response?.message)
            } else {
                setError(false)
                setResposta(true)
                setMsg(response?.message)

                let dados = { ...obj, ...values, nomefantasia: values?.nomeFantasia }
                dados.endereco = { ...dados?.endereco, datanascimento: values?.data, celular: values?.celular?.replace(/\D/g, "") }
                saveCookie('authUser', JSON.stringify(dados));
                if (obj?.celular?.replace(/\D/g, "") != values?.celular?.replace(/\D/g, "")) {
                    setModalCelular(true)
                }
                if (obj.email != values?.email) {
                    setModalEmail(true)
                }
            }
            setLoading(false)
            document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
    });
    useEffect(() => {
        let objs = cookie
        setObj(objs)
    }, [])
    const handleChange = (event) => {
        let onlyNumbers = event?.target?.value?.replace(/\D/g, "")
        if (onlyNumbers?.length > 14) return onlyNumbers.slice(0, 14);

        if (onlyNumbers?.length <= 11) {
            onlyNumbers = onlyNumbers.replace(/^(\d{3})(\d)/, "$1.$2")
                .replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3")
                .replace(/\.(\d{3})(\d)/, ".$1-$2");
        } else {
            onlyNumbers = onlyNumbers.replace(/^(\d{2})(\d)/, "$1.$2")
                .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
                .replace(/\.(\d{3})(\d)/, ".$1/$2")
                .replace(/(\d{4})(\d)/, "$1-$2");
        }
        validation.setFieldValue('documento', onlyNumbers)
    };
    useEffect(() => {
        if (obj?.cpf) {
            handleChange({ target: { value: obj?.cpf } })
        }
    }, [obj?.cpf])

    return (
        <div>
            <Form className="custom-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                <Row style={{ flex: 1, marginTop: 20, flexDirection: 'row' }}>

                    <Col lg={12}>
                        <div>
                            <div className="mb-1">
                                <Label htmlFor="example-text-input" className="form-Label" >
                                    {validation.values?.documento?.length > 11?.length > 11 ? "*Razão Social" : "*Nome Completo"}
                                </Label>
                                <Input name="nome" value={validation.values?.nome} onChange={validation.handleChange} className="form-control" type="text" />
                                {validation.touched?.nome && validation.errors?.nome && (
                                    <div style={{ color: 'red' }}>{validation.errors.nome}</div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div>
                            <div className="mb-1">
                                <Label htmlFor="example-text-input" className="form-Label" >
                                    {validation.values?.documento?.length > 11?.length > 11 ? "Nome do Responsável" : "Nome Fantasia"}
                                </Label>
                                <Input name="nomeFantasia" value={validation.values?.nomeFantasia} onChange={validation.handleChange} className="form-control" type="text" />
                                {validation.touched?.nomeFantasia && validation.errors?.nomeFantasia && (
                                    <div style={{ color: 'red' }}>{validation.errors?.nomeFantasia}</div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div>
                            <div className="mb-1">
                                <Label htmlFor="example-text-input" className="form-Label" >
                                    *Data de Nascimento
                                </Label>
                                <InputMask className="form-control" mask="99/99/9999" name="data" maskPlaceholder={null} value={validation.values.data} onChange={validation.handleChange} id="date" />
                                {validation.touched?.data && validation.errors?.data && (
                                    <div style={{ color: 'red' }}>{validation.errors?.data}</div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div>
                            <div className="mb-1">
                                <Label htmlFor="example-text-input" className="form-Label">
                                    {validation.values?.documento?.length > 11 ? "CNPJ" : "CPF"}
                                </Label>
                                <Input className="form-control" type="text" name="documento" value={validation.values?.documento} onChange={handleChange} />
                                {validation.touched?.documento && validation.errors?.documento && (
                                    <div style={{ color: 'red' }}>{validation.errors?.documento}</div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div>
                            <div className="mb-2">
                                <Label htmlFor="example-text-input" className="form-Label"  >
                                    *Celular Principal
                                </Label>
                                <InputMask mask={'(99) 99999-9999'} name="celular" value={validation.values.celular} onChange={validation.handleChange} onBlur={validation.handleBlur} className="form-control" type="tel" />
                                {validation.touched.celular && validation.errors.celular && (
                                    <div style={{ color: 'red' }}>{validation.errors.celular}</div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div>
                            <div className="mb-2">
                                <Label htmlFor="example-text-input" className="form-Label" >
                                    *E-mail
                                </Label>
                                <Input name="email" autocomplete="new-password" value={validation.values.email} onChange={validation.handleChange} className="form-control" type="email" style={{ textTransform: 'none' }} />
                                {validation.touched?.email && validation.errors?.email && (
                                    <div style={{ color: 'red' }}>{validation.errors?.email}</div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <button className="btn btn-primary" type="submit" disabled={loading} style={{ position: 'absolute', bottom: 20, right: 25 }}>Salvar</button>
                </Row>
            </Form>


            <ValidacaoEmail email={validation?.values?.email} modal={modalEmail} setModal={setModalEmail} id={obj?.id} />
            <ValidacaoCelular celular={validation?.values?.celular} modal={modalCelular} setModal={setModalCelular} id={obj?.id} />
        </div>
    );
};
