import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api/apiSite";
import Listagem from "./utils/listagem";
import Icone from "../../assets/icones/icone.png"
import { useCookies } from 'react-cookie';

function FeatureSection(val) {
  const [operadoraSaude, setOperadoraSaude] = useState();
  const [datas, setDatas] = useState([]);
  const { tipo } = useParams();
  const [estados, setEstados] = useState([])
  const [estadosSelect, setEstadosSelect] = useState('')
  const [planoSelect, setPlanoSelect] = useState('')

  const [loading, setLoading] = useState(false)
  const [ramos, setRamos] = useState([])
  const [ramo, setRamo] = useState('')
  const [tipoRamo, setTipoRamo] = useState('')
  const [cookie, setCookie] = useCookies(['authUser'])
  const obj =cookie.authUser
  useEffect(()=>{
    handleRamos()
  },[])
  useEffect(() => {
    if (obj?.dados?.estado && estados.length > 0) {
      let ids = estados?.find((item) => item.nome == obj?.dados?.estado)
      setEstadosSelect(ids?.id)
    }
  }, [obj, estados])

  const handleRamos = async () => {
    let value = await Api.ramosVendaOline()
    let result = value.list.filter((item) => item.tiposoperadoras?.some((item) => item.operadora.linkvenda) ? true : item.operadoraslinks?.some((item) => item.link))
    result = result.map((item) => { return { id: item.id, nome: item.nome, tipo: item.tiposoperadoras?.some((item) => item.operadora.linkvenda) ? 'Odonto-Saude' : item.operadoraslinks.some((item) => item.link) ? 'Outros' : '' } })

    let uniqueArray = result.reduce((acc, current) => {
      const exists = acc.find(item => item.id === current.id);
      if (!exists) {
        acc.push(current);
      }
      return acc;
    }, []);
    uniqueArray.sort((a, b) => a.ordem - b.ordem)
    setRamos(uniqueArray)
  }

  const handleSelectRamo = async (value) => {
    setRamo(value)
    let result = ramos.find((find) => find.id == Number(value))
    setTipoRamo(result?.tipo)

    handleEstados(value)
  } 
  useEffect(()=>{
   
    handleSelectRamo(val?.id)
  },[val?.id,ramos])

  useEffect(() => {
    if(ramo && tipoRamo){
      if(tipoRamo=='Odonto-Saude'){
        if(estadosSelect){
          handleOperadora()
        }else{
          setDatas([]);
        }
      }else if(tipoRamo=='Outros'){
        handleOperadora()
      }
    }

  }, [tipoRamo,ramo,estadosSelect])


  const handleEstados = async (id) => {
    setEstadosSelect('')
    let tipo=  [2, 3, 4].includes(Number(id))? 1  : [1, 5, 6].includes(Number(id))? 2 :  [210].includes(Number(id)) ? 3 :  [280].includes(Number(id)) ? 4 : [217].includes(Number(id)) ? 5  :id
    let value = await Api.estadosVendaOline(id)
    setEstados(value.list)
  }
  const handleOperadora = async () => {
    
    setLoading(true)
    setDatas([]);
    let list = await Api.operadoraTipo({ tipo:ramo,estado: tipoRamo=='Odonto-Saude'?estadosSelect:'' });
    let variavel = list.list.filter((item) => ((item.linkvenda != null) && (item.linkvenda!='') || (item.link != null) && (item.link!='') ) );
    let valores = variavel.map((item) => {
      return { id: item.id, title: item.nome, image: item.imagem, filter: item.tipopessoa, link: item.linkvenda || item.link,usuario: item.usuario && item.linkUsuario };
    });
  
    setDatas({ valores, estado: estadosSelect });
    setLoading(false)
  };
  useEffect(() => {
    if (tipo == 'saude') {
      setPlanoSelect(1)
    } else if (tipo == 'odonto') {
      setPlanoSelect(2)
    }
    document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="featured-section bgs-cover pt-20 rpt-150 pb-20 rpb-100" style={{ minHeight: 700 }}>
      <div className="container">
        <div className="row" style={{ marginBottom: 15, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <div className={"col-8 col-md-4 col-lg-4"}  >

            <select className="form-control" name="estados"  value={ramo} onChange={(e) => { handleSelectRamo(e.target.value) }} >
              <option value=''>Escolha o Ramo</option>
              {ramos.map((item) => (
                <option key={'ramo-' + item.id} value={item.id}>{item.nome}</option>
              ))}
            </select>
          </div>

          {(tipoRamo == 'Odonto-Saude') &&
            <div className="col-8 col-md-4 col-lg-4" >

              <select className="form-control" name="estados" value={estadosSelect} onChange={(e) => { setEstadosSelect(e.target.value) }} >
                <option value=''>Escolha o Estado</option>
                {estados && estados.map((item) => (
                  <option key={item} value={item.id}>{item.nome}</option>
                ))}
              </select>
            </div>
          }

        </div>
        {(tipoRamo?(tipoRamo == 'Odonto-Saude' && estadosSelect)?true:tipoRamo:tipoRamo) && !loading && datas.valores?.length>0 && <Listagem datas={datas} tipoRamo={tipoRamo} />}
       

        <div className="feature-btn text-center mt-20"></div>
      </div>
    </section>
  );
}

export default FeatureSection;
