import React, { useEffect, useState } from "react";
import BackToTop from "../../lib/BackToTop";
import { FaWhatsapp } from "react-icons/fa";
import bgImage from '../../assets/images/bg-whatsapp.jpg';


export const AreaModal = ({ title, setNotificacao, notificacao }) => {
  return (
    <div style={{ position: 'absolute', borderRadius: 6, width: 120, top: '-50px', left: -80, backgroundColor: 'white', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)', color: '#092140', height: 50, verticalAlign: 'middle', display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >


      <p style={{ color: '#092140', opacity: '1' }} dangerouslySetInnerHTML={{ __html: title }}></p>
      <span aria-hidden="true" onClick={() => { setNotificacao(!notificacao) }} style={{ color: '#092140', fontSize: 20, position: 'absolute', right: 0, top: 0, marginRight: 5 }} >&times;</span>
    </div>
  )
}
function BacktoTopCom({ className }) {

  useEffect(() => {
    BackToTop(".scroll-top");
  });

  let minutosPassados = 0;

  // Função que será chamada a cada 10 minutos
  const [notificacao, setNotificacao] = useState(true);

  useEffect(() => {
    let notificacao = false
    // Função que será disparada quando a página carregar e a cada 2 minutos
    const handleEvent = () => {
      //  console.log("Evento disparado!",notificacao);
      // alert("Evento disparado!")
      if (notificacao) {
        notificacao = false
        setNotificacao(false)
      } else {
        notificacao = true
        setNotificacao(true)
      }

      // alert("Evento disparado!")
      // Coloque aqui o código que deseja executar
    };

    // Disparar o evento assim que a página for carregada
    handleEvent();

    // Definir um intervalo para disparar o evento a cada 2 minutos (120000ms)
    //120000
    const intervalId = setTimeout(() => {
      handleEvent();
    }, 5000);

    // Limpar o intervalo quando o componente for desmontado para evitar memory leaks
    return () => clearInterval(intervalId);
  }, []); // Array vazio [] para garantir que o useEffect seja executado apenas uma vez ao carregar a página

  const BalaoFala = () => {
    return (
      <div style={{
        position: 'absolute', top: '5px', right: '-20px',  marginRight: 80,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '15px',
        padding: '5px',
        width: '110px',
        fontFamily: 'Arial, sans-serif',
        fontSize: '14px',
        color: '#092140',
        display: 'inline-block',
        color: '#11A147'
      }}>
        Posso ajudar?
        <div style={{
          position: 'absolute',
          top: '50%',
          right: '-10px',
          transform: 'translateY(-50%)',
          width: '0',
          height: '0',
          borderTop: '4px solid transparent',
          borderBottom: '4px solid transparent',
          borderLeft: '8px solid #ccc'
        }}></div>
        <div style={{
          position: 'absolute',
          top: '50%',
          right: '-8px',
          transform: 'translateY(-50%)',
          width: '0',
          height: '0',
          borderTop: '4px solid transparent',
          borderBottom: '4px solid transparent',
          borderLeft: '8px solid #fff'
        }}></div>
      </div>

    );
  };
  const [card, setCard] = useState(false)
  return (
    <>

      {card &&


        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', position: "fixed", bottom: "80px", right: '30px', Zindex: "99", width: '400px', height: '300px', color: 'white', cursor: 'pointer', boxShadow: "0px 0px 20px #8e8e8e", borderRadius: 8, zIndex: 99999999 }}>
          <div style={{ backgroundColor: '#295f54', display: 'flex', justifyContent: 'flex-end', borderTopLeftRadius: 8, borderTopRightRadius: 8 }} >


            <span aria-hidden="true" onClick={() => { setCard(!card) }} style={{ color: '#fff', fontSize: 25, marginRight: 10 }} >&times;</span>
          </div>
          <div style={{ flex: 3, background: `url(${bgImage}) center top no-repeat` }}>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', backgroundColor: '#fff', borderRadius: 10, padding: '1px 10px', margin: "30px 70px 10px 0", fontSize: 15, fontWeight: 600, wordWrap: 'break-word', boxShadow: "rgba(0, 0, 0, 0.13) 0px 1px 0.5px", textAlign: 'left', marginLeft: 10 }}>
              <span style={{ color: '#777', fontSize: 14, display: 'block' }}></span>

              <span style={{ fontSize: 14, display: 'block', color: '#444' }}>
                Olá, Parceiro! 👋 <br />
                Tem dúvidas? Fale conosco no WhatsApp.
              </span>
              <span style={{ fontSize: 14, color: '#888', textAlign: 'right', width: '100%' }}>
                agora
              </span>

            </div>

          </div>
          <div style={{ backgroundColor: '#fff', flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>

            <a onClick={() => {
              window.open("https://api.whatsapp.com/send/?phone=558000607060&text=&type=phone_number&app_absent=0", "_blank");
            }} type="button" className={` ${className || ""}`}
              style={{ width: '90%', height: '40px', color: 'white', cursor: 'pointer', borderRadius: '20px', backgroundColor: '#25D366', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <FaWhatsapp size={30} />
              <span style={{ marginLeft: 10 }}>
                Chamar no Whatsapp
              </span>

            </a>

          </div>
        </div>
      }
      <div style={{ width: '100%', zIndex: 99999999 }}>
        <button type="button"
          style={{ position: "fixed", bottom: "30px", right: '20px', Zindex: "99", width: '50px', height: '50px', color: 'white', cursor: 'pointer', borderRadius: '50%', backgroundColor: '#25D366', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 99999999 }}>
          <FaWhatsapp size={30} onClick={() => { window.open("https://api.whatsapp.com/send/?phone=558000607060&text=&type=phone_number&app_absent=0", "_blank"); }} className={` ${className || ""}`} />
          {notificacao &&
            <BalaoFala />
          }
        </button>

      </div>



    </>

  );
}

export default BacktoTopCom;