
import React, { useEffect, useRef, useState } from "react";


function TermosDeUso() {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="featured-section bgs-cover pt-20  pb-20 rpb-100" style={{ minHeight: 850 }}>
      <div className="container"  >

        <div style={{ marginRight:isDesktop? 50:0, marginLeft: isDesktop? 50:0, textAlign: 'justify' }}>

          <header >
            <p style={{ textAlign: 'center', marginBottom: 20 }}>POLÍTICA DE PRIVACIDADE - CORRETOR PARCEIRO</p>
          </header>

          <section>
            <p>Bem-vindo ao Corretor Parceiro! Somos um portal desenvolvido pela <strong>Natuseg Assessoria</strong>, sociedade empresária limitada, inscrita no CNPJ/ME sob o n. 03.136.742/0001-37 (“Natuseg”). Nosso objetivo é oferecer apoio e recursos para corretores que atuam nos segmentos dos produtos disponibilizados no portal, facilitando suas operações e otimizando os serviços prestados aos seus clientes.</p>

            <p>A sua privacidade é nossa prioridade. Esta Política de Privacidade (“Política”) explica como coletamos, utilizamos e protegemos os Dados Pessoais dos usuários do nosso portal, corretores (as) e seus/suas clientes (“Você”), e quais os seus direitos em matéria de privacidade e proteção de dados, garantindo a conformidade com a Lei Geral de Proteção de Dados (LGPD).</p>

            <p>O fornecimento dos seus Dados Pessoais pressupõe a aceitação da presente Política de Privacidade. Portanto, caso não concorde com ela, solicitamos que não nos forneça os seus Dados Pessoais. Todavia, pedimos a gentileza de nos informar acerca da sua eventual discordância para que possamos utilizar seus fundamentos para avaliar a revisão dos nossos processos e desta Política.</p>

            <p>Após a leitura desta Política, caso necessite de mais esclarecimentos sobre como tratamos suas informações pessoais, fique à vontade para entrar em contato conosco através do e-mail <a href="mailto:privacidade@natuseg.com.br">privacidade@natuseg.com.br</a>.</p>
          </section>

          <section>
            <h3>1. Dados coletados, forma de coleta e finalidade</h3>
            <p>Para fornecer os nossos serviços de forma completa e personalizada, nós realizamos o tratamento de Dados Pessoais, mas apenas os essenciais para prestar os nossos serviços a Você e pelo período estritamente necessário.</p>

            <h3>Se Você é corretor(a):</h3>
            <ul>
              <li><strong>Dados coletados:</strong> CPF, data de nascimento, nome completo, e-mail e número de celular;</li>
              <li><strong>Forma de coleta:</strong> Você nos fornece diretamente ao se cadastrar em nosso portal;</li>
              <li><strong>Finalidade:</strong> Acesso à área logada do portal para uso dos recursos desenvolvidos para otimizar o processo de vendas, facilitando a apresentação e simulações de produtos e preços, informações e a finalização de negócios. Também é possível que utilizemos os seus Dados de contato para divulgação de produtos, serviços, campanhas promocionais e eventos relacionados ao conteúdo do portal.</li>
            </ul>

            <h3>Se Você é cliente ou potencial cliente de um corretor:</h3>
            <ul>
              <li><strong>Dados coletados:</strong> Idade e profissão. O fornecimento de informações como nome, telefone e e-mail é opcional e permite que o seu corretor ofereça uma experiência personalizada para você.</li>
              <li><strong>Forma de coleta:</strong> Nós recebemos os seus Dados por meio do corretor, que nos envia através do formulário de cotação disponível em nosso portal;</li>
              <li><strong>Finalidade:</strong> Fornecer cotação de planos de saúde e seguros.</li>
            </ul>

            <h3>Se Você é usuário do nosso website:</h3>
            <ul>
              <li><strong>Dados coletados:</strong> Endereço IP;</li>
              <li><strong>Forma de coleta:</strong> Através de cookies;</li>
              <li><strong>Finalidade:</strong> Realizar análises sobre o uso do portal para melhorar nossos serviços e personalizar a experiência do usuário.</li>
            </ul>
          </section>

          <section>
            <h3>2. Compartilhamento dos Dados Pessoais</h3>
            <p>Nós não vendemos, trocamos ou transferimos suas informações pessoais para terceiros. Podemos compartilhar suas informações apenas nas seguintes situações:</p>
            <ul>
              <li>Com os fornecedores dos produtos: para viabilizar cotações, propostas e serviços relacionados.</li>
              <li>Com autoridades competentes: em caso de exigências legais ou regulatórias.</li>
            </ul>
          </section>

          <section>
            <h3>3. Armazenamento e Segurança dos Dados Pessoais</h3>
            <p>Os Dados coletados são armazenados apenas pelo tempo necessário para cumprir as finalidades para as quais foram coletados, respeitando os prazos legais aplicáveis e nossa política interna de retenção de dados. Quando suas informações não forem mais necessárias ou quando Você solicitar a exclusão, tomaremos as medidas adequadas para eliminar esses Dados de forma segura, caso aplicável.</p>

            <p>Embora nenhuma transmissão de dados pela internet seja totalmente segura, nos esforçamos para proteger suas informações. Adotamos medidas de segurança adequadas para proteger suas informações pessoais.</p>
          </section>

          <section>
            <h3>4. Seus Direitos</h3>
            <p>O Corretor Parceiro respeita e garante os seus Direitos enquanto Titular de Dados, previstos na LGPD, que são:</p>
            <ul>
              <li>Acesso aos Dados;</li>
              <li>Anonimização, bloqueio ou eliminação de Dados desnecessários, excessivos ou tratados em desconformidade com a LGPD;</li>
              <li>Confirmação da existência do Tratamento;</li>
              <li>Correção de Dados incompletos, inexatos ou desatualizados;</li>
              <li>Portabilidade dos Dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</li>
              <li>Eliminação dos Dados Pessoais tratados com o consentimento do Titular, exceto nas hipóteses de cumprimento de obrigação legal ou regulatória pelo controlador e estudo por órgão de pesquisa, garantindo a anonimização dos Dados Pessoais sempre que possível;</li>
              <li>Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de Dados;</li>
              <li>Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
              <li>Revogação do consentimento, por procedimento gratuito e facilitado, ratificados os Tratamentos realizados sob o amparo do consentimento anteriormente manifestado.</li>
            </ul>
            <p>Para exercer qualquer um desses direitos, Você pode entrar em contato conosco através das informações fornecidas na seção de contato desta Política.</p>
          </section>

          <section>
            <h3>5. Cookies</h3>
            <p>Para melhorar sua experiência em nosso portal, utilizamos cookies. Cookies são pequenos arquivos de texto armazenados no seu dispositivo que nos ajudam a entender como você interage em nosso portal. Existem dois tipos principais de cookies que utilizamos:</p>
            <ul>
              <li><strong>Cookies de Sessão:</strong> São cookies temporários que permanecem no seu dispositivo enquanto você navega no nosso portal. Eles são apagados automaticamente assim que você fecha o navegador.</li>
              <li><strong>Cookies Persistentes:</strong> São cookies que permanecem armazenados no seu dispositivo após você fechar o navegador. Eles são utilizados para lembrar suas preferências e personalizar sua experiência em visitas futuras.</li>
            </ul>
            <p>Você pode gerenciar suas preferências de cookies nas configurações do seu navegador. No entanto, a desativação de cookies pode impactar sua experiência de navegação e as funcionalidades do Corretor Parceiro.</p>
          </section>

          <section>
            <h3>6. Dúvidas e Contato</h3>
            <p>Se Você tiver dúvidas, comentários ou solicitações relacionadas a esta Política de Privacidade ou ao Tratamento de seus Dados, entre em contato conosco através do e-mail: <a href="mailto:privacidade@natuseg.com.br">privacidade@natuseg.com.br</a>. Estamos sempre à disposição para atender suas dúvidas, sugestões e solicitações.</p>
          </section>

          <section>
            <h3>7. Modificações nesta Política</h3>
            <p>Nós podemos atualizar esta Política de Privacidade a qualquer momento. Sugerimos que Você visite esta Política regularmente para se manter informado sobre como estamos protegendo seus Dados.</p>
          </section>
        </div>
      </div>
    </section>
  );
}
export default TermosDeUso;
