import { ApiRequest } from "./request";

export const Api = {
  dominios: async (dados) => ApiRequest('post', '/site/dominios', dados),
  ramooperadorasDivulgacao: async () => ApiRequest('post', '/site/RamooperadorasDivulgacao'),
  estrutura: async () => ApiRequest('get', '/site/estrutura'),
  Visualizacao: async (dados) => ApiRequest('post', '/site/blogView', dados),
  VisualizacaoMaterial: async (dados) => ApiRequest('post', '/site/materialView', dados),
  banner: async (dados) => ApiRequest('post', '/site/banner', dados),
  areas: async () => ApiRequest('post', '/site/areas', { dominio: window.location.host }),
  operadoras: async (id) => ApiRequest('get', `/site/operadoras/${id}`),
  operadora: async (id) => ApiRequest('get', `/site/operadora/${id}`),
  ramosAtuacao: async (dados) => ApiRequest('post', '/site/ramosAtuacao', dados),
  ramosPaiAtuacao: async (dados) => ApiRequest('post', '/site/ramosPaiAtuacao', dados),
  sobre: async (dados) => ApiRequest('post', '/site/sobre', dados),
  depoimentos: async () => ApiRequest('post', '/site/depoimentos', { dominio: window.location.host }),
  blogs: async () => ApiRequest('post', '/site/blog', { dominio: window.location.host }),
  materiais: async () => ApiRequest('post', '/site/materiais', { dominio: window.location.host }),
  treinamentos: async () => ApiRequest('post', '/site/treinamentos', { dominio: window.location.host }),
  estadosSaude: async (dados) => ApiRequest('get', `/site/estadossaude/${dados}`),
  estadosOdontologico: async () => ApiRequest('get', '/site/estadosodontologico'),
  operadoraTipo: async (dados) => ApiRequest('post', '/site/operadoraTipo', dados),
  operadoraSaude: async (dados) => ApiRequest('post', '/site/operadoraSaude', dados),
  operadoraOdontologico: async (dados) => ApiRequest('post', '/site/operadoraOdonto', dados),
  operadoraPet: async (dados) => ApiRequest('post', '/site/operadorapet', dados),
  operadoraRH: async (dados) => ApiRequest('post', '/site/operadoraTipo', dados),
  operadoraUTI: async (dados) => ApiRequest('post', '/site/operadoraUTI', dados),
  operadoraCARD: async (dados) => ApiRequest('post', '/site/operadoraCARD', dados),
  operadoraConsorcio: async (dados) => ApiRequest('post', '/site/operadoraconsorcio', dados),
  operadoraMaterial: async (dados) => ApiRequest('post', '/site/operadoramaterial', dados),
  operadoraSaudeVenda: async (dados) => ApiRequest('post', '/site/operadorasaudevenda', dados),
  operadoraOdontologicoVenda: async (dados) => ApiRequest('post', '/site/operadoraodontologicovenda', dados),
  operadoraSeguro: async (dados) => ApiRequest('post', '/site/operadoraSeguro', dados),
  materialDivulgacao: async (dados) => ApiRequest('post', '/site/materialDivulgacao', dados),
  materialDivulgacaoEstados: async (dados) => ApiRequest('post', '/site/materialDivulgacaoEstados', dados),
  operadorasDivulgacao: async () => ApiRequest('get', '/site/operadorasDivulgacao/listagem'),
  DadosBuscarCNPJ: async (cnpj) => ApiRequest('post',  '/site/buscarCNPJ', { cnpj }),
  gestorModal: async (tela) => ApiRequest('post', '/site/gestorModal', { tela }),
  operadorasAll: async (ramo) => ApiRequest('get', `/site/operadorasAll/${ramo}`),
  ramosFornecedores: async (dados) => ApiRequest('post', '/site/ramosFornecedores', dados),
  ramosFornecedor: async () => ApiRequest('post', '/site/ramosFornecedor'),
  fornecedorRamos: async () => ApiRequest('post', '/site/fornecedorRamos'),
  RamosFornecedoresTemplate: async (dados) => ApiRequest('post', '/site/ramosFornecedoresTemplate', dados),
  RamosTemplate: async (dados) => ApiRequest('post', '/site/ramosTemplate', dados),
  ramosVendaOline: async () => ApiRequest('get', '/site/ramosVendaOline'),
  estadosVendaOline: async (dados) => ApiRequest('get', `/site/estadosVendaOline/${dados}`)
};
