import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../../api/apiSite";
import SliderCom from "../../../components/helpers/SliderCom";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { Row, Col } from "reactstrap";
import { baseUrlApi } from "../../../api/baseUrl";

export default function Ramos({ list }) {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [list]);

    const sliderRef = useRef(null);
    const settings = {
        dots: false, infinite: true, autoplay: true, autoplaySpeed: 2000, arrows: false, speed: 1000,
        slidesToShow: list?.length <= 4 ? list?.length : 4,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 992, settings: { slidesToShow: 4 } },
            { breakpoint: 767, settings: { slidesToShow: 3 } },
            { breakpoint: 575, settings: { slidesToShow: 1 } }
        ]
    }
    const prevHandler = () => sliderRef.current.slickPrev();
    const nextHandler = () => sliderRef.current.slickNext();


    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredVerMais, setIsHoveredVerMais] = useState(false);
    const handleMouse = () => {
        setIsHoveredVerMais(!isHoveredVerMais);
    };
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const textStyle = {
        textDecoration: isHovered ? 'underline' : 'none',
        cursor: 'pointer'
    };
    const textStyleVerMais = {
        textDecoration: isHoveredVerMais ? 'underline' : 'none',
        cursor: 'pointer'
    };
    return (
        <section className="" style={{ marginTop: 40, marginBottom: 60 }}>
            <Row style={{ display: 'flex', marginTop: '-20px',display:'flex',justifyContent:"center"  }} >
                {list &&
                    list.map((item, index) => (
                        <Col style={{ flex: '0 0 210px' }} >
                            <a href={`/ramos/${item.id}`} tabIndex={-1} id={item.id} key={item.id} style={{ cursor: "pointer", marginBottom: 0 }} >
                                <div key={item?.id} style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-around', alignItems: 'center' }} >
                                    <a style={{ margin: '25px 10px 10px', display: 'flex', flexDirection: "column", justifyContent: 'space-around', alignItems: 'center', height: 200, ...(!isDesktop && { width: 90 }) }} >
                                        <div style={{ height: 10 }}>
                                            <img src={`${baseUrlApi}/imagens/${item?.imagem ? item?.imagem : item?.icone}`} width={45} />
                                        </div>
                                        <div style={{ height: 50, display: 'flex', justifyContent: 'flex-end' }}>
                                            <p style={{ textAlign: 'center', fontSize: 14, color: '#092140', width: 180, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.4 }}>
                                                {item.nome.split('\n').map((linha, index) => (
                                                    <React.Fragment key={index}>
                                                        {linha}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </a>
                        </Col>
                    ))}
            </Row>
        </section>
    );
}
