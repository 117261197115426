import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Dados from "./index";
import Layouts from "../Home/Layouts";
import { Api } from "../../api/apiSite";
export default function ServiceOne() {
  const { id} = useParams();
  const [operadora,setOperadora] = useState('Operadora')
  const [ramos, setRamos] = useState([])
  const [operadoras, setOperadoras] = useState([])
  const [descricao, setDescricao] = useState('')
  const [logo, setLogo] = useState('')
  const [titulo, setTitulo] = useState('')
  const [datas, setDatas] = useState({});
  const handleOperadoraRamos = async () => {
    setDatas({});
    let list = await Api.RamosFornecedoresTemplate({ id });
    let dados = list.list.find((item) => item.id == id)
    setDescricao(dados?.obs)
    setLogo(dados?.imagem)
    setTitulo(dados?.nome)
    setOperadora(dados?.nome)
    let ramo = list.list?.filter((item) => item.id !== Number(id))
    let operadorasList = []
    // let listagem = await Api.ramosFornecedor();
    let operadora = []
    list.list.map((item) => {
      item.tiposoperadoras.map((type) => {
        if (!operadora.some((som) => som?.id == type.operadora?.id)) {
          operadora.push(type.operadora)
        }
      })
    });
    if (list?.list?.length > 0) {
      let listagem = []

      operadora.map((item) => {
        if (!listagem.includes(item?.arquivo)) {
          listagem.push(item?.arquivo)
          operadorasList.push(item)
        }
      });
    }
    
    setOperadoras(operadorasList)
    setRamos(ramo)
    // setDatas({ valores: [...valoresw, ...valores] });
    // setDatas({ valores: [...valoresw, ...valores] });
  };

  useEffect(() => {
    handleOperadoraRamos()
  }, [id]);
  return (
    <Layouts
      style={{ fontFamily: "Open Sans, sans-serif" ,textTransform:'none'}}
      pageTitle={titulo}
      
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Ramos de Atuação", path: "/ramos" },
        { name: titulo, path: `/ramos/${id}` },
      ]}
    >
      <Dados id={id} setOperadora={setOperadora} ramos={ramos} operadoras={operadoras} descricao={descricao} logo={logo} titulo={titulo} datas={datas} />
    </Layouts>
  );
}
