import React, { useEffect, useRef, useState } from "react";
import { Alert, Col, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap";
import { BiBook, BiBookOpen, BiCollapse, BiEdit, BiShow, BiWrench, BiHide } from "react-icons/bi";

import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Api } from "../../../api/apiUsuario";
import { Cadastro } from "../Cadastro";
import Esqueci from "../Recuperar";

import { GoogleLogin, GoogleOAuthProvider, googleLogout } from '@react-oauth/google';
import CodigoModal from "../codigo";
import { ExitModal } from "../../components/exitModal";
import { ValidacaoCelular } from "../Validacao/celular";
import { ValidacaoEmail } from "../Validacao/email";
import { ValidacaoEndereco } from "../Validacao/endereco";
import { getCookie, saveCookie } from "../../../components/cookies";
export const Login = ({ modalLogin, setModalLogin, msgSessao }) => {
  //localStorage.setItem("authUser", JSON.stringify({}));


  const [modalCadastro, setModalCadastro] = useState(false);

  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);

  const [celular, setCelular] = useState("");
  const [id, setid] = useState();
  const [modalCelular, setModalCelular] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const [error, setError] = useState();
  const [modalCodigo, setModalCodigo] = useState(false);
  const [esqueci, setModalEsqueci] = useState(false);
  const [handleHide, setHandleHide] = useState([]);
  const navigate = useNavigate();
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor entre com seu Usuário!"),
      password: Yup.string().required("Por favor entre com sua senha!")
    }),
    onSubmit: async (values) => {
      setLoading(true);

      let response = await Api.login(values);
      if (response?.error) {
        setError(response);
        setid(response?.user?.id);
        if (response.validaemail == false) {
          await Api.disparoEmail({ id: response?.user?.id, email: response.user.email })
          setModalEmail(true);
          setEmail(response?.user?.email);
        }
        if (response.validacelular == false) {
          await Api.disparoCelular({ id: response?.user?.id, celular: response.user.celular })
          setModalCelular(true);
          setCelular(response?.user?.celular);
        }
     
      } else {
        const { token, user } = response
        const { permissoes, ...dados } = user
        saveCookie('authToken', token)
        saveCookie('authUser', { ...dados })

        setModalLogin(false);
        let page = getCookie('authPagehist')
        navigate((!page?.includes('/') ? page : '/ferramentas'))
      }
      setLoading(false);
    }
  });
  const handleShowHide = async (type, status, index) => {
    if (status) {
      document.querySelector(`input[id="password${type}"]`).type = 'text'
    } else {
      document.querySelector(`input[id="password${type}"]`).type = 'password'
    }
    let valores = [...handleHide]
    valores[index] = !valores[index]
    setHandleHide(valores)
  }
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div>
      <Modal id="modal-center3" size="md" centered={true} isOpen={modalLogin}>
        <div className="modal-header" style={{ border: 'none' }} >

          <ExitModal setModal={setModalLogin} />
        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>

          <Row className="g-0">
            <Col lg={12} md={12} className="col-xxl-12">
              <div className="auth-full-page-content d-flex p-sm-2 p-1">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">
                      <div className="text-center">

                        <p className="" style={{ color: '#092140', fontSize: 20 }}>
                          {!msgSessao ?
                            <p>
                              Para usar essa funcionalidade<br />
                              é necessário entrar no sistema
                            </p>
                            :
                            <p style={{ color: '#C6272E' }} >
                              A sessão expirou por inatividade.<br /> Entre novamente no sistema
                            </p>}

                        </p>
                      </div>
                      <Form className="custom-form" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }} >

                        <div className="mb-1">
                          <Label className="form-label">Usuário</Label>
                          <Input name="email" className="form-control" placeholder="CPF OU CNPJ" type="text" onChange={(e) => { validation.handleChange(e) }}
                            onBlur={validation.handleBlur} value={validation.values.email || ""}
                            invalid={validation.touched.email && validation.errors.email ? true : false}
                          />
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <Label className="form-label">Senha</Label>
                            <div style={{ display: 'flex', flexDirection: 'row' }} >
                              <div style={{ flex: 8 }}>
                                <Input id="password" name="password" value={validation.values.password || ""} type="password" placeholder="******" onChange={validation.handleChange} onBlur={validation.handleBlur}
                                  invalid={validation.touched.password && validation.errors.password ? true : false
                                  }
                                />
                              </div>
                              <div style={{ border: '2px solid #cfdbf1', flex: 1, borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
                                {handleHide[0] ? <BiShow size={20} onClick={() => { handleShowHide('', false, 0) }} /> : <BiHide size={20} onClick={() => { handleShowHide('', true, 0) }} />}
                              </div>
                            </div>

                          </div>
                          <div className="col-2" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer', paddingLeft: 0 }} >
                          </div>
                          {validation.touched.password &&
                            validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="row ">
                          <div className="col">
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                              <a className="form-check-label" onClick={() => { setModalCodigo(true); }}
                                style={{ fontSize: 16, color: "#C6262E", textDecoration: 'none', textAlign: 'center', lineHeight: 1.4 }}
                                onMouseOver={(e) => { e.target.style.textDecoration = 'underline' }}
                                onMouseOut={(e) => { e.target.style.textDecoration = 'none' }} href="#" >
                                <span style={{ color: '#C6262E' }}>  Entrar {!isDesktop && <br />} com código</span>
                              </a>
                              <a className="form-check-label" onClick={() => { setModalEsqueci(true) }}
                                style={{ fontSize: 16, color: "#C6262E", textDecoration: 'none', textAlign: 'center', lineHeight: 1.4 }}
                                onMouseOver={(e) => { e.target.style.textDecoration = 'underline' }}
                                onMouseOut={(e) => { ; e.target.style.textDecoration = 'none' }} >
                                <span style={{ color: '#C6262E' }}>  Esqueci {!isDesktop && <br />} minha senha</span>
                              </a>

                            </div>
                            <div className="mt-3 " style={{ display: "flex", justifyContent: 'center' }}>
                              <button className="btn btn-primary btn-block" type="submit" disabled={loading} style={{ width: 160 }} >
                                Entrar
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                      <div className="text-center">
                        <p className="mb-0" style={{ marginTop: 10, fontSize: 16, color: "#092140" }}>
                          Não possui cadastro?
                        </p>
                        <button className="btn btn-danger" onClick={() => {
                          setModalLogin(false);
                          setModalCadastro(true);
                        }}>
                          Cadastre-se Grátis{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <ValidacaoCelular modal={modalCelular} setModal={setModalCelular} celular={celular} id={id} />
      <ValidacaoEmail modal={modalEmail} setModal={setModalEmail} email={email} id={id} />

      {/* <ValidacaoEndereco modalEndereco={modalEndereco} setModalEndereco={setModalEndereco} handleValidarEndereco={handleValidarEndereco} loading={loading} handleCEP={handleCEP} cep={cep} setCep={setCep} info={info} /> */}

      <Cadastro modalCadastro={modalCadastro} setModalCadastro={setModalCadastro} setModalLogin={setModalLogin} />
      <Esqueci modalEsqueci={esqueci} setModalEsqueci={setModalEsqueci} login={validation.values.email} />
      <CodigoModal setModal={setModalCodigo} modal={modalCodigo} login={validation.values.email} />
    </div>
  );
};
