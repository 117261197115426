import React from "react";
import { useParams } from "react-router-dom";
import Dados from "./index";
import Layouts from "../Home/Layouts";

export default function ServiceOne() {
  const { estado, plano } = useParams();
 
  return (
    <Layouts
      style={{ fontFamily: "Open Sans, sans-serif" ,textTransform:'none'}}
      pageTitle={`${plano == "saude" ? "Planos de Saúde" : plano=='odontologico'?"Planos Odontologicos":plano=="pet"?'Planos Pet (cães e gatos)':plano=="rh"?"PAT e benefícios para RH":plano=="uti"?"UTI Móvel Emergência 24h":"card"?"Cartões de Benefícios":""}`}
      
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: ` ${plano == "saude" ? "Planos de Saúde" : plano=='odontologico'?"Planos Odontologicos":plano=="pet"?'Planos Pet (cães e gatos)':plano=="rh"?"Pat e benefícios para RH":plano=="uti"?"UTI Móvel Emergência 24h":"card"?"Cartões de Benefícios":""}`, path: `/plano/${plano}` },
      ]}
    >
      <Dados plano={plano} estado={estado} />
    </Layouts>
  );
}
