import React from "react";
import Dados from "./index";
import Layouts from "../Home/Layouts";
import { useParams } from "react-router-dom";

export default function AboutThree() {
  const { id } = useParams();
  return (
    <>
      <Layouts
        style={{ fontFamily: "Open Sans, sans-serif", textTransform: 'none' }}
        pageTitle={'Cotacões'}
        breadcrumbs={[
          { name: "Home", path: "/" },
          { name: "Ferramentas", path: "/ferramentas" },
          { name: "Cotações", path: "/cotacoes" },
          { name: "Visualizar", path: `/cotacoesprecos/${id}` },
        ]}>
        <Dados />
      </Layouts>
    </>
  );
}
