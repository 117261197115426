import { ApiRequest } from "./request";

const baseRoute = "site"; // Caso precise adicionar um prefixo global às rotas

export const Api = {
  materiais: async () => ApiRequest("post", `/materiais`, { dominio: window.location.host }),
  listagem: async (dados) => ApiRequest("get", `/materialDivulgacao?local=${dados.local || ""}&total=${dados?.total || ""}&link=${dados?.link || ""}`),
  materialDivulgacao: async () => ApiRequest("get", `/materialDivulgacao`),
  materialDivulgacaoEstados: async () => ApiRequest("get", `/materialDivulgacao/materialDivulgacaoEstados`),
  materialDivulgacaoOperadoraRamos: async () => ApiRequest("get", `/materialDivulgacao/materialDivulgacaoOperadoraRamos`),
  ramooperadorasDivulgacao: async () => ApiRequest("post", `/RamooperadorasDivulgacao`),
  VisualizacaoMaterial: async (dados) => ApiRequest("post", `/materialView`, dados),
};
