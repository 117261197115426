import React, { useEffect, useState } from "react";
import { Api } from "../../../api/apiSite"
import { Link } from 'react-router-dom';
export default function Hero({ pageTitle, breadcrumbs = [] }) {
  const [list, setList] = useState();

  const handleDados = async () => {
    let list = await Api.banner();
    if (list?.list?.id) {
      setList(list.list);
    }
  };
  useEffect(() => {
    handleDados();
  }, []);
  return (
    <section
      className="page-banner bgs-cover overlay pt-1"
      style={{ 
        backgroundColor:'#f6f7f9',
         minHeight: 150, display:'block'}}
    >
    
      <div className="container"  >
        <div className="banner-inner">
          <h1 className="page-title" style={{fontSize:23,fontWeight:100, marginTop:80,color:'#092140',display:"flex"}} > 
          
        {pageTitle}</h1>
          <nav aria-label="breadcrumb" style={{marginTop:-10}}>
            <ol className="breadcrumb">
              {breadcrumbs &&
                breadcrumbs.length > 0 &&
                breadcrumbs.map((item) => (
                  <li key={Math.random()} className="breadcrumb-item" style={{color:'#092140'}}>
                    <Link to={item.path} style={{fontWeight:100,color:'#092140'}}> {item?.name}</Link>
                  </li>
                ))}
            </ol>
          </nav>
        </div>
      </div>
    </section>
  );
}
