import React, { useState } from "react";
import Apple from "../../assets/images/lojas/apple.png";
import Android from "../../assets/images/lojas/android.png";
import { Modal } from "reactstrap";
import { ExitModal } from "../components/exitModal";
export default function FooterHomeTwo({ }) {


  const handleClickLojas = () => {
    setModal(true)
  }
  const [modal, setModal] = useState(false)
  return (
    <footer
      className={`footer-two text-white `}
      style={{ backgroundColor: '#082140', marginTop: 'auto', display: 'flex', flexDirection: 'column', width: '100%' }}>

      <div className="copyright-area">
        <div className="" style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>


          <div className="copyright-inner " >

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: 10 }} >
              <img src={Apple} width={150} style={{ cursor: 'pointer' }} onClick={() => { handleClickLojas() }} />
              <img src={Android} width={150} style={{ cursor: 'pointer' }} onClick={() => { handleClickLojas() }} />
            </div>

            <p style={{ textAlign: 'center', width: '100%', marginBottom: 0 }}>copyright 2025 Corretor Parceiro</p>
            <p style={{ textAlign: 'center', width: '100%' }}>
              <a className="text" style={{ color: '#fff', textDecoration: 'underline', marginRight: 15 }}
                onMouseOut={(e) => { e.target.style.color = '#fff'; e.target.style.textDecoration = 'underline' }} href="/termosdeuso"
                target="_blank"  >
                Termos de Uso
              </a>
              <a className="text"
                onMouseOut={(e) => { e.target.style.color = '#fff'; e.target.style.textDecoration = 'underline' }} style={{ marginLeft: 2, marginRight: 2, color: '#fff', textDecoration: 'underline' }} href="/politicadeprivacidade" target="_blank" >
                Política de Privacidade
              </a>
            </p>

          </div>
        </div>
      </div>
      <Modal isOpen={modal} size='md' centered={true}>
        <div className="modal-header" style={{ border: '0px' }}>
          <ExitModal setModal={setModal} />

        </div>
        <div className="modal-body" style={{ border: '0px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>Nosso aplicativo estará disponível em breve.</div>
        </div>
        <div className="modal-footer" style={{ border: '0px' }}>

        </div>
      </Modal>
    </footer>
  );
}
