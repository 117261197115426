import { useEffect, useState, useRef } from "react";
import { Row, Form, Label, Input } from "reactstrap";
import { Api } from "../../../api/apiUsuario";
import React from "react";
import { Col } from "reactstrap";
import { useCookies } from "react-cookie";
import { useFormik } from "formik";
import * as Yup from 'yup';
import FileInputComponent from "../../components/buttonFile";
import { baseUrlApi } from "../../../api/baseUrl";
import { FaEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiShow, BiHide } from "react-icons/bi";
import { getCookie } from "../../../components/cookies";
export const Senha = () => {
    const [resposta, setResposta] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const cookie = getCookie('authUser');
    const [obj, setObj] = useState({})



    const validation = useFormik({
        enableReinitialize: true,
        validationSchema: Yup.object({
            id: Yup.string(),
            password: Yup.string().required("Senha é obrigatória"),
            passwordNova: Yup.string().matches(/^(?=.*[A-Z]).{8,}$/, "A senha deve ter pelo menos 8 caracteres e incluir uma letra maiúscula").required("Senha é obrigatória"),
            passwordNovaConfirm: Yup.string().matches(/^(?=.*[A-Z]).{8,}$/, "A senha deve ter pelo menos 8 caracteres e incluir uma letra maiúscula").required("Senha é obrigatória"),

        }),
        initialValues: {
            id: obj?.id,
            password: null,
            passwordNova: null,
            passwordNovaConfirm: null,
        },
        onSubmit: async (values) => {
            let { password, passwordNova, passwordNovaConfirm } = values
            setLoading(true)
            let response = await Api.editarSenha({ id: obj?.id, password: password, passwordNew: passwordNova, passwordConfirmNew: passwordNovaConfirm })
            if (!response?.error) {
                setError(false)
                setResposta(true)
                setMsg(response?.message)
            } else {
                setError(true)
                setResposta(true)
                setMsg(response?.message)
            }
            setLoading(false)
            document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
    });

    useEffect(() => {
        console.log(validation.errors)
    }, [validation.errors])
    const [handleHide, setHandleHide] = useState([]);
    const handleShowHide = async (type, status, index) => {
        if (status) {
            document.querySelector(`input[id="password${type}"]`).type = 'text'
        } else {
            document.querySelector(`input[id="password${type}"]`).type = 'password'
        }
        let valores = [...handleHide]
        valores[index] = !valores[index]
        setHandleHide(valores)
    }
    useEffect(() => {
        let objs = cookie
        setObj(objs)
    }, [])
    return (
        <Form
            className="custom-form"
            enctype='multipart/form-data'
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}>
            <div>
                <Row style={{ padding: '0px 20px 0px 20px', marginTop: 20 }} >

                    <div className="col-md-6 mb-5 my-5" >
                        <Label htmlFor="example-text-input" className="form-Label">
                            Senha atual
                        </Label>
                        <div style={{ display: 'flex' }} >
                            <div style={{ flex: 6 }} >
                                <div>

                                    <Input id="password0" name="password" autocomplete="new-password"
                                        onChange={validation.handleChange} value={validation.values?.password} className="form-control" type="password"
                                    />

                                </div>
                            </div>
                            <div style={{ flex: 1, display: 'flex', alignItems: "center", justifyContent: 'center', cursor: 'pointer', borderRadius: '5px', border: '1px solid #CED4D9', marginBottom: 10 }} >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div   > {handleHide[0] ? <BiShow size={20} onClick={() => { handleShowHide('0', false, 0) }} /> : <BiHide size={20} onClick={() => { handleShowHide('0', true, 0) }} />}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-5 my-5" ></div>
                    <div className="col-md-6 mb-5 my-5" >
                        <Label htmlFor="example-text-input" className="form-Label">
                            <label>Nova senha</label>
                        </Label>
                        <div style={{ display: 'flex' }}  >
                            <div style={{ flex: '6' }}  >
                                <div >
                                    <Input id="password1" name="passwordNova" autocomplete="new-password"
                                        onChange={validation.handleChange} value={validation.values?.passwordNova}
                                        className="form-control" type="password"
                                    />
                                </div>
                            </div>
                            <div style={{ flex: '1', display: 'flex', alignItems: "center", justifyContent: 'center', cursor: 'pointer', borderRadius: '5px', border: '1px solid #CED4D9', marginBottom: 10 }} >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div   > {handleHide[1] ? <BiShow size={20} onClick={() => { handleShowHide('1', false, 1) }} /> : <BiHide size={20} onClick={() => { handleShowHide('1', true, 1) }} />}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-5 my-5" >
                        <Label htmlFor="example-text-input" className="form-Label">
                            <label>Confirmar senha</label>
                        </Label>
                        <div style={{ display: 'flex' }}  >
                            <div style={{ flex: '6' }}  >
                                <div >

                                    <Input id="password2" name="passwordNovaConfirm" autocomplete="new-password"
                                        onChange={validation.handleChange} value={validation.values?.passwordNovaConfirm} className="form-control" type="password"
                                    />

                                </div>
                            </div>
                            <div style={{ flex: '1', display: 'flex', alignItems: "center", justifyContent: 'center', cursor: 'pointer', borderRadius: '5px', border: '1px solid #CED4D9', marginBottom: 10 }} >
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div   > {handleHide[2] ? <BiShow size={20} onClick={() => { handleShowHide('2', false, 2) }} /> : <BiHide size={20} onClick={() => { handleShowHide('2', true, 2) }} />}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-12 " >

                        <p style={{ color: "red", textAlign: 'center', marginTop: 20 }}>
                            Sua senha deve conter 8 dígitos com números e letras
                        </p>
                    </div> */}
                    {
                        ((validation.touched.passwordNova && validation.errors.passwordNova) || (validation.touched.passwordNovaConfirm && validation.errors.passwordNovaConfirm))
                        && (
                            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', width: '100%', marginTop: 20 }} >
                                <div style={{ color: 'red', textAlign: 'center' }}>{
                                    validation.errors.passwordNova || validation.errors.passwordNovaConfirm
                                }</div>
                            </div>
                        )}
                    <button className="btn btn-primary" type="submit" disabled={loading} style={{ position: 'absolute', bottom: 20, right: 25 }}>Salvar</button>
                </Row>


            </div>

        </Form>
    )
}