import React, { useEffect, useState } from "react";
import { Api as apiGestorModal } from "../../api/apiGestorModal";
import { Api as apiEstrutura } from "../../api/apiEstrutura";
import BacktoTopCom from "../../components/helpers/BackToTopCom";
import Footer from "./footer";
import Header from "./Header";
import Sobre from "./conteudo/sobre";
import Galeria from "./conteudo/ramos";
import Banner from "./conteudo/banner";
import Logo from "./conteudo/operadoras";
import { GestorModal } from "../components/modal";
import { baseUrlApi } from "../../api/baseUrl";

export default function HomeThree() {
  const [dados, setDados] = useState([]);
  const [ordem, setOrdem] = useState([]);
  const handleOrdem = async () => {
    let response = await apiEstrutura.listagem({ status: '1' });
    if (!response?.error) {
      let list = response?.find
      setOrdem(list);
    }
  };
  const handleModal = async () => {
    let response = await apiGestorModal.listagem({ tela: "Home" })
    if (!response?.error) {
      let list = response?.find
      setDados(list);
    }
  };
  useEffect(() => {
    handleOrdem();
    handleModal()
  }, []);

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Header />
      {ordem &&
        ordem?.map((item) => (
          <div key={item.id}>
            {item.id == 10 && <Banner />}
            {item.id == 12 && <Logo />}
            {item.id == 14 && <Sobre />}
            {item.id == 11 && <Galeria />}
            {item.id == 15 && <Testemunios />}
            {item.id == 16 && <Inscreva />}
            {item.id == 18 && <Contato />}
          </div>
        ))}
      <GestorModal dados={dados} />
      <Footer className="footer-home-three pt-150" />
      <BacktoTopCom />
    </div>
  );
}
