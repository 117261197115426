export function getRootDomain(url) {
    const domain = url.replace(/^(https?:\/\/)?(www\.)?/, '');
    const parts = domain.split('.');
    const len = parts.length;
    if (parts[len - 2] === 'com' && parts[len - 1] === 'br') {
      return parts[len - 3] + '.com.br';
    }
    if (parts[len - 1] === 'com') {
      return parts[len - 2] + '.com';
    }
    return domain;
  }