import React, { useState } from "react";
import { Col, Input, Label, Form, Row, UncontrolledAlert } from "reactstrap";
import { Api } from "../../../api/apiUsuario";
import { useFormik } from "formik";
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

export const Etapa2 = ({ setProximo, setid, id, tipoPessoa }) => {
  document.title = "Corretor Parceiro";
  const [resposta, setResposta] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const validation = useFormik({

    enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().required(),
      nome: Yup.string().required('O item é obrigatório'),
      nomeFantasia: Yup.string().nullable().optional(),
      data: Yup.string().required('O data é obrigatório')
    }),
    initialValues: {
      id: id,
      nome: '',
      nomeFantasia: '',
      data: '',
    },
    context: { tipoPessoa },
    onSubmit: async (values) => {
      setLoading(true)
      if (id) {
        let response = await Api.etapa2(values);
        if (response?.error) {
          setError(true)
          setResposta(true)
          setMsg(response?.message)
        } else {
          validation.resetForm()
          setid(response?.id)
          setProximo(3);
        }
      } else {
        setProximo(1);
      }
      setLoading(false)
      document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  });


  return (
    <Form
      className="custom-form"
      enctype='multipart/form-data'
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}>
      <Row className="g-0">
        <Col lg={12} md={12} className="col-xxl-12">
          <div className="auth-full-page-content d-flex p-sm-2 p-4">
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                <div className="auth-content my-auto">
                  <div className="text-center">
                    <p style={{ color: '#092140', fontSize: 20 }}>Complete seu Cadastro</p>
                  </div>
                 
                  <Row>
                    <Col lg={12}>
                      <div>
                        <div className="mb-1">
                          <Label htmlFor="example-text-input" className="form-Label" >
                            {tipoPessoa == 'PJ' ? "*Razão Social" : "*Nome Completo"}
                          </Label>
                          <Input name="nome" value={validation.values.nome} onChange={validation.handleChange} className="form-control" type="text" />
                          {validation.touched.nome && validation.errors.nome && (
                            <div style={{ color: 'red' }}>{validation.errors.nome}</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <div className="mb-1">
                          <Label htmlFor="example-text-input" className="form-Label" >{tipoPessoa == 'PJ' ? "Nome do Responsável" : "Nome Fantasia"} </Label>
                          <Input name="nomeFantasia" value={validation.values.nomeFantasia} onChange={validation.handleChange} className="form-control" type="text" />
                          {validation.touched.nomeFantasia && validation.errors.nomeFantasia && (
                            <div style={{ color: 'red' }}>{validation.errors.nomeFantasia}</div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <div className="mb-1">
                          <Label htmlFor="example-text-input" className="form-Label" >
                            *Data de Nascimento
                          </Label>
                          <InputMask className="form-control" mask="99/99/9999" name="data" maskPlaceholder={null} value={validation.values.data} onChange={validation.handleChange} id="date" />
                          {validation.touched.data && validation.errors.data && (
                            <div style={{ color: 'red' }}>{validation.errors.data}</div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>


                  <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: 15 }} >
                    <div>
                      <button className="btn btn-primary" style={{ float: "left" }}
                        onClick={() => {
                          setProximo(1);
                        }} >
                        Voltar
                      </button>
                    </div>
                    <div>
                      <button className="btn btn-primary" disabled={loading} style={{ float: "right" }}
                        type="submit" >
                        Avançar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
