import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import Info from "./info";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { baseUrlApi } from "../../../api/baseUrl";
export default function MainSection({ datas, plano }) {

  const [isotope, setIsotope] = useState(null);
  const [filterKey, setFilterKey] = useState("fisica");
  const [escolha, setEscolha] = useState("");
  const [dados, setDados] = useState([]);
  // const { estado, plano } = useParams();
  const [itemSize, setSize] = useState("0px");
  const [item2, setItem] = useState("");
  const handleFiltro = (val) => {

    if (val == "PF") {
      let fisica = datas.valores?.filter((item) => item.filter == "PF");
      setDados(fisica);
      setEscolha("PF");
    }
    if (val == "PJ") {
      let juridico = datas.valores?.filter((item) => item.filter == "PJ");
      setDados(juridico);
      setEscolha("PJ");
    }
   
    if ( ['uti','pet','card'].includes(plano)) {
      let juridico = datas.valores?.filter((item) => item.filter == plano?.toUpperCase());
   
      setDados(juridico);
      setEscolha(plano?.toUpperCase());
    }

  };
  const handleDados = () => {
    handleFiltro();
  };
  useEffect(() => {
    handleDados('PF');
  }, [datas]);
  const handler = (e, value) => {
    e.preventDefault();
    const getItems = document.querySelectorAll(`#${value}`).length;
    if (getItems > 0) {
      if (item2 !== value) {
        setItem(value);
      } else {
        setItem("");
      }
    }
  };
  return (
    <section className="portfolio-section   ">
      <div className="container">
        {!['uti','pet','card'].includes(plano) &&
          <ul className="portfolio-filter mb-45" style={{ listStyleType: 'none' }}>
            <li
              onClick={() => handleFiltro("PF")}
              className={escolha === "PF" ? "current" : ""}
              style={{
                fontFamily: "Open Sans, sans-serif",
                border: '1px solid #4180D6', fontWeight: 100
              }}
            >
              Pessoa Física
            </li>
            <li
              onClick={() => handleFiltro("PJ")}
              className={escolha === "PJ" ? "current" : ""}
              style={{ fontFamily: "Open Sans, sans-serif", border: '1px solid #4180D6', fontWeight: 100 }}
            >
              Pessoa Jurídica
            </li>
          </ul>
        }

        <div className="row align-items-center  portfolio-wrap">
          {(dados && (!['uti','pet','card'].includes(plano)? true : escolha) &&
            dados.length > 0) &&
            dados.map((item) => (
              <a
                key={item.id}
                className={`col-lg-12 col-sm-12 item `}
                style={{ cursor: "pointer" }}
               
              >
                <div className="feature-item   style-three " style={{ marginBottom: 10, padding: 10 }}>
                  <div className="icon"></div>

                  <div className="feature-content style-three" style={{ marginBottom: 5 }}>
                    <Link
                     onClick={(e) => handler(e, "operadora" + item.id)} 
                      className="menu-item-has-children active"
                      style={{ fontFamily: "Open Sans, sans-serif", marginLeft: 10, fontSize: 15 }} id={"operadora" + item.id}
                    >
                      <div style={{ float: 'left', width: '89%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <img className="w-10"
                          style={{  width: "70px", marginRight: "5px", textAlign: 'left' }}
                          src={`${item.image}`}
                          alt="Testimonial"
                        />
                        <a href="#" >
                          {item.title}
                        </a>
                      </div>
                      <div style={{ float: 'right' }}>
                        <span className="menu-expand" >
                          {item2 == (`operadora${item?.id}`) ?
                            <FaAngleUp />
                            :
                            <FaAngleDown />
                          }
                        </span>
                      </div>
                    </Link>
                    <div className="sub-menu" style={{ display: item2 == "operadora" + item.id ? "block" : "none" }} >
                      <Info id={item.id} estado={datas.estado} />
                    </div>
                  </div>
                </div>
              </a>
            ))}
        </div>
      </div>
    </section>
  );
}
