import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Dados from "./index";
import Layouts from "../Home/Layouts";

export default function ServiceOne() {
  const { id } = useParams();
  const [detalhes,setDetalhes] = useState({})
  return (
    <Layouts
      pageTitle={`Ramos de Atuação`}
      breadcrumbs={detalhes?.id ?  
         [ { name: "Home", path: "../" },
      { name: "Ramos de Atuação", path: "/ramos" },{ name: detalhes?.nome, path: `/ramos/${detalhes?.id}` }] 
      :
      [{ name: "Home", path: "../" },
      { name: "Ramos de Atuação", path: "/ramos" }] }
    >
      <Dados id={id} setDetalhes={setDetalhes}  />
    </Layouts>
  );
}
