import axios from "axios";
import { getCookie } from "../components/cookies";
import { baseUrlApi } from './baseUrl'
  const token = getCookie("authToken");

const axiosInstance = axios.create({
  baseURL: `${baseUrlApi}/tabelaPrecos`,
  headers: {
    "Authorization": `Bearer ${token}`,
    "Content-Type": "application/json"
  },
  validateStatus: (status) => true

});
export const Api = {
  entidades: async () => {
    let response = await axiosInstance.post("/entidades");
    return response.data.option;
  },
  estados: async (dados) => {
    let response = await axiosInstance.post("/estados", dados);
    return response.data.option;
  },
  operadoras: async (dados) => {
    let response = await axiosInstance.post("/operadoras", dados);
    return response.data.option;
  },
  tiposOperadoras: async (dados) => {
    let response = await axiosInstance.post("/tiposoperadoras", dados);
    return response.data.option;
  },
  numeroVidas: async (dados) => {
    let response = await axiosInstance.post("/numeroVidas", dados);
    return response.data.option;
  },
  enviarEmail: async (dados) => {
    let response = await axiosInstance.post(`/enviarEmail/${dados?.id || ""}`, dados)
    return response.data;
  },
  areas: async (dados) => {
    let response = await axiosInstance.post("/areas", dados);
    return response.data.option;
  },
  produtos: async (dados) => {
    let response = await axiosInstance.post("/produtos", dados);
    return response.data.option;
  },
  visualizar: async (dados) => {
    let response = await axiosInstance.post("/visualizar", dados);
    return response.data.dados;
  },
  gerarPdf: async (dados) => {
    let response = await ApiRequestCotacao('post', `/gerarPdf/${dados?.id || ""}`, dados)
    return response.data;
  },
  cadastro: async (dados) => {
    let response = await axiosInstance.post("/cadastro", dados);
    return response.data;
  },
  listagem: async () => {
    let response = await axiosInstance.post("/listagem");
    return response.data.list;
  },
  delete: async (dados) => {
    let response = await axiosInstance.delete("/delete/" + dados.id);
    return response.data.list;
  },
  editar: async (dados) => {
    let response = await axiosInstance.put("/editar", dados);
    return response.data;
  },
  filtar: async (dados) => {
    let response = await axiosInstance.post("/buscar", dados);
    return response.data;
  },
  dados: async (dados) => {
    let response = await axiosInstance.post("/dados", dados);
    return response.data.dados;
  },
  visualizarBuscar: async (dados) => {
    let response = await axiosInstance.post("/visualizarBuscar", dados);
    return response.data;
  },
  compartilhar: async (dados) => {
    let response = await axiosInstance.post("/compartilhar", dados);
    return response.data;
  }
};
