import { getCookie } from "../components/cookies";
import { axiosInstance } from "../instancias/instancia";
import { axiosInstanceCotacao } from "../instancias/instanciaCotacao";

export const ApiRequest = async (method, url, data = null, requiresAuth = false, status = false) => {
  try {
    const token = getCookie("authToken");
    const config = {
      method, url, data,
      headers: {
        ...((requiresAuth && token) && { 'Authorization': `Bearer ${token}` }),
        'ContentType': 'application/json'
      },
      

    };
    const response = await axiosInstance(config);
    return status ? response : response?.data;
  } catch (error) {
    return { error: true, message: 'Erro, tente novamente mais tarde.' };
  }
};



export const ApiRequestCotacao = async (method, url, data = null) => {
  try {
    const token = getCookie("authToken");
    const config = {
      method, url, data,
      headers: {
        'Authorization': `Bearer ${token}`,
        'ContentType': 'application/json'
      },

    };
    const response = await axiosInstanceCotacao(config);
    return response
  } catch (error) {
    return { error: true, message: 'Erro, tente novamente mais tarde.' };
  }
};