import { useEffect, useRef, useState } from "react";
import { Modal, Row } from "reactstrap";
import { Api } from "../../../api/apiUsuario";
import React from "react";
import { Col, Input, Label, UncontrolledAlert } from "reactstrap";
import { BiBook, BiBookOpen, BiCollapse, BiEdit, BiShow, BiWrench, BiHide } from "react-icons/bi";
import FileInputComponent from "../../components/buttonFile";
import { ValidacaoEmail } from "../Validacao/email";
import { ValidacaoCelular } from "../Validacao/celular";

import { FaEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { baseUrlApi } from "../../../api/baseUrl";
import { ExitModal } from "../../components/exitModal";
import { useCookies } from "react-cookie";
import { Endereco } from "./enderenco";
import { Dados } from "./dados";
import { Foto } from "./foto";
import { Senha } from "./senha";

export const MeusDados = ({ setModalDados, modalDados }) => {


    const [email, setEmail] = useState();
    const [celular, setCelular] = useState('');
  

    const [modalEmail, setModalEmail] = useState(false);


    const [tipo, setTipo] = useState([true, false, false])
    const handleTipo = (tipo) => {
        if (tipo == 1) {
            setTipo([true, false, false, false])
        }
        if (tipo == 2) {
            setTipo([false, true, false, false])
        }
        if (tipo == 3) {
            setTipo([false, false, true, false])
        }
        if (tipo == 4) {
            setTipo([false, false, false, true])
        }
    }
    return (
        <>
            <Modal id="modal-center3" size="lg" centered={true} isOpen={modalDados} >

                <div className="modal-header" style={{ border: 'none' }} >
                    <ExitModal setModal={setModalDados} />

                </div>
                <div className="modal-body" style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 15, paddingTop: 8, minHeight: 450 }} >

                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                        <button className={`btn btn${tipo[0] ? '-primary' : ''}`} onClick={() => handleTipo(1)} style={{ width: 150, border: (!tipo[0] ? '1px solid #ccc' : '') }}>Dados </button>
                        <button className={`btn btn${tipo[1] ? '-primary' : ''}`} onClick={() => handleTipo(2)} style={{ border: (!tipo[1] ? '1px solid #ccc' : ''), width: 150 }}>Endereço</button>
                        <button className={`btn btn${tipo[2] ? '-primary' : ''}`} onClick={() => handleTipo(3)} style={{ width: 150, border: (!tipo[2] ? '1px solid #ccc' : '') }}>Logomarca</button>
                        <button className={`btn btn${tipo[3] ? '-primary' : ''}`} onClick={() => handleTipo(4)} style={{ width: 150, border: (!tipo[3] ? '1px solid #ccc' : '') }}>Senha</button>

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '100%' }}>
                        {tipo[0] &&
                            <Dados />
                        }
                        {tipo[1] &&
                            <Endereco />
                        }
                        {tipo[2] &&
                            <Foto />
                        }
                        {tipo[3] &&
                            <Senha />
                        }





                        <div className="" style={{ width: '50%', textAlign: 'center', display: 'flex', justifyContent: 'center', position: 'absolute', bottom: 20, right: 200 }}>
                            <div className="mb-0" style={{ textAlign: 'center' }}>

                                <a className="text" style={{ marginLeft: 2, marginRight: 15, color: '#2278f3', textDecoration: 'underline' }}
                                    onMouseOver={(e) => { e.target.style.color = 'black'; e.target.style.textDecoration = 'none' }}
                                    onMouseOut={(e) => { e.target.style.color = '#2278f3'; e.target.style.textDecoration = 'underline' }} href="/termodeuso"
                                    target="_blank"  >
                                    Termos de Uso
                                </a> <a className="text" onMouseOver={(e) => { e.target.style.color = 'black'; e.target.style.textDecoration = 'none' }}
                                    onMouseOut={(e) => { e.target.style.color = '#2278f3'; e.target.style.textDecoration = 'underline' }} style={{ marginLeft: 2, marginRight: 2, color: '#2278f3', textDecoration: 'underline' }} href="/politicadeprivacidade" target="_blank" >
                                    Política de Privacidade
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>

    )
}