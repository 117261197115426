import React from "react";
import { BiBook, BiBookOpen, BiCollapse, BiEdit, BiShow, BiWrench } from "react-icons/bi";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { ImBin } from "react-icons/im";
import { useNavigate } from 'react-router-dom';
export const ListagemTabPre = ({ buscar, iltrar, list, permissoes, setBuscar, setModalExcluir, setModalVisualizarOperadora, setid, handleCalculo, handleLimpar, setModalEditar, handleDados, handleCarregamento, handleStatus, setModalAgendamento, setRamo }) => {
  const navigate = useNavigate();
  return (
    <div>
      <input
        placeholder="buscar..."
        className="form-control"
        name="buscar"
        value={buscar}
        onChange={(e) => {
          // filtrar(e.target.value);
          setBuscar(e.target.value);
        }}
      />
      <div
        className="table-responsive"
        style={{ marginTop: 20, minHeight: 250 }}
      >
        <table className="table" style={{ border: 'none' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'center', border: 'none' }}>Visualizar</th>
              <th style={{ textAlign: 'center', border: 'none' }}>Ramo</th>
              <th style={{ textAlign: 'center', border: 'none' }}>Nome do Cliente</th>
              <th style={{ textAlign: 'center', border: 'none' }}>Fase da Negociação</th>
              <th style={{ textAlign: 'center', border: 'none' }}>Agendamentos</th>

            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((item, index) => (
                <tr key={index}>

                  <td style={{ textAlign: 'center' }}>
                    <a
                      // href={`/cotacoesprecos/${item.token}`} rel="noreferrer" target="_blank"
                      onClick={() => {
                        navigate(`/cotacoesprecos/${item.token}`)
                      }}
                    >  <BiShow /></a>

                  </td>

                  <td style={{ textAlign: 'center' }}>{item.tipooperadora?.nome} </td>
                  <td style={{ textAlign: 'center' }}>{item.descricao} </td>

                  <td>
                    <select
                      className="form-control"
                      defaultValue={item.status}
                      onChange={(e) => {
                        handleStatus(item.id, e.target.value);
                      }}
                    >
                      <option>Selecione</option>
                      <option value="1">Enviado para cliente</option>
                      <option value="2">Cliente analisando</option>
                      <option value="3">Cliente interessado</option>
                      <option value="4">Contrato fechado</option>
                      <option value="5">Sem interesse</option>
                    </select>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <button className="btn btn-default" style={{ fontSize: 14, lineHeight: 1 }} onClick={() => { setid(item.id); setModalAgendamento(true); }}>
                      {item?.agendamentos?.length > 0 ?
                        <>   {item?.agendamentos?.length}</>
                        :
                        <>Criar <br />Agendamento</>
                      }
                    </button>{" "}
                  </td>

                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
