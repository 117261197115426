import useToggle from "../../../../hooks/useToggle";
import Drawer from "../menu";
import React, { useEffect, useState } from "react";
import boneco from "../../../../assets/images/icones/boneco.png";
import { Link } from "react-router-dom";
import { Login } from "../../../Auth/Login";
import { MeusDados } from "../../../Auth/MeusDados";
import { googleLogout } from '@react-oauth/google';
import logoAqui from "../../../../assets/images/sualogoaqui.png";
import { GoGear } from "react-icons/go";
import { RxExit } from "react-icons/rx";
import { FiMenu } from "react-icons/fi";
import bonecoEditar from "../../../../assets/images/icones/editar.png";
import { baseUrlApi, baseUrlApp } from "../../../../api/baseUrl";
import { useCookies } from "react-cookie";
import logomarca from "../../../../assets/images/logos/logo.png";
import { Api as apiAuthentica } from "../../../../api/apiAuthentica";
import { Api } from "../../../../api/apiDominios"
import { useNavigate } from "react-router-dom";
import { getRootDomain } from "../../../components/getRootDomain";
import { getCookie, removeCookie, saveCookie } from "../../../../components/cookies";
export default function MobileHeaderCom({ className }) {
  const [drawer, setValue] = useToggle(false);
  const [modalLogin, setModalLogin] = useState(false)
  const [modalDados, setModalDados] = useState(false)
  const [msgSessao, setMsgSessao] = useState('')
  const [logo, setLogo] = useState('')
  const [loading, setLoading] = useState(false)
  const [obj, setObj] = useState()
  const cookie = getCookie('authUser')
  const [refresh, setRefresh] = useState(0);
  useEffect(() => {
    setObj(cookie)
  }, [refresh])


  const navigate = useNavigate();
  const handleLink = async () => {
    const token = getCookie("authToken");
    let response = await apiAuthentica.Auth()
    if (response.status == 401) {
      setMsgSessao('A sessão expirou por inatividade.<br/> Entre novamente no sistema')
      setModalLogin(true)
    } else {
      window.open(`${baseUrlApp}/login/${obj?.id}/${token}`, "_blank");
    }
  }
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const handleDominio = async () => {
    setLoading(true)
    let response = await Api.listagem({ search: getRootDomain(window.location.hostname) });
    if (!response?.error && response.find) {
      let list = response.find[0]
      setLogo(list?.imagem)
    }
    setLoading(false)
  };
  useEffect(() => {
    handleDominio()
    setMsgSessao('')
  }, [])
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [drawer]);
  function capitalizeFirstLetter(string) {
    if (string.length === 0) return string;
    let PrimeiraLetra = string[0]?.toUpperCase()
    let OutrasLetra = string.slice(1).toLowerCase()

    return PrimeiraLetra + OutrasLetra
  }
  return (
    <div key={refresh}>
      <Drawer drawer={drawer} action={setValue.toggle} />
      <div className={`mobile-header d-block `} style={{ padding: 0 }} >
        <div className="d-flex justify-content-between" style={{ alignItems: "center", height: "100%" }}>
          <div className="button-area" style={{ display: 'flex', justifyContent: isDesktop ? "" : "space-between", alignItems: 'center', width: '100%' }} >
            <div style={{ display: 'flex' }}>
              <a className="logo-area" style={{ display: 'flex', justifyContent: 'space-between', marginRight: 10 }}>
                <FiMenu size={30} color={'#0639A4'} style={{ cursor: 'pointer' }} onClick={setValue.toggle} />
              </a>

              {!loading &&
                <>
                  {logo ?
                    <a onClick={() => {
                      navigate('/')
                    }} className="logo-area" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <img src={`${baseUrlApi}/imagens/${logo}`} />
                    </a>
                    :
                    <a onClick={() => {
                      navigate('/')
                    }} className="logo-area" style={{ display: 'flex', justifyContent: 'space-between', marginRight: 10 }}>
                      <img src={logomarca} width={120} />
                    </a>
                  }
                </>

              }


            </div>

            {obj?.id &&
              (obj?.foto ? <img src={`${baseUrlApi}/imagens/${obj?.foto}`} style={{ width: "65px", marginRight: 9, marginLeft: 20 }} onClick={(e) => {
                e.preventDefault()
                setModalDados(true)
              }} /> :
                <img src={logoAqui} width={"100px"} style={{ width: "100px", marginRight: 9, marginLeft: 20 }} onClick={(e) => {
                  e.preventDefault()
                  setModalDados(true)
                }} />
              )

            }
          </div>
          {(isDesktop ? true : (obj?.id ? false : true)) &&


            <a className="logo-area" style={{ verticalAlign: 'center', textDecoration: 'none', fontSize: 12, width: 200 }}>


              <div className={`restly-header-main-menu ${className || ""}`} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }} >

                {!obj?.nome &&
                  <ul style={{ display: 'flex', justifyContent: 'center' }}>

                    <li>
                      <Link onClick={() => {
                        saveCookie('authPagehist', '/ferramentas')
                        setModalLogin(true)
                      }} style={{ width: "100%", fontSize: 14 }} >
                        <><img src={boneco} style={{ width: isDesktop ? 15 : 24, marginRight: 2, marginBottom: 5 }} /> {isDesktop && "Área do Corretor"} </>
                      </Link>
                    </li>
                  </ul>
                }
                {obj?.nome &&

                  <ul style={{ textAlign: 'center' }}>

                    <li>
                      <Link to="#"  >
                        <p style={{ textAlign: 'center', width: '100%' }} >   <img src={boneco} style={{ width: 15, marginRight: 2, marginBottom: 5 }} />  Olá, <span style={{ textTransform: 'capitalize' }}>{capitalizeFirstLetter(obj?.nomefantasia ? obj?.nomefantasia.split(' ')[0] : obj?.nome.split(' ')[0])}</span>{" "}<i className="fa fa-angle-down" /></p>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <a
                            onClick={() => {
                              setModalDados(true);
                            }}
                            style={{
                              textAlign: 'left', color: '#222222', display: 'block', padding: '0px 15px', position: 'relative', color: '#505056', borderRadius: '4px', margin: '0 0', lineHeight: 2.5, textAlign: 'left'
                            }}
                          >
                            <img src={bonecoEditar} style={{ width: 15, marginBottom: 4, marginRight: '4px' }} />
                            Meu Perfil
                          </a>
                        </li>
                        {obj?.permissao != 5 &&
                          <li >
                            <a onClick={handleLink}
                              style={{
                                textAlign: 'left', color: '#222222', display: 'block', padding: '0px 15px', position: 'relative', color: '#505056', borderRadius: '4px', margin: '0 0', lineHeight: 2.5, textAlign: 'left'
                              }} target="_blank" >
                              <GoGear size={15} style={{ marginRight: 2 }} color="#5c5c5c" />
                              Gerenciar
                            </a>
                          </li>

                        }
                        <li>
                          <a onClick={() => {
                            removeCookie('authUser')
                            removeCookie('authToken')
                            navigate('/')
                            setRefresh(prev => prev + 1);
                       
                          }}
                            style={{
                              textAlign: 'left', color: '#222222', display: 'block', padding: '0px 15px', position: 'relative', color: '#505056', borderRadius: '4px', margin: '0 0', lineHeight: 2.5, textAlign: 'left'
                            }}
                          >
                            <RxExit size={15} style={{ marginRight: 4 }} color="#5c5c5c" />
                            Sair
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>

                }

              </div>

            </a>
          }
        </div >
      </div >
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} msgSessao={msgSessao} />
      <MeusDados modalDados={modalDados} setModalDados={setModalDados} />
    </div>
  );
}
