import axios from 'axios'
import { baseUrlApi } from './baseUrl'
const axiosInstance = axios.create({
    baseURL: `${baseUrlApi}/municipios`,

    validateStatus: (status) => true
})
export const Api = {
    listagem: async (dados) => {
        let response = await axiosInstance.get(`/${dados?.idEstado || ""}?idTipoOperadora=${dados?.idTipoOperadora || ""}`)
        return response.data
    }
}