import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../../api/apiSite";
import background from "../../../assets/images/background/progress.png";
import SliderCom from "../../../components/helpers/SliderCom";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { Col, Row } from "reactstrap";
import { baseUrlApi } from "../../../api/baseUrl";

export default function Operadoras({ list }) {


  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 1000,
    slidesToShow: list?.length <= 4 ? list?.length : 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: { slidesToShow: 4 }
      },
      {
        breakpoint: 767,
        settings: { slidesToShow: 3 }
      },
      {
        breakpoint: 575,
        settings: { slidesToShow: 2 }
      }
    ]
  };
  const sliderRef = useRef(null);
  const prevHandler = () => sliderRef.current.slickPrev();
  const nextHandler = () => sliderRef.current.slickNext();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [list]);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredVerMais, setIsHoveredVerMais] = useState(false);
  const handleMouse = () => {
    setIsHoveredVerMais(!isHoveredVerMais);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const textStyle = {
    textDecoration: isHovered ? 'underline' : 'none',
    cursor: 'pointer'
  };
  const textStyleVerMais = {
    textDecoration: isHoveredVerMais ? 'underline' : 'none',
    cursor: 'pointer'
  };
  return (
    <section >
      <div className="" style={{ textAlign: 'center', marginTop: '-20px', marginBottom: 0 }}>
        <Row style={{ display: 'flex',justifyContent:'center',alignItems:'center' }} >
          {list && list.map((item) => (
            <Col style={{ flex: '0 0 210px' }} >
              {item?.imagem &&
                <div className="logo-item">
                  <a href={`/${item.subdominio}`}>
                    <img src={`${baseUrlApi}/imagens/${item?.imagem}`} width={150} />
                  </a>
                </div>
              }
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
}
