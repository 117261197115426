import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { Api } from "../../api/apiCotacoes";
import { Agendamentos } from "./utils/agendamentos";
import Agendar from "./utils/agendar";
import { Cadastro } from "./utils/cadastro";
import Editar from "./utils/editar";
import { Excluir } from "./utils/excluir";
import { Filtros } from "./utils/filtros";
import { ListagemTabPre } from "./utils/listagem";

import { GestorModal } from "../components/modal";
import { Api as ApiSite } from "../../api/apiSite";
function AboutSection() {
  document.title = "Corretor Parceiro";
  const [ramo, setRamo] = useState("");
  useEffect(() => {
    var url = window.location.href;
    url = url.split("/");
    if (url.includes("saude")) {
      setRamo("2");
    } else if (url.includes("odontologico")) {
      setRamo("1");
    }
  }, []);

  const [resposta, setResposta] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [dados, setDados] = useState();
  const [info, setInfo] = useState();
  const [estados, setEstados] = useState([]);
  const [areaComercializacao, setAreaComercializacao] = useState([]);
  const [modalVisualizarDetalhe, setModalVisualizarDetalhe] = useState(false);
  const [profissoes, setProfissoes] = useState();
  const [profissoesSelect, setProfissoesSelect] = useState();
  const [salvarVisualizar, setSalvarVisualizar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setid] = useState("");
  const [modalExcluir, setModalExcluir] = useState(false);
  const [modalVisualizarOperadora, setModalVisualizarOperadora] =
    useState(false);
  const [buscar, setBuscar] = useState("");
  const [list, setList] = useState([]);



  const [editar, setEditar] = useState(false);
  const [itens, setItens] = useState([{}, {}]);
  const [faixas, setFaixas] = useState([]);
  const [calculo, setCalculo] = useState([]);
  const [calculoDetalhe, setCalculoDetalhe] = useState([]);
  const [infoDependente, setInfoDependente] = useState([]);
  const [infoSelecionado, setInfoSelecionado] = useState({});
  const [
    modalVisualizarDetalheSelecionado,
    setModalVisualizarDetalheSelecionado
  ] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalFiltro, setModalFiltro] = useState(false);
  const [modalAgendar, setModalAgendar] = useState(false);
  const [modalAgendamento, setModalAgendamento] = useState(false);
  const [municipios, setMunicipios] = useState([]);
  const [filtro1, setFiltro1] = useState("");
  const [filtro2, setFiltro2] = useState("");
  const [filtro3, setFiltro3] = useState("");
  const [filtro4, setFiltro4] = useState("");
  const [filtro5, setFiltro5] = useState("");
  const [filtro6, setFiltro6] = useState("");

  useEffect(() => {
    Listagem();

    handleProfissoes();
    handleFaixaValue();
    handleAreaComercializacao();
    handleMunicipios();

  }, []);

  const handleMunicipios = async () => {
    let option = await Api.municipio();
    setMunicipios(option?.option);
  };
  const handleCarregamento = (status) => {
    let body = document.querySelector("body");
    if (status) {
      if (body) {
        body.style.cursor = "wait";
      }
    } else {
      if (body) {
        body.style.cursor = "";
      }
    }
  };
  const handleStatus = async (id, status) => {
    let list = await Api.status({ id: id, status });
    setList(list.list);
  };
  const handleAreaComercializacao = async () => {
    let dados = await Api.areacomercializacao();
    setAreaComercializacao(dados?.option);
  };
  const handleEstados = async () => {
    setLoading(true)
    if (validation.values.tipoOperadora) {
      let option = await Api.estados({ tipoOperadoras: validation.values.tipoOperadora });
      setEstados(option);
    }
    setLoading(false)
  };

  const handleProfissoes = async () => {
    let dados = await Api.profissoes();
    console.log('handleProfissoes',{dados})
    let val = dados?.option;
    val?.unshift({ nome: "Sem profissão", id: "" });
    val?.unshift({ nome: "Todas as profissões", id: "*" });
    setProfissoes(val);
    setProfissoesSelect([]);
  };
  const handleModifica = (index, val) => {
    let item = itens;
    if (item[index] || item[index] == "") {
      item[index] = val;
    } else {
      item.push(val);
    }
    setItens(item);
    setEditar(!editar);
  };
  const handleAdd = (val) => {
    let item = [...itens];
    item.push({});
    setItens(item);
  };
  const handleExit = (index) => {
    let item = [...itens];
    item.splice(index, 1);
    setItens(item);
    setEditar(!editar);
  };
  const handleFaixasValue = (value, index) => {
    let faixa = faixas;
    faixa[index].value = value;
    setFaixas(faixa);
    setEditar(!editar);
  };
  const handleFaixaValue = async () => {
    let list = await Api.faixas({ id: 8 });
    if(list){
      let listFaixas = list
    
      setFaixas(listFaixas);
    }
   
  };
  const handleTipoValue = async (dados) => {
    handleModifica(dados.item, dados.val);
  };
  const Listagem = async () => {
    handleCarregamento(true);
    let dados = await Api.listagem();
    setList(dados?.list);
    handleCarregamento(false);
  };
  const handleExcluir = async () => {
    handleCarregamento(true);
    let list = await Api.delete({ id: id });
    setList(list);
    setModalExcluir(false);
    setid("");
    handleCarregamento(false);
  };
  const handleFiltro = async () => {
    handleCalculo(id);
    setModalFiltro(false);
  };
  const handleCalculo = async (id) => {
    handleCarregamento(true);
    let dados = await Api.calculo({
      id: id,
      filtro1,
      filtro2,
      filtro3,
      filtro4,
      filtro5,
      filtro6
    });
    setid(id);
    setCalculo(dados?.list?.list);
    setModalVisualizarOperadora(true);
    let val = dados.list.list.map((item) => {
      return {
        operadora: item.nome,
        valores: item.tabelas.map((item2) => {
          return item2.tabelafaixas.map((item3) => {
            return item3.valor;
          });
        })
      };
    });
    let val2 = val.map((item) => {
      return {
        operadora: item.operadora,
        valores: item.valores.flat().concat()
      };
    });
    let val3 = val2.map((item) => {

      return {
        operadora: item.operadora,
        valores: item.valores.map((valor) => {
          return parseFloat(valor?.replace(",", "."));
        })
      };
    });

    let val4 = val3.map((item) => {
      return {
        operadora: item.operadora,
        valores: [Math.max(...item.valores), Math.min(...item.valores)]
      };
    });
    let valoresMaximos = [];
    let valoresMinimos = [];
    let valores = [];
    let operadoraValorMaximo;
    let operadoraValorMinimo;
    let operadoraValoIntermediario;
    let meio = 0;
    let valorDoMeio = 0;
    let valorMaximo = 0;
    let valorMinimo = 0;
    let operadoras = []
    if (
      val3.length > 0 &&
      val4.length > 0 &&
      dados.list.cotacao.info.ramo != "1"
    ) {
      for (let i = 0; i < val3.length; i++) {
        valores.push(...val3[i].valores);
        operadoraValoIntermediario = val3[i].operadora;
      }
      for (let i = 0; i < val4.length; i++) {
        valoresMaximos.push(Math.max(...val4[i].valores));
        valoresMinimos.push(Math.min(...val4[i].valores));
      }

      valores.sort((a, b) => a - b); // ordena o array em ordem crescente
      meio = Math.floor(valores.length / 2);
      valorDoMeio = valores[meio];
      valorMaximo = valores[valores.length - 1];
      valorMinimo = valores[0];
      for (let i = 0; i < val4.length; i++) {

        if (Math.max(...val4[i].valores) === valorMaximo) {
          operadoraValorMaximo = val4[i].operadora;
        }
        if (Math.min(...val4[i].valores) === valorMinimo) {
          operadoraValorMinimo = val4[i].operadora;
        }
      }
    } else {
      let val = dados.list.list.map((item) => {
        return {
          operadora: item.nome,
          valores: item.tabelas.map((item2) => {
            return item2.precovida;
          })
        };
      });
      let val2 = val.map((item) => {
        return {
          operadora: item.operadora,
          valores: item.valores.flat().concat()
        };
      });
      let val3 = val2.map((item) => {
        return {
          operadora: item.operadora,
          valores: item.valores.map((valor) => {
            return parseFloat(valor.replace(",", "."));
          })
        };
      });
      let val4 = val3.map((item) => {
        return {
          operadora: item.operadora,
          valores: [Math.max(...item.valores), Math.min(...item.valores)]
        };
      });
      for (let i = 0; i < val3.length; i++) {
        valores.push(...val3[i].valores);
        operadoraValoIntermediario = val3[i].operadora;
      }
      for (let i = 0; i < val4.length; i++) {
        valoresMaximos.push(Math.max(...val4[i].valores));
        valoresMinimos.push(Math.min(...val4[i].valores));
      }
      valores.sort((a, b) => a - b); // ordena o array em ordem crescente
      meio = Math.floor(valores.length / 2);
      valorDoMeio = valores[meio];
      valorMaximo = Math.max(...valoresMaximos);
      valorMinimo = Math.min(...valoresMinimos);
      for (let i = 0; i < val4.length; i++) {
        operadoras.push(val4[i].operadora)
        operadoras.push(val4[i].operadora)
        if (Math.max(...val4[i].valores) === valorMaximo) {
          operadoraValorMaximo = val4[i].operadora;
        }
        if (Math.min(...val4[i].valores) === valorMinimo) {
          operadoraValorMinimo = val4[i].operadora;
        }
      }
    }

    valores = valores.filter((item) => item)
    valorDoMeio = valores[meio];
    valorMaximo = valores[valores.length - 1];
    valorMinimo = valores[0];

    meio = Math.floor(operadoras.length / 2);


    operadoraValorMaximo = operadoras[operadoras.length - 1];
    operadoraValoIntermediario = operadoras[meio]
    operadoraValorMinimo = operadoras[0]
    setCalculoDetalhe({
      operadoraValorMaximo: operadoraValorMaximo,
      operadoraValorMinimo: operadoraValorMinimo,
      operadoraValoIntermediario: operadoraValoIntermediario,
      valorMaximo: valorMaximo,
      valorMinimo: valorMinimo,
      valorDoMeio: valorDoMeio,
      id: dados.list.cotacao.id,
      vidas: dados.list.cotacao.vidas,
      nome: dados.list.cotacao.nome,
      nomeempresa: dados.list.cotacao.empresa,
      estado: dados.list?.cotacao?.info?.estado?.nome
    });
    handleCarregamento(false);
  };

  const filtrar = async (nome) => {
    handleCarregamento(true);
    setLoading(true);
    let list = await Api.filtro({ nome: nome });
    setList(list.list);
    setLoading(false);
    handleCarregamento(false);
  };
  const handleDados = async (id) => {
    handleCarregamento(true);
    let dados = await Api.dados({ id: id });
    setDados({
      tipo: dados.list.tipo,
      ramo: dados.list.ramo,
      descricao: dados.list.descricao,
      municipio: dados.list.municipio,
      email: dados.list.email,
      telefone: dados.list.telefone,
      estado: dados.list.idestado,
      profissao: dados.list.idprofissao,
      titular: dados.list.titular,
      nomeempresa: dados.list.nomeempresa,
      tipocnpj: dados.list.tipocnpj,
      dependentes: dados.list.dependentes,
      areacomercializacao: dados.list.idareacomercializacao
    });


    dados.list?.cotacoesfaixas?.forEach((elemento2) => {
      const elemento1 = newFaixa?.find(
        (elemento1) => elemento1.id === elemento2.idfaixa
      );
      if (elemento1) {
        elemento1.value = elemento2.valor;
      }
    });
    if (faixas) {
      let newFaixa = faixas;
      setFaixas(newFaixa);
    }

    let val1 = dados.list?.cotacoesdependentes?.map((item) => {
      return item.dependente;
    });
    setItens(val1);
    handleCarregamento(false);

  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "" || id,
      tipo: dados?.tipo ? dados.tipo : "1",
      tipoOperadora: dados?.tipoOperadora ? dados.tipoOperadora : "",
      descricao: dados?.descricao ? dados.descricao : "",
      municipio: dados?.municipio ? dados.municipio : "",
      email: dados?.email ? dados.email : "",
      telefone: dados?.telefone ? dados.telefone : "",
      estados: dados?.estado ? dados.estado : "",
      profissao: dados?.profissao ? dados.profissao : "",
      titular: dados?.titular ? dados.titular : "",
      nomeempresa: dados?.nomeempresa ? dados.nomeempresa : "",
      tipocnpj: dados?.tipocnpj ? dados.tipocnpj : "",
      dependentes: dados?.dependentes ? dados.dependentes : "",
      faixas: null,
      dependentesarray: null
    },

    onSubmit: async (values) => {
      // console.log(values.profissao?.value)
      values.profissao = (`${values.profissao?.value}`)?.replace("*", "")

      values.ramo = [1, 5, 6].includes(values.tipoOperadora) ? 1 : 2
      handleCarregamento(true);
      setLoading(true);
      values.faixas = faixas;
      values.dependentesarray = itens;
      if (id) {
        let json = await Api.editar(values);
        if (json.error) {
          setError(true);
          setResposta(true);
          setMsg(json.msg);
        } else {
          Listagem();
          setBuscar("");
          handleLimpar(true);
          setModalEditar(false);
          window.open(`/cotacoesprecos/${json.id}`, '_blank');
        }
        setLoading(false);
      } else {
        let json = await Api.cadastro(values);
        if (json.error) {
          setError(true);
          setResposta(true);
          setMsg(json.msg);
        } else {
          setError(false);
          setResposta(true);
          setMsg(json.msg);
          handleLimpar(false);
          Listagem();
          window.open(`/cotacoesprecos/${json.id}`, '_blank');
        }
        setLoading(false);
      }
      document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
      handleCarregamento(false);
    }
  });

  const handleLimpar = (status = false) => {
    if (status) {
      setResposta(false);
    }
    validation.values.cnpj = "";
    validation.values.descricao = "";
    validation.values.municipio = "";
    validation.values.email = "";
    validation.values.telefone = "";
    validation.values.estados = "";
    validation.values.profissao = "";
    validation.values.titular = "";
    validation.values.nomeempresa = "";
    validation.values.tipocnpj = "";
    validation.values.dependentes = "";
    validation.values.areacomercializacao = "";
    validation.values.faixas = null;
    validation.values.dependentesarray = null;
    setItens([{}, {}, {}, {}, {}]);
    setInfoDependente([]);
    setInfoSelecionado({});
    setSelecteds([]);
    setid("");
    setDados({
      tipo: "1",
      ramo: "",
      descricao: "",
      municipio: "",
      email: "",
      telefone: "",
      estado: "",
      profissao: "",
      titular: "",
      nomeempresa: "",
      tipocnpj: "",
      dependentes: "",
      areacomercializacao: ""
    });
    let newFaixa = faixas;
    if (newFaixa) {
      newFaixa?.forEach((elemento2) => {
        elemento2.value = "";
      });
    }
    setFaixas(newFaixa);
  };
  const [selecteds, setSelecteds] = useState([]);

  const [area, setArea] = useState(1);
  useEffect(() => {
    var url = window.location.href;
    url = url.split("/");
    if (url.includes("minhascotacoes")) {
      setArea(2);
    }
  }, []);
  useEffect(() => {
    handleEstados()
  }, [validation.values.tipoOperadora])
  const [gestorModal, setGestorModal] = useState()
  const handleModal = async () => {
    var url = window.location.href;
    url = url.split("/");
    if (url.includes('saude')) {
      let list = await ApiSite.gestorModal('Multicalculo-Saude');
      setGestorModal(list.dados)
    } else if (url.includes('odonto')) {
      let list = await ApiSite.gestorModal('Multicalculo-Odonto');
      setGestorModal(list.dados)
    }
  };
  useEffect(() => {
    handleModal()
  }, [])
  useEffect(() => {
    handleLimpar(true);
  }, [validation.values.tipoOperadora])
  return (
    <section className="about-two  rpt-80 pb-105 rpb-100">
      <div className="container" >
        <div className="" style={{ width: "100%", marginTop: '-50px' }}>
          <div className="row">
            <div className="col-lg-12 ">
              <div
                className="about-content pt-100 rpt-20 rpr-0 wow fadeInRight delay-0-2s"

              >
                <div className="section-title mb-35">
                  <h2 style={{ fontSize: 20, fontWeight: 100, textAlign: 'center' }}>
                    {(`Faça cotações para seus clientes`).toLocaleUpperCase()}
                  </h2>
                </div>
                <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center', flexDirection: 'column' }} >

                  <div style={{ marginBottom: 30 }} >

                    <button style={{ float: "right", border: "none" }} className={area == 2 ? "btn btn-primary" : "btn"} onClick={() => { setArea(area == 1 ? 2 : 1); }} >
                      {"Histórico"}
                    </button>
                    <button style={{ float: "right", border: "none" }} className={area == 1 ? "btn btn-primary" : "btn"} onClick={() => { setArea(area == 1 ? 2 : 1); }} >
                      {"Gerar cotação"}
                    </button>
                  </div>
                </div>
                {area == 1 && (
                  <Form className="custom-form" encType='multipart/form-data'
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>

                    <Cadastro setResposta={setResposta} handleAdd={handleAdd} handleExit={handleExit} handleTipoValue={handleTipoValue} itens={itens} faixas={faixas}
                      handleFaixasValue={handleFaixasValue} error={error} estados={estados} msg={msg} profissoes={profissoes} resposta={resposta} setSalvarVisualizar={setSalvarVisualizar}
                      validation={validation} loading={loading} areaComercializacao={areaComercializacao} setLoading={setLoading} />
                  </Form>
                )}
                {area == 2 && (
                  <>
                    <ListagemTabPre handleStatus={handleStatus} handleCarregamento={handleCarregamento} buscar={buscar} filtrar={filtrar} list={list} setBuscar={setBuscar} setModalExcluir={setModalExcluir} setModalVisualizarOperadora={setModalVisualizarOperadora} setid={setid} handleCalculo={handleCalculo} handleLimpar={handleLimpar} handleDados={handleDados} setModalEditar={setModalEditar} setModalAgendar={setModalAgendar} setModalAgendamento={setModalAgendamento} setRamo={setRamo} />
                  </>
                )}
                <Excluir handleCarregamento={handleCarregamento} handleExcluir={handleExcluir} modalExcluir={modalExcluir} setModalExcluir={setModalExcluir} />



                <Editar handleCarregamento={handleCarregamento} dados={dados} areaComercializacao={areaComercializacao} error={error} estados={estados} faixas={faixas} handleAdd={handleAdd} handleExit={handleExit} handleFaixasValue={handleFaixasValue} handleLimpar={handleLimpar} handleTipoValue={handleTipoValue} itens={itens} loading={loading} modalEditar={modalEditar} msg={msg}
                  profissoes={profissoes}
                  resposta={resposta}
                  setModalEditar={setModalEditar}
                  setResposta={setResposta}
                  setSalvarVisualizar={setSalvarVisualizar}
                  validation={validation}
                />
                <Filtros filtro1={filtro1} filtro2={filtro2} filtro3={filtro3} filtro4={filtro4} filtro5={filtro5} filtro6={filtro6} municipios={municipios} handleCarregamento={handleCarregamento} modalFiltro={modalFiltro} setModalFiltro={setModalFiltro} setFiltro1={setFiltro1} setFiltro2={setFiltro2} setFiltro3={setFiltro3} setFiltro4={setFiltro4} setFiltro5={setFiltro5} setFiltro6={setFiltro6} handleFiltro={handleFiltro} ramo={ramo} />

                <Agendamentos handleLimpar={handleLimpar} modalAgendamento={modalAgendamento} setModalAgendamento={setModalAgendamento} id={id} setModalAgendar={setModalAgendar} modalAgendar={modalAgendar} setid={setid} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <GestorModal dados={gestorModal} />
    </section>
  );
}

export default AboutSection;
