import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useToggle from "./hooks/useToggle";
import Routers from "./Routers";
import React from "react";
import { LoadingProvider, useLoading } from "./components/loading/request";
import { CookieProvider } from "./components/cookies";


const LoadingIndicator = () => {
  const { loading } = useLoading();
  return loading && <div className="fixed top-0 left-0 w-full h-2 bg-green-500"></div>;
};
function App() {
  const { rtl } = useSelector((state) => state.rtl);
  const html = document.getElementsByTagName("html");

  useEffect(() => {
    if (rtl) {
      html[0].dir = "rtl";
    } else {
      html[0].dir = "";
    }
  }, [rtl]);

  return (

    <LoadingProvider>
      <LoadingIndicator />
      <div className={rtl ? "direction-rtl" : ""}>
        <Routers />
      </div>
    </LoadingProvider>
  );
}

export default App;
