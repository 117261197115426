import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api/apiSite";
import Mobile from "./utils/mobile";
import Desktop from "./utils/desktop";
import Icone from "../../assets/icones/icone.png"
import { Api as ApiSite } from "../../api/apiSite";
import { GestorModal } from "../components/modal";
import { useCookies } from 'react-cookie';

function FeatureSection(val) {
  const [datas, setDatas] = useState({});
  const [cookie, setCookie] = useCookies(['authUser'])
  const obj = cookie.authUser
  const [estados, setEstados] = useState([])
  const [estadosSelect, setEstadosSelect] = useState('')
  useEffect(() => {
    handleEstados();
  }, [val?.plano])

  const handleEstados = async () => {
    // let value = await Api.estadosSaude()
    // setEstados(value.list)
  }
  const handleOperadora = async () => {
    setDatas({});
    let list = await Api.fornecedorRamos();

    let valores = list.list.map((item) => {
      return { id: item.id, title: item.nome, image: item.imagem, tipos: item.tiposoperadoras.map((type) => { return { id: type.tipooperadora.id, nome: type.tipooperadora.nome, imagem: type.tipooperadora.imagem, icone: type.tipooperadora.icone } }), filter: 'fornecedor' };
    });

    // let listagem = await Api.ramosFornecedor();
    let valoresw = []
    valores.map((item) => {
      item.tipos.map((type) => {
        if (!valoresw.some((som) => som.id == type.id)) {
          valoresw.push({ id: type.id, title: type.nome, image: type.icone, tipos: [item], filter: 'ramo' })
        } else {
          let index = valoresw.findIndex((find) => find.id == type.id)
          let tiposOperara = valoresw[index]?.tipos
          if (!tiposOperara.some((som) => som.id == item.id)) {
            valoresw[index].tipos.push(item)
          }
        }

      })


    });
    setDatas({ valores: [...valoresw, ...valores] });
  };

  useEffect(() => {

    handleOperadora()
  }, [val?.plano, estadosSelect, setEstadosSelect]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [val?.plano]);
  const [gestorModal, setGestorModal] = useState()
  const handleModal = async () => {
    let list = await ApiSite.gestorModal('Tabela');
    setGestorModal(list.dados)

  };
  useEffect(() => {
    handleModal()
  }, [])
  return (
    <section className="featured-section bgs-cover pt-20 rpt-150 pb-20 rpb-100" style={{ minHeight: 700 }}>
      <div className="container">
        {!datas.valores ? (
          <></>
        ) : (
          <>
            {(isDesktop)
              ? <Desktop datas={datas} />
              : <Mobile datas={datas} />}
          </>
        )}
        <div className="feature-btn text-center mt-20"></div>
      </div>
      <GestorModal dados={gestorModal} />
    </section>
  );
}
export default FeatureSection;
