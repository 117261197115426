import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Info from "./info";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { baseUrlApi } from "../../../api/baseUrl";
export default function DesktopVisualizar({ datas }) {


  const [produtos, setProdutos] = useState([]);
  const [produtosFiltrados, setProdutosFiltrados] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
  const [fornecedoresFiltrados, setFornecedoresFiltrados] = useState([]);
  const [fisico, setFisico] = useState([]);
  const [juridico, setJuridico] = useState([]);
  const [item2, setItem] = useState("");

  const handleFiltro = () => {
    let fornecedor = datas.valores?.filter((item) => item.filter == "fornecedor");
    setFornecedoresFiltrados(fornecedor);
    setFornecedores(fornecedor);
    let produto = datas.valores?.filter((item) => item.filter == "ramo");
    setProdutosFiltrados(produto)
    setProdutos(produto);
  };
  useEffect(() => {
    handleFiltro();
  }, [datas.valores]);
  const handler = (e, value) => {
    e.preventDefault();
    const getItems = document.querySelectorAll(`#${value}`).length;
    if (getItems > 0) {
      if (item2 !== value) {
        setItem(value);
      } else {
        setItem("");
      }
    }
  };
  const [buscarProdutos, setBuscarProdutos] = useState('')
  const [buscarFornecedores, setBuscarFornecedores] = useState('')


  useEffect(() => {
    if (produtos.length > 0 && buscarProdutos) {
      let filtro = produtos.filter((a) =>
        a.title?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(buscarProdutos.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
      );
      setProdutosFiltrados(filtro)
    }
  }, [buscarProdutos])

  useEffect(() => {
    if (fornecedores.length > 0 && buscarFornecedores) {
      let filtro = fornecedores.filter((a) =>
        a.title?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(buscarFornecedores.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
      );
      setFornecedoresFiltrados(filtro)
    }
  }, [buscarFornecedores])

  return (
    <section className="portfolio-section  rpt-95 pb-90 rpb-70">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-xl-6">
            <input className="form-control" placeholder="Buscar Produtos" name={buscarProdutos} onChange={(e) => { setBuscarProdutos(e.target.value) }} style={{ marginBottom: 20 }} />
            <button className="btn btn-primary" style={{ width: "100%", backgroundColor: "#4180D6", border: "none", fontWeight: 100 }} >
              Produtos
            </button>
            {produtosFiltrados &&
              produtosFiltrados.length > 0 &&
              produtosFiltrados.map((item) => (
                <a
                  key={item.id}
                  className={`col-lg-4 col-sm-6 item `}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handler(e, "operadora" + item.id)}
                >
                  <div
                    className="feature-item   style-three "
                    style={{ marginBottom: 0, padding: 10 }}
                  >
                   <div className="feature-content style-three" style={{ marginBottom: 5 }}>
                      <Link className="menu-item-has-children active" style={{ fontFamily: "Open Sans, sans-serif", marginLeft: 10, fontSize: 15 }} onClick={(e) => handler(e, "operadora" + item.id)} id={"operadora" + item.id} >
                        <div style={{ float: 'left', width: '89%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' ,marginTop:2}}>
                          <img className="w-10"
                            style={{ width: "20px", marginRight: "5px", textAlign: 'left' }}
                            src={`${baseUrlApi}/imagens/${item.image}`} />
                          <a href="#" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.4, width: 450 }} >
                            {item.title} 
                          </a>
                        </div>
                        <div style={{ float: 'right' }}>
                          <span className="menu-expand">
                            {item2 == (`operadora${item?.id}`) ?
                              <FaAngleUp />
                              :
                              <FaAngleDown />
                            }
                          </span>
                        </div>
                      </Link>
                      <div
                        className="sub-menu"
                        style={{
                          display:
                            item2 == "operadora" + item.id ? "block" : "none"
                        }}
                      >

                        <Info tipos={item.tipos} identificacao={"fornecedores"} />
                      </div>
                    </div>
                  </div>
                </a>
              ))}
          </div>
          <div className="col-md-6 col-xl-6">
            <input className="form-control" placeholder="Buscar Fornecedores" name={buscarFornecedores} onChange={(e) => { setBuscarFornecedores(e.target.value) }} style={{ marginBottom: 20 }} />
            <button className="btn btn-primary" style={{ width: "100%", backgroundColor: "#4180D6", border: "none", fontWeight: 100 }} >
              Fornecedores
            </button>
            {fornecedoresFiltrados &&
              fornecedoresFiltrados.length > 0 &&
              fornecedoresFiltrados?.map((item) => (
                <a
                  key={item.id}
                  className={`col-lg-4 col-sm-6 item `}
                  style={{ cursor: "pointer", marginBottom: 10 }}
                >
                  <div
                    className="feature-item   style-three "
                    style={{ marginBottom: 0, padding: 10 }}
                  >
                    <div className="feature-content style-three" style={{ marginBottom: 5 }}>
                      <Link className="menu-item-has-children active" style={{ fontFamily: "Open Sans, sans-serif", marginLeft: 10, fontSize: 15 }} onClick={(e) => handler(e, "operadora" + item.id)} id={"operadora" + item.id} >
                        <div style={{ float: 'left', width: '89%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <img className="w-10"
                            style={{ width: "70px", marginRight: "5px", textAlign: 'left' }}
                            src={`${baseUrlApi}/imagens/${item.image}`} />
                          <a href="#" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.4, width: 450 }} >
                            {item.title} 
                          </a>
                        </div>
                        <div style={{ float: 'right' }}>
                          <span className="menu-expand">
                            {item2 == (`operadora${item?.id}`) ?
                              <FaAngleUp />
                              :
                              <FaAngleDown />
                            }
                          </span>
                        </div>
                      </Link>
                      <div className="sub-menu"  style={{ display: item2 == "operadora" + item.id ? "block" : "none"  }} >
                        <Info tipos={item.tipos} identificacao={"ramos"} />
                      </div>
                    </div>
                  </div>
                </a>
              ))}
          </div>

        </div>
        <div className="row align-items-center  portfolio-wrap"></div>
      </div>
    </section>
  );
}
