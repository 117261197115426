import React, { useEffect, useRef, useState } from "react";
import { Modal, Row, Col, Form } from "reactstrap";
import { ExitModal } from "../../components/exitModal";
import { Alert } from "reactstrap";
import { useCookies } from "react-cookie";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Api } from "../../../api/apiUsuario";
import InputMask from 'react-input-mask';
import { AlertResposta } from "../../../components/loading/alert";
export const ValidacaoCelular = ({ modal, setModal, celular, id, setProximo }) => {
  const [cookies, setCookie] = useCookies(['time']);
 
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  const [time, setTime] = useState(30);
  const [start, setStart] = useState(false);

  const tempoPrincipal = cookies.time
  const [resposta, setResposta] = useState(false);
  const handleTime = () => {
    let tempo1 = 1;
    let timer = setInterval(() => {
      let tempo = 0;

      if (tempoPrincipal > 0) {
        setTime(tempoPrincipal);
        tempo = tempoPrincipal - tempo1;
        tempo1 = tempo1 + 1;
        setTime(tempo);
        setCookie('time', JSON.stringify(tempo), { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
      } else {
        tempo = time - tempo1;
        tempo1 = tempo1 + 1;
        setTime(tempo);
        setCookie('time', JSON.stringify(tempo), { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
      }
      if (tempo == 0) {
        setStart(false);
        clearInterval(timer);
      }
    }, 1000);
  };
  useEffect(() => {
    if (start) {
      handleTime();
    }
  }, [start]);

  function handleInputChange(e) {
    const maxLength = e.target.maxLength;
    const currentLength = e.target.value.length;
    if (currentLength >= maxLength) {
      e.preventDefault();
      if (e.target === input1Ref.current) {
        input2Ref.current.focus();
      } else if (e.target === input2Ref.current) {
        input3Ref.current.focus();
      } else if (e.target === input3Ref.current) {
        input4Ref.current.focus();
      } else if (e.target === input1Ref.current) {
        input1Ref.current.focus();
      }
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().nullable().optional(),
      codigo1: Yup.string().required('Preencha o código completo.'),
      codigo2: Yup.string().required('Preencha o código completo.'),
      codigo3: Yup.string().required('Preencha o código completo.'),
      codigo4: Yup.string().required('Preencha o código completo.'),
      celular: Yup.string().required('Preencha o código completo.'),
    }),
    initialValues: {
      id: id || '',
      codigo1: '',
      codigo2: '',
      codigo3: '',
      codigo4: '',
      celular: celular
    },
    onSubmit: async (values) => {
      setLoading(true)
      let response = await Api.validarCelular({
        id: id, codigo: `${values?.codigo1}${values?.codigo2}${values?.codigo3}${values?.codigo4}`, celular: celular
      });
      if (response?.error) {
        setError(true)
        setResposta(true)
        setMsg(response?.message)
      } else {
        setError(false)
        setResposta(true)
        setMsg(response?.message)
        validation.resetForm()
        if (response?.validaemail && response?.validacelular && setProximo) {
          setProximo(4)
        }
        setModal(false)
      }
      setLoading(false)
    }
  });

  const handleDisparo = async () => {
    setLoading(true);
    if (!start) {
      setTime(59);
      setCookie('time', JSON.stringify(0), { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
      let response = await Api.disparoCelular({ celular: celular, id: id });
      if (!response?.error) {
        setError(false)
        setResposta(true)
        setMsg(response?.message)
      } else {
        setError(true)
        setResposta(true)
        setMsg(response?.message)
      }
      setStart(true);
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={modal} centered={true}>
      <Form
        className="custom-form"
        enctype='multipart/form-data'
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}>
        <div className="modal-header" style={{ border: 'none' }}>
          <ExitModal setModal={setModal} />
        </div>
        <div className="" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20, fontWeight: 300 }}>
          <p style={{ color: '#092140', textAlign: 'center', fontWeight: 300 }}>Valide seu Celular</p>
          <Alert color="success" style={{ marginTop: "13px", textAlign: 'center', fontWeight: 300 }}>
            Digite o código enviado por SMS ou Whatsapp. <br />
            <InputMask mask={'(99) 99999-9999'} disabled value={celular} type="tel" style={{ textAlign: 'center', backgroundColor: 'transparent', color: '#155724' }} />
          </Alert>
        


          <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
            <Col lg={3} style={{ flex: 1 }}>
              <input className="form-control" value={validation.values.codigo1} ref={input1Ref} onChange={(e) => {
                handleInputChange(e);
                validation.setFieldValue('codigo1', e.target.value)
              }} style={{ textAlign: "center" }} maxLength={1} />

            </Col>
            <Col lg={3} style={{ flex: 1 }}>
              <input className="form-control" value={validation.values.codigo2} ref={input2Ref} onChange={(e) => {
                handleInputChange(e);
                validation.setFieldValue('codigo2', e.target.value)
              }} style={{ textAlign: "center" }} maxLength={1} />

            </Col>
            <Col lg={3} style={{ flex: 1 }}>
              <input className="form-control" value={validation.values.codigo3} ref={input3Ref} onChange={(e) => {
                handleInputChange(e);
                validation.setFieldValue('codigo3', e.target.value)
              }} style={{ textAlign: "center" }} maxLength={1}
              />

            </Col>
            <Col lg={3} style={{ flex: 1 }}>
              <input className="form-control" value={validation.values.codigo4} ref={input4Ref}
                onChange={(e) => {
                  handleInputChange(e);
                  validation.setFieldValue('codigo4', e.target.value)
                }} style={{ textAlign: "center" }} maxLength={1}
              />

            </Col>
          </Row>
          <Col lg={12} style={{ flex: 1 }}>
            {
              ((validation.touched.codigo1 && validation.errors.codigo1) || (validation.touched.codigo2 && validation.errors.codigo2) || (validation.touched.codigo3 && validation.errors.codigo3) || (validation.touched.codigo4 && validation.errors.codigo4))
              && (
                <div style={{ textAlign: 'center' }} >
                  <div style={{ color: 'red' }}>{
                    validation.errors.codigo1 || validation.errors.codigo2 || validation.errors.codigo3 || validation.errors.codigo4
                  }</div>
                </div>
              )}
          </Col>
        </div>
        <Row>
          <Col lg={12} style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
            <button className="btn btn-default mb-2" disabled={loading ? loading : start ? true : false} onClick={() => { handleDisparo() }} type="button">{loading ? 'Reenviar codigo' : start ? `00:${time < 10 ? '0' + time : time}` : `Reenviar código`}</button>
            <button className="btn btn-primary " style={{ marginBottom: 20, width: 160 }} disabled={loading} type="submit"  >
              Avançar
            </button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}