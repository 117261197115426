import { useEffect, useState } from "react";
import { Api as apiComunicados } from "../../../../api/apiComunicados"
import { Api as apiTreinamentos } from "../../../../api/apiTreinamentos"
import { Api as apiMaterialDivulgacao } from "../../../../api/apiMaterialDivulgacao"
import { Api as apiTipoOperadora } from "../../../../api/apiTipoOperadoras"
import boneco from "../../../../assets/images/icones/boneco.png";
import bonecoEditar from "../../../../assets/images/icones/editar.png";
import card from "../../../../assets/images/icones/card.png";
import dente from "../../../../assets/images/icones/dente.png";
import estetoscopio from "../../../../assets/images/icones/estetoscopio.png";
import sino from "../../../../assets/images/icones/sino.png";
import empresasParceias from "../../../../assets/images/icones/empresas_parceiras.png";
import ferramentas_vendas from "../../../../assets/images/icones/ferramentas_vendas.png";
import material_divulgacao from "../../../../assets/images/icones/material_divulgacoes.png";
import auto_moto from "../../../../assets/images/icones/auto_moto.png";
import faca_cotacoes from "../../../../assets/images/icones/faca_cotacoes.png";
import home from "../../../../assets/images/icones/home.png";
import material_vendas from "../../../../assets/images/icones/material_vendas.png";
import pet from "../../../../assets/images/icones/pet.png";
import produtosEmpresas from "../../../../assets/images/icones/produtos_empresas.png";
import seja_parceiro from "../../../../assets/images/icones/seja_parceiro.png";
import treinamentos from "../../../../assets/images/icones/treinamentos.png";
import vendas_online from "../../../../assets/images/icones/vendas_online.png";
import uti_movel from "../../../../assets/images/icones/uti_movel.png";
import buscar from "../../../../assets/images/icones/busca.png";
import condominio from "../../../../assets/images/icones/condominio.png";
import residencial from "../../../../assets/images/icones/residencial.png";
import produtos_ramos_atuacao from "../../../../assets/images/icones/produtos_ramos_atuacao.png";
import seguro from "../../../../assets/images/icones/seguro.png";
import wpp from "../../../../assets/images/icones/wpp.png";
import { Login } from "../../../Auth/Login";
import { MeusDados } from "../../../Auth/MeusDados";
import React from "react";
import "./style.css";
import { Parceiro } from "../Parceiro";
import { baseUrlApi } from "../../../../api/baseUrl";
import { Modal } from "reactstrap";
import { ItensMenu } from "../../../components/itemMenu";
import { ExitModal } from "../../../components/exitModal";

import { getCookie } from "../../../../components/cookies";
function Drawer({ drawer, action }) {
  const [itemSize, setSize] = useState("");
  const [item, setItem] = useState("");
  const [modalDados, setModalDados] = useState();

  const [msgSessao] = useState('')
  const [modalLogin, setModalLogin] = useState(false);
  const [modalParceiro, setModalParceiro] = useState(false);
  const [comunicados, setComunicados] = useState('')
  const [treinamento, setTreinamento] = useState('')
  const [materiais, setMaterial] = useState('')
  const [modal, setModal] = useState(false)
  const [ramos, setRamos] = useState([])
  const [obj, setObj] = useState()
  const cookie = getCookie('authUser')
  useEffect(() => {
    setObj(cookie)
  }, [])
  useEffect(() => {
    handleComunicados()
    handleTreinamentos()
    handleMaterial()
    handleRamos()
  }, [])

  const handleTreinamentos = async () => {
    let response = await apiTreinamentos.listagem({ local: 'SITE', status: 'Aprovado', total: 1 });
    if (!response?.error) {
      let total = response?.totalItems
      setTreinamento(total)
    }
  }
  const handleMaterial = async () => {
    let response = await apiMaterialDivulgacao.listagem({ local: 'SITE', total: 1 });
    if (!response?.error) {
      let total = response?.totalItems
      setMaterial(total)
    }
  }
  const handleComunicados = async () => {
    let response = await apiComunicados.listagem({ local: 'SITE', status: 'Aprovado' });
    if (!response?.error) {
      let list = response?.find
      let id = obj?.id
      if (id) {
        id = JSON.parse(id)
      }
      let listagem = list?.filter((item) => (!item?.blogviews?.some((item2) => (item2?.iduser == id))))
      setComunicados(listagem?.length)
    }
  };

  const handleRamos = async () => {
    let response = await apiTipoOperadora.listagem()
    if (!response?.error) {
      let list = response?.find
      let result = list.filter((item) => item?.tiposoperadoras?.some((item) => item.operadora.linkvenda) ? true : item.operadoraslinks?.some((item) => item.link))
      result = result?.map((item) => { return { id: item.id, nome: item.nome, icone: item.icone, tipo: item.tiposoperadoras?.some((item) => item.operadora.linkvenda) ? 'Odonto-Saude' : item.operadoraslinks?.some((item) => item.link) ? 'Outros' : '' } })
      let uniqueArray = result?.reduce((acc, current) => {
        const exists = acc.find(item => item.id === current.id);
        if (!exists) {
          acc.push(current);
        }
        return acc;
      }, []);
      uniqueArray?.sort((a, b) => a.ordem - b.ordem)
      setRamos(uniqueArray)
    }
  }

  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${!modalLogin && drawer ? "active" : ""
          }`}
        style={{ width: '100%' }}
      ></div>

      <div className="offcanvas_menu" style={{ display: 'block' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${!modalLogin && drawer ? "active" : ""
                  }`}
                style={{ width: !modalLogin && drawer ? "290px" : "" }}
              >
                <ExitModal setModal={(e) => action(e)} />


                <div id="menu" style={{ marginTop: 20 }} >
                  <ul className="offcanvas_main_menu">
                    {!obj?.nome && (
                      <ItensMenu itemMenu={item} setItem={setItem} setSize={setSize} action={action} obj={obj} setModalLogin={setModalLogin}
                        title="Entre ou Cadastre-se" link={'/'} imagem={boneco}
                        privado={true} />
                    )}


                    {obj?.id && (
                      <ItensMenu
                        itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} onClick={() => { setModalDados(true) }}
                        title={`Meu Perfil`} link={''} imagem={bonecoEditar}
                        privado={true} html={""} id={'home'}
                      />
                    )}
                    <ItensMenu itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Inicio" link={'/'} imagem={home}
                      privado={false} />

                    <ItensMenu itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Ferramenta de Vendas" link={'/ferramentas'} imagem={ferramentas_vendas}
                      privado={true} />

                    <ItensMenu itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Avisos e Comunicados" link={'/comunicados'} imagem={sino}
                      privado={true}
                      html={comunicados ?
                        <div style={{ position: 'absolute', left: 8, top: '-2px', borderRadius: '50%', color: 'red', width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 900, backgroundColor: 'red', color: '#fff' }} >{comunicados}</div>
                        : ""} />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Agenda de Treinamentos" link={'/treinamentos'} imagem={treinamentos}
                      privado={true}
                      html={treinamento ?
                        <div style={{ position: 'absolute', left: 8, top: '-2px', borderRadius: '50%', color: 'red', width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 900, backgroundColor: 'red', color: '#fff' }} >{treinamento}</div>
                        : ""} />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} action={action} obj={obj} setModalLogin={setModalLogin}
                      title="Materiais de Divulgação" link={'/materiais'} imagem={material_divulgacao}
                      privado={true} html={materiais ?
                        <div style={{ position: 'absolute', left: 8, top: '-2px', borderRadius: '50%', color: 'red', width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 900, backgroundColor: 'red', color: '#fff' }} >{materiais}</div>
                        : ""} />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Tabelas e Materiais de Vendas" link={''} imagem={material_vendas}
                      privado={true} html={""} id={'tabelaMateriaisVendas'}
                      subMenu={[
                        { link: '/plano/saude', title: 'Planos de Saúde', imagem: estetoscopio },
                        { link: '/plano/odontologico', title: 'Planos Odontológicos', imagem: dente },
                        { link: '/plano/pet', title: 'Planos Pet (cães e gatos)', imagem: pet },
                        { link: '/plano/uti', title: 'UTI Móvel Emergências 24h', imagem: uti_movel },
                        { link: '/plano/card', title: 'Cartões de Benefícios', imagem: card }
                      ]}
                    />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Faça Suas Cotações" link={''} imagem={faca_cotacoes}
                      privado={true} html={""} id={'cotacoes'}
                      subMenu={[
                        { link: '/cotacoes', title: 'Planos de Saúde', imagem: estetoscopio, privado: true },
                        { link: '/cotacoes', title: 'Planos Odontológicos', imagem: dente, privado: true },
                        { link: '/tabela', title: 'Tabelas Personalizadas', imagem: card, privado: true },
                        { link: '', title: 'Seguro Auto e Moto', imagem: auto_moto, privado: true, onClick: () => { setModal(true) } },
                        { link: '', title: 'Seguro Residencial', imagem: residencial, privado: true, onClick: () => { setModal(true) } },
                        { link: '', title: 'Seguro Condomínio', imagem: condominio, privado: true, onClick: () => { setModal(true) } },
                        { link: '', title: 'Consórcios (Veículos e imóveis)', imagem: seguro, privado: true, onClick: () => { setModal(true) } },
                      ]}
                    />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Feche sua Vendas Online" link={''} imagem={vendas_online}
                      privado={true} html={""} id={'vendas'}
                      subMenu={ramos?.map((item) => { return { link: `/vendaOnline/${item?.id}`, title: item.nome, imagem: `${baseUrlApi}/imagens/${item?.icone}` } })}
                    />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Produtos e Empresas" link={''} imagem={produtosEmpresas}
                      privado={true} html={""} id={'produtos'}
                      subMenu={[
                        { link: '/produtos', title: 'Pesquisar Produtos', imagem: buscar, privado: true },
                        { link: '', title: 'Catálogo de Produtos', imagem: treinamentos, onClick: () => { setModal(true) } },
                        { link: '/ramos', title: 'Ramos de Atuação', imagem: produtos_ramos_atuacao, privado: true },
                        { link: '/empresas', title: 'Empresas Parceiras', imagem: empresasParceias, privado: true }
                      ]}
                    />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Seja Nosso(a) Parceiro(a)" link={''} imagem={seja_parceiro}
                      privado={true} html={""} id={''} onClick={() => { window.open("https://api.whatsapp.com/send/?phone=558000607060&text=&type=phone_number&app_absent=0", "_blank"); }}
                    />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Fale Conosco" link={''} imagem={wpp}
                      privado={true} html={""} id={''} onClick={() => { window.open("https://api.whatsapp.com/send/?phone=558000607060&text=&type=phone_number&app_absent=0", "_blank"); }}
                    />

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} msgSessao={msgSessao} />
      <Parceiro modalDados={modalParceiro} setModalDados={setModalParceiro} />
      <MeusDados modalDados={modalDados} setModalDados={setModalDados} />

      <Modal isOpen={modal} size='md' centered={true}>
        <div className="modal-header" style={{ border: '0px' }}>
          <ExitModal setModal={setModal} />

        </div>
        <div className="modal-body" style={{ border: '0px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>Em breve.</div>
        </div>
        <div className="modal-footer" style={{ border: '0px' }}>

        </div>
      </Modal>
    </>
  );
}

export default Drawer;
