import { useEffect, useState, useRef } from "react";
import { Row, Form } from "reactstrap";
import { Api } from "../../../api/apiUsuario";
import React from "react";
import { Col } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
import FileInputComponent from "../../components/buttonFile";
import { baseUrlApi } from "../../../api/baseUrl";
import { FaEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import logoAqui from "../../../assets/images/sualogoaqui.png";
import { getCookie, saveCookie } from "../../../components/cookies";
export const Foto = () => {
    const [resposta, setResposta] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("");
    const cookie = getCookie("authUser");
    const [obj, setObj] = useState({})
    const refImagem = useRef(null);
    const formData = new FormData()
    const [url, setUrl] = useState('')

    const validation = useFormik({
        enableReinitialize: true,
        validationSchema: Yup.object({
            id: Yup.string(),
            apagar: Yup.boolean().nullable().optional(),
            imagem: Yup.string().nullable().optional()
        }),
        initialValues: {
            id: obj?.id,
            apagar: null,
            imagem: null,
        },
        onSubmit: async (values) => {
            setLoading(true)
            formData.set('imagem', values?.imagem)
            formData.set('apagar', values?.apagar)
            let response = await Api.editarFoto(formData, values?.id)
            if (!response?.error) {
                setError(true)
                setResposta(true)
                setMsg(response?.message)
                let dados = { ...obj }
                dados.foto = (response.foto && response.foto != 'Apagada') ? response.foto : response.foto == 'Apagada' ? '' : dados.foto
                validation.resetForm()
                setObj(dados)
                saveCookie('authUser', JSON.stringify(dados));

            } else {
                setError(true)
                setResposta(true)
                setMsg(response?.message)
            }
            setLoading(false)
            document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
    });
    useEffect(() => {
        if (validation?.values?.imagem) {
            let urlImage = URL.createObjectURL(validation?.values?.imagem);
            setUrl(urlImage)
        }
    }, [validation.values.imagem])
    useEffect(() => {
        let objs = cookie
        setObj(objs)
    }, [])
    return (
        <Form
            className="custom-form"
            enctype='multipart/form-data'
            onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}>
            <div>
                <Row style={{ flex: 1, marginTop: 20, flexDirection: 'row' }}>
                    <div className="col-md-12" style={{ display: 'flex', flexDirection: 'column', justifyItems: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex' }} >
                            <div style={{ display: 'flex', margin: 'auto', flexDirection: 'column' }} >
                                <div>
                                    {obj?.foto ?
                                        <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center', flexDirection: 'column' }} >
                                            <img src={url ? url : `${baseUrlApi}/imagens/${obj?.foto}`} alt="" style={{ height: '120px', borderRadius: '20%' }} />

                                        </div>
                                        :
                                        <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center', flexDirection: 'column' }} >
                                            <img src={url ? url : logoAqui} alt="" width={'150'} style={{ paddingTop: 10, padding: 10 }} />
                                        </div>
                                    }
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <button type="button" style={{ backgroundColor: 'transparent' }}>
                                        <FaEdit size={20} color="green" style={{ cursor: "pointer" }} onClick={() => {
                                            document.querySelector("#imagem").click()
                                        }} />
                                    </button>

                                    <button type="submit" style={{ backgroundColor: 'transparent' }}>
                                        <FaRegTrashAlt size={20} color="red" type="submit" style={{ cursor: "pointer" }} onClick={() => {
                                            document.querySelector('#imagem').value = ""
                                            validation.setFieldValue('imagem', null)
                                            validation.setFieldValue('apagar', true)
                                        }} />
                                    </button>
                                </div>
                            </div>

                        </div>
                        <p style={{ textAlign: 'center', marginTop: 20 }} >Dimensões Ideais: 170px por 90px</p>
                    </div>

                    <Col lg={12} style={{ display: 'none', border: 'none', borderBottom: 'none' }}>
                        <div >
                            <div className="mb-3">
                                {/* <FileInputComponent handleExit={(e) => { handleExit(e) }} handleChange={(event) => { setImagem(event.target.files ? event.target.files[0] : '') }} name={name} setName={setName} /> */}
                                <input type="file" id="imagem" onChange={(event) => {
                                    if ('File', event.target.files.length > 0) {
                                        validation.setFieldValue('imagem', event.target.files[0])
                                    } else {
                                        validation.setFieldValue('imagem', '')
                                    }
                                }} ref={refImagem} />
                            </div>
                        </div>
                    </Col>

                    <button className="btn btn-primary" type="submit" disabled={loading} style={{ position: 'absolute', bottom: 20, right: 25 }}>Salvar</button>
                </Row >


            </div>

        </Form>
    )
}