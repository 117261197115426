import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api/apiMaterialDivulgacao";
import Divulgacao from "./divulgacao";
import Icone from "../../assets/icones/icone.png"
import { useCookies } from 'react-cookie';

function FeatureSection({ id }) {
    const [loading, setLoading] = useState(false);
    const [datas, setDatas] = useState([]);
    const [datasFiltro, setDatasFiltro] = useState({});
    const [operadoras, setOperadoras] = useState([])
    const [ramooperadoras, setRamoOperadoras] = useState([])
    const [estados, setEstados] = useState([])
    const [estadosSelect, setEstadosSelect] = useState('')
    const [operadorasSelect, setOperadorasSelect] = useState(id)
    const [nomeOperadora, setNomeOperadora] = useState('')
    const [nomeEstado, setNomeEstado] = useState('')
    const [tipo, setTipo] = useState('')
    const [ramoSelect, setRamoSelect] = useState('')
    const [cookies, setCookie] = useCookies(['authUser', 'id']);
    const obj = cookies.authUser
    const idSession = cookies.id
    const handleEstados = async () => {
        let response = await Api.materialDivulgacaoEstados()
        if (!response?.error) {
            setEstados(response?.find)
        }
    }


    const handleOperadora = async () => {
        let response = await Api.materialDivulgacaoOperadoraRamos()
        let tiposOperadora = []
        if (!response.error) {
            let listagem = response?.find.sort((a, b) => {
                const nomeA = a.nome.toUpperCase();
                const nomeB = b.nome.toUpperCase();
                if (nomeA < nomeB) {
                    return -1;
                }
                if (nomeA > nomeB) {
                    return 1;
                }
                return 0;
            });
            listagem.map((item) => {
                item.materialDivulgacaoRamosOperadoras?.map((item2) => {
                    if(!tiposOperadora.some((som)=> som == item2.tipooperadora?.id ) ){
                        tiposOperadora.push(item2.tipooperadora)
                    }
                })
            })
            setRamoOperadoras(tiposOperadora)
            setOperadoras(listagem)
        }

    };
    useEffect(() => {
        handleEstados();
        handleOperadora()
    }, []);
    const handleOMaterial = async (dados) => {
        setLoading(true)
        let response = await Api.materialDivulgacao(dados)
        if (!response?.error) {
            setDatas(response?.find)
            setDatasFiltro(response?.find)

        }
        setLoading(false)
    };
    const handleFiltro = async () => {
        setLoading(true)
        let listagem = datas?.filter((item) => (
            (operadorasSelect && operadorasSelect != '*') ? item.materialDivulgacaoRamosOperadoras?.some((som)=>som.idOperadora== parseInt(operadorasSelect)) : true) && 
            ((tipo && tipo != '*') ? item.tipo == tipo : true) && 
            ((estadosSelect && estadosSelect != '*') ? item.materialDivulgacaoEstados?.some((som)=> som.idEstado  == parseInt(estadosSelect)  ): true) && 
            ((ramoSelect && ramoSelect != '*' ? item?.materialDivulgacaoRamosOperadoras?.some((som)=> som.idTipoOperadora==parseInt(ramoSelect)) : true)))
        setDatasFiltro(listagem)
        setLoading(false)
    };
    useEffect(() => {
        handleVisualizar()
    }, [datas])
    const handleVisualizar = async () => {
        datas?.map(async (item) => {
            await Api.VisualizacaoMaterial({ iduser: idSession, idMaterial: item.id })
        })
    }
    useEffect(() => {
        handleFiltro()
    }, [operadorasSelect, estadosSelect, tipo, ramoSelect])
    useEffect(() => {
        setOperadorasSelect(id)
    }, [id])
    useEffect(() => {
        handleOMaterial({ estado: estadosSelect, operadora: (operadorasSelect && operadorasSelect != '*') ? operadorasSelect : '', tipo: tipo })
    }, [])
    return (
        <section className="featured-section bgs-cover pt-20 rpt-150 pb-20 rpb-100" style={{ minHeight: 700 }}>
            <div className="container" >

                <div className="row" style={{ margin: 'auto' }}>
                    <div className="col-6 col-lg-3 " style={{ margin: 'auto' }} >
                        <select className="form-control" name="estados" onChange={(e) => {
                            setEstadosSelect(e.target.value)
                            let option = document.querySelector('select[name="estados"]')
                            let index
                            if (option) {
                                index = option.options.selectedIndex
                                setNomeEstado(option.options[index].text)
                            }
                        }} >
                            <option value=''>Filtrar Estado</option>
                            <option value='*'>* Todas as Opções</option>
                            {estados && estados.map((item) => (
                                <option value={item.id} key={item}>{item.nome}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-6 col-lg-3 " style={{ margin: 'auto' }} >
                        <select className="form-control" name="ramos" onChange={(e) => {
                            setRamoSelect(e.target.value)
                            let option = document.querySelector('select[name="ramos"]')
                            let index
                            if (option) {
                                index = option.options.selectedIndex
                                setNomeEstado(option.options[index].text)
                            }
                        }} >
                            <option value=''>Filtrar Produto</option>
                            <option value='*'>* Todas as Opções</option>
                            {ramooperadoras && ramooperadoras.map((item) => (
                                <option value={item.id} key={item}>{item.nome}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-6 col-lg-3 " style={{ margin: 'auto' }}>
                        <select className="form-control" name="fornecedor" value={operadorasSelect} onChange={(e) => {
                            setOperadorasSelect(e.target.value)
                            let option = document.querySelector('select[name="fornecedor"]')
                            let index
                            if (option) {
                                index = option.options.selectedIndex
                                setNomeOperadora(option.options[index].text)
                            }
                        }} >
                            <option value=''>Filtrar Fornecedor</option>
                            <option value='*'>Todas as Opções</option>
                            {operadoras && operadoras.map((item) => (
                                <option value={item.id} key={item}>{item.nome}</option>
                            ))}
                        </select>
                    </div>
                    {/* <div className="col-6 col-lg-3 " style={{ margin: 'auto' }}>
                        <select className="form-control" name="tipo" onChange={(e) => {
                             setTipo(e.target.value) 
                            
                             }} >
                            <option value=''>Filtrar Tipo</option>
                            <option value='*'>Todas as Opções</option>
                            <option value='1'>WhatsApp</option>
                            <option value='2'>Instagram</option>
                            <option value='3'>Facebook</option>
                            <option value='4'>Gráfica</option>
                        </select>
                    </div> */}


                </div>

                {!loading && datasFiltro &&
                    <Divulgacao datas={datasFiltro} estado={nomeEstado} operadora={nomeOperadora} />
                }

                {!loading && datasFiltro?.length == 0 &&
                    <div className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", fontSize: 20, minHeight: 299, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        Em Breve
                    </div>
                }
                

                <div className="feature-btn text-center mt-20"></div>
            </div>
        </section>
    );
}

export default FeatureSection;
