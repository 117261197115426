import React, { useEffect, useState } from "react";
import { Col, Input, Label, Form, Row, UncontrolledAlert } from "reactstrap";
import { Api } from "../../../api/apiUsuario";
import Nat from "../../../assets/images/nat.jpeg"
 
export const Etapa4 = ({ id }) => {
  document.title = "Corretor Parceiro";
  const [resposta, setResposta] = useState(false);
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");


  const handleSenha = async () => {
    if (id) {
      let response = await Api.etapa4({ id });
      if (response?.error) {
        setError(true)
        setResposta(true)
        setMsg(response?.message)
      } 
    } 
  }
  useEffect(()=>{
    handleSenha()
  },[])


  return (
    <Form
      className="custom-form"
      enctype='multipart/form-data'
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}>
      <Row className="g-0">
        <Col lg={12} md={12} className="col-xxl-12">
          <div className="auth-full-page-content d-flex p-sm-2 p-4">
            <div className="w-100">
            <img src={Nat} width={80} style={{borderRadius:20,position:'absolute',top:0}} />

              <div className="d-flex flex-column h-100">
                <div className="auth-content my-auto">
               
                  <div className="text-center">
                    <p style={{ color: '#2278F3', fontSize: 30 }}>Bem vindo parceiro!</p>
                  </div>
                 
                  <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', minHeight: 220 }} >

                    <p className="text-center" style={{ fontSize: 20, color: '#2278F3', marginTop: '-70px' }}>
                      Seu cadastro foi concluído com sucesso.
                    </p>
                    <p style={{ fontSize: 20, color: '#092140', marginTop: '-90px' }}>
                      Sua senha provisória foi enviada para seu email, sms ou whatsapp.<br />
                      Caso deseje mais informações, treinamentos dos produtos e das <br />
                      ferramentas de vendas, acesse o sistema e fale conosco pelo chat.
                    </p>
                  </Row>

                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
